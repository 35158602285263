import { create } from 'zustand'
import { createDraft, finishDraft } from 'immer'
import { mountStoreDevtool } from 'simple-zustand-devtools'

const useDebugState = create((set, get) => ({
  state: {
    organisation: {
      hourly_rate: 89000,
    },
  },
  initialised: false,
  initialise: () => set((state) => ({ ...state, initialised: true })),
  getDraft: () => createDraft(get().state),
  commitDraft: (draft) =>
    set((state) => ({ ...state, state: finishDraft(draft) })),
  update: (change) => set((state) => ({ ...state, ...change })),
}))

if (process.env.NODE_ENV === 'development') {
  mountStoreDevtool('DebugState', useDebugState)
}

export default useDebugState
