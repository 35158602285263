import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  Typography,
} from '@mui/material'

import Icon from 'components/Icon'

import Form from './Form'
import Addons from './Addons'

const Product = ({ configuration, product, update, availableProducts }) => (
  <Card id={product.id}>
    <CardHeader
      title={<Typography variant='h5'>{product.configuration.name}</Typography>}
      subheader={product.configuration.description}
      avatar={
        <Icon name={product.configuration.icon} sx={{ fontSize: '2.5rem' }} />
      }
      action={
        <IconButton aria-label='close'>
          <Icon name='delete' />
        </IconButton>
      }
    />
    <Divider />

    <CardContent>
      <Form
        product={product}
        values={configuration}
        update={(change) => update({ ...configuration, ...change })}
      />
    </CardContent>
    <Addons
      product={product}
      availableProducts={availableProducts}
      values={configuration}
      update={(change) => update({ ...configuration, ...change })}
    />
  </Card>
)

export default Product
