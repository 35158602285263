import { styled } from '@mui/material/styles'

const Column = styled('section')`
  display: flex;
  position: relative;
  gap: 0.5rem;
  flex-direction: column;
`

export default Column
