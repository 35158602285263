import { CardContent, FormControlLabel, Stack, Switch } from '@mui/material'

import Field from 'components/Field'
import Icon from 'components/Icon'
import { useState } from 'react'

const Static = ({ update }) => {
  const [name, setName] = useState('')
  const [variableName, setVariableName] = useState('')
  const [value, setValue] = useState('')

  return (
    <>
      <Field
        label='Name'
        value={name}
        helperText='The human readable name of the attribute'
        update={setName}
      />
      <Field
        label='Variable name'
        value={variableName}
        helperText='The name of the attribute when used in calculations'
        update={setVariableName}
      />
      <Field
        label='Value'
        value={value}
        multiline
        minRows={2}
        helperText='The value of the attribute'
        update={setValue}
      />
    </>
  )
}

export default Static
