import { Typography } from '@mui/material'

const Invitation = ({}) => {
  return (
    <>
      <Typography variant='h5' mt={8}>
        Invitation
      </Typography>
      <Typography variant='caption' mb={2} component='p'>
        This is your open invitation to join an organisation.
      </Typography>
    </>
  )
}

const Invitations = () => {
  const invitations = []
  return (
    <>
      <Typography variant='h5' mt={8}>
        Invitations
      </Typography>
      <Typography variant='caption' mb={2} component='p'>
        These are your open invitations to join organisations.
      </Typography>
      {invitations.map((invitation) => (
        <Invitation key={invitation.id} {...invitation} />
      ))}
      {invitations.length === 0 && (
        <Typography variant='body1' mb={2} component='p'>
          You have no open invitations.
        </Typography>
      )}
    </>
  )
}

export default Invitations
