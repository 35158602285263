import { v4 as uuid } from 'uuid'
import { FormControlLabel, Stack, Switch } from '@mui/material'
import Field from 'components/Field'

const CommonFields = ({
  id = uuid(),
  icon = 'question_mark',
  name = '',
  variable_name = '',
  description = '',
  type = 'string',
  required = true,
  visible = true,
  attributes = {},
  onChange,
}) => (
  <>
    <Stack gap={4} direction='row'>
      <FormControlLabel
        labelPlacement='start'
        label='Required'
        control={
          <Switch
            defaultChecked={required}
            onChange={() => {
              onChange({ required: !required })
            }}
          />
        }
      />
      <FormControlLabel
        labelPlacement='start'
        label='Show in UI'
        control={
          <Switch
            defaultChecked={visible}
            onChange={() => {
              onChange({ visible: !visible })
            }}
          />
        }
      />
    </Stack>
    <Stack gap={2} direction='row'>
      <Field
        label='Name'
        required
        value={name}
        placeholder='My variable'
        helperText='The variable name as it will appear in the UI'
        update={(value) =>
          onChange({
            name: value,
            variable_name: value.toLowerCase().replaceAll(/[^\w]/gi, '_'),
          })
        }
      />
      <Field
        label='Variable name'
        placeholder='my_variable'
        InputProps={{
          readOnly: true,
          disabled: true,
        }}
        value={variable_name}
        helperText='The name of the value for use in calculations'
      />
    </Stack>
    <Field
      label='Description'
      value={description}
      multiline
      minRows={2}
      helperText='A detailed description of the variable'
      update={(value) => onChange({ description: value })}
    />
  </>
)

export default CommonFields
