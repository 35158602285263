import { Card, Container, IconButton, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useState } from 'react'

import Icon from 'components/Icon'

const Details = styled('div')`
  white-space: nowrap;
  display: grid;
  grid-template-columns: auto auto 1fr;
  margin-top: 0.5em;
`

const Url = styled(Typography)`
  text-overflow: ellipsis;
  overflow: hidden;
`

const ImagePreview = ({ url: url_string, title, withDetails, onDelete }) => {
  const [[width, height], setSize] = useState([0, 0])
  const extension = '.' + url_string.split('.').slice(-1)[0]
  const path = url_string.slice(0, -1 * extension.length)

  return (
    <Card variant='outlined'>
      <Container style={{ padding: '.5em 1em' }}>
        <IconButton style={{ float: 'right' }} onClick={onDelete}>
          <Icon name='trash' />
        </IconButton>
        {title && (
          <Typography variant='h5' mb='.5em'>
            {title}
          </Typography>
        )}
        <img
          alt='Image preview'
          onLoad={({ target }) =>
            setSize([target.naturalWidth, target.naturalHeight])
          }
          style={{
            display: 'block',
            maxWidth: '100%',
            maxHeight: '100px',
            border: '1px dashed #ddd',
          }}
          src={url_string}
        />
        {withDetails && (
          <Details>
            <Url variant='caption'>{path}</Url>
            <Typography variant='caption'>{extension}</Typography>
            <Typography variant='caption'>{`, ${width} x ${height}px`}</Typography>
          </Details>
        )}
      </Container>
    </Card>
  )
}

export default ImagePreview
