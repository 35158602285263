import { useState } from 'react'
import { Table } from 'components/Table'

import Header from './Header'
import Resource from './PermissionsRow'

const Permissions = ({ permissions, readOnly }) => {
  const [mark_state, set_mark_state] = useState()
  const permissions_config = [
    {
      label: 'Accounts',
      name: 'accounts',
      permissions: permissions?.accounts,
      mark_state,
      set_mark_state,
    },
    {
      label: 'Customers',
      name: 'customers',
      permissions: permissions?.customers,
      mark_state,
      set_mark_state,
      sub_resources: [
        {
          label: 'Contacts',
          name: 'contacts',
          permissions: permissions?.customers?.contacts,
          mark_state,
          set_mark_state,
        },
        {
          label: 'Products',
          name: 'products',
          permissions: permissions?.customers?.products,
          mark_state,
          set_mark_state,
        },
        {
          label: 'Events',
          name: 'events',
          permissions: permissions?.customers?.events,
          mark_state,
          set_mark_state,
        },
      ],
    },
    {
      label: 'Products',
      name: 'products',
      permissions: permissions?.products,
      mark_state,
      set_mark_state,
    },
    {
      label: 'Organisations',
      name: 'organisations',
      permissions: permissions?.organisations,
      mark_state,
      set_mark_state,
      sub_resources: [
        {
          label: 'Invitations',
          name: 'invitations',
          permissions: permissions?.organisations?.invitations,
          mark_state,
          set_mark_state,
        },
        {
          label: 'Users',
          name: 'users',
          permissions: permissions?.organisations?.users,
          mark_state,
          set_mark_state,
        },
        {
          label: 'Roles',
          name: 'roles',
          permissions: permissions?.organisations?.roles,
          mark_state,
          set_mark_state,
        },
      ],
    },
  ]

  return (
    <Table
      dark={!readOnly}
      columns={
        readOnly
          ? '2.5fr 1fr 1fr 1fr 1fr 1fr 0px'
          : '3fr 1fr 1fr 1fr 1fr 1fr auto'
      }
    >
      <Header />
      {permissions_config.map((permission) => (
        <Resource key={permission.name} {...permission} readOnly={readOnly} />
      ))}
    </Table>
  )
}

export default Permissions
