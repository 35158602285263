import { forwardRef } from 'react'

import { styled } from '@mui/material'

const TableElement = styled('section')`
  display: grid;
  grid-template-columns: ${({ columns }) => (columns ? columns : '1fr')};
  gap: ${({ gap }) => (gap ? gap : '0 1rem')};
  margin-top: 2rem;

  --section-background-color: ${({ dark }) =>
    dark ? 'rgba(0,0,0,.1)' : 'rgba(255,255,255,.2)'};

  & > .MuiStack-root:first-of-type {
    margin-bottom: 2rem;
  }
`

const Table = forwardRef(({ children, ...props }, ref) => (
  <TableElement ref={ref} {...props}>
    {children}
  </TableElement>
))

export default Table
