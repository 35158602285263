import { Stack, Typography } from '@mui/material'

import CollectionHeader from '../../components/CollectionHeader'
import ColorTests from './ColorTests'
import FeatureTests from './FeatureTests'
import NoOrganisations from './NoOrganisations'

const Dashboard = () => (
  <>
    <CollectionHeader
      title='Dashboard'
      description='Welcome to your dashboard. Here you can see a summary of your account.'
      show_search={false}
    />
    <Stack spacing={5}>
      <FeatureTests />
      <div>
        <Typography variant='h5' component='h3' gutterBottom>
          Color Tests
        </Typography>
        <ColorTests />
      </div>
      <NoOrganisations />
    </Stack>
  </>
)

export default Dashboard
