import { Stack } from '@mui/material'
import { v4 as uuid } from 'uuid'
import { useNavigate } from 'react-router-dom'

import TableOfContent from 'components/TableOfContent'
import SideNavigationButton from 'components/SideNavigationButton'

const variablesToToc = (variables) => {
  const icons = {
    input: 'input-pipe',
    calculation: 'code',
    product: 'bags-shopping',
  }

  return variables.map(({ id, name, description, type }) => ({
    id,
    title: name,
    subtitle: description,
    icon: icons[type],
  }))
}

const reorder = (stateVariables, fromToc) =>
  fromToc.map((item) => stateVariables.find((v) => v.id === item.id))

const SideNavigation = ({
  id,
  configuration: { name, icon, variables },
  parent,
  products,
  update,
}) => {
  const navigate = useNavigate()

  return (
    <>
      <Stack spacing={1}>
        <SideNavigationButton
          url={`/products/${id}/settings`}
          label='Settings'
          icon='cog'
        />
        <SideNavigationButton
          url={`/products/${id}/components`}
          label='Components'
          icon='puzzle'
        />
      </Stack>

      <div style={{ position: 'sticky', top: '-1rem' }}>
        <TableOfContent
          title='Product structure'
          subtitle='Drag and drop to reorder'
          items={[
            {
              id,
              name,
              icon,
              subtitle: 'General info',
              children: variablesToToc(variables),
            },
          ]}
          update={(items) => update(reorder(variables, items[0].children))}
        />
        {!parent && (
          <TableOfContent
            title='Addons'
            items={products.map((product) => ({
              id: product.id,
              name: product.configuration.name,
              icon: product.configuration.icon,
              subtitle: 'General info',
              variables: [],
              external_url: `/products/${product.id}`,
            }))}
            action={() => {
              navigate(`/products/${uuid()}?parent=${id}`)
            }}
            update={() => {}}
          />
        )}
      </div>
    </>
  )
}

export default SideNavigation
