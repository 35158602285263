import { Table } from 'components/Table'

import Invitation from './Invitation'
import { expired } from './functions'

const Section = ({
  invitations,
  show_expired,
  days,
  organisation_id,
  absolute,
}) =>
  invitations
    .map((invitation) => {
      if (!show_expired && expired(invitation, days)) return null
      return (
        <Invitation
          secondary
          key={invitation.id}
          {...invitation}
          organisation_id={organisation_id}
          absolute={absolute}
        />
      )
    })
    .filter(Boolean)

export default Section
