import { useState } from 'react'
import { Drawer, Stack, Typography, styled } from '@mui/material'

import Fab from 'components/Fab'
import Icon from 'components/Icon'
import Grid from 'components/Grid'
import { useCustomers } from 'state/useCustomersStore'
import CollectionHeader from 'components/CollectionHeader'
import search from 'utilities/search'
import useQueryParam from 'hooks/useQueryParam'

import Card from './Card'
import Form from './Form'

const AnimatedCard = styled(Card)`
  transition:
    transform 0.2s ease-in-out,
    box-shadow 0.2s ease-in-out;
  &:hover {
    transform: translateY(-0.1rem);
    box-shadow: var(--mui-shadows-3);
  }
`

const Customers = () => {
  const customers = useCustomers()
  const [customer, setCustomer] = useState({})
  const search_term = useQueryParam('search')
  const [drawerOpen, setDrawerOpen] = useState(false)

  return (
    <>
      <CollectionHeader title='Customers' />
      <Grid>
        {search(customers, search_term, 'name').map((customer) => (
          <AnimatedCard key={customer.id} {...customer} />
        ))}
      </Grid>

      <Drawer
        anchor='bottom'
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        PaperProps={{ sx: { p: '2em 3em 5em' } }}
      >
        <Typography variant='h4' mb={4}>
          Create new customer
        </Typography>
        <Form
          customer={customer}
          update={(change) => setCustomer({ ...customer, ...change })}
        />
        <Stack mt={2} direction='row' spacing={2} justifyContent='flex-end'>
          <Fab
            aria-label='save'
            onClick={() => {
              throw 'Implementation missing'
            }}
          >
            <Icon name='save' />
          </Fab>
        </Stack>
      </Drawer>

      <Fab aria-label='add' onClick={() => setDrawerOpen(true)}>
        <Icon name='add' />
      </Fab>
    </>
  )
}

export default Customers
