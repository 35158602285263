import {
  Button,
  Card,
  CardActions,
  CardContent,
  Collapse,
  IconButton,
  styled,
} from '@mui/material'
import { Form } from 'react-router-dom'

import SectionHeader from 'components/SectionHeader'
import Icon from 'components/Icon'
import useSettings from 'hooks/useSettings'

import DisplayTimeAs from './DisplayTimeAs'

const Grid = styled('div')`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  align-items: center;
  justify-items: start;
  /* No, you can't just change it to nth-of-type Hannes : https://github.com/emotion-js/emotion/issues/1105 */
  & > :nth-child(odd) {
    justify-self: end;
  }
`

const Settings = ({ expanded, set_expanded, ...header_props }) => (
  <Form method='post'>
    <SectionHeader
      {...header_props}
      action={
        <IconButton
          onClick={() => set_expanded(!expanded)}
          aria-expanded={expanded}
          aria-label='show more'
          size='small'
        >
          <Icon name={expanded ? 'chevron-up' : 'chevron-down'} />
        </IconButton>
      }
    />
    <Card>
      <Collapse in={expanded} timeout='auto' unmountOnExit>
        <CardContent>
          <Grid>
            <DisplayTimeAs />
          </Grid>
        </CardContent>
      </Collapse>
      <CardActions sx={{ justifyContent: 'end' }}>
        <Button
          type='submit'
          name='section'
          value='settings'
          variant='text'
          color='secondary'
          size='small'
        >
          Save
        </Button>
      </CardActions>
    </Card>
  </Form>
)

export default Settings
