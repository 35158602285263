import { forwardRef } from 'react'
import { styled } from '@mui/material/styles'
import { Chip, Badge as MuiBadge, Stack } from '@mui/material'

import { useSession } from 'state/useSession'
import Icon from 'components/Icon'
import Avatar from './Avatar'

const Badge = styled(MuiBadge)`
  & .MuiBadge-badge {
    right: 0;
  }
  &.MuiChip-avatar.MuiChip-avatarColorSecondary {
    background-color: transparent;
  }
`

const UserButton = forwardRef(({ onClick, open }, ref) => {
  const session = useSession()

  const alt = session.user.user_metadata.full_name
  const src = session.user.user_metadata.avatar_url
  const name = session.user.user_metadata.full_name
  const todos = []

  return (
    <Chip
      ref={ref}
      variant='outlined'
      sx={{
        'bgcolor': open ? '#ddd' : 'transparent',
        '&.MuiChip-clickable:hover': {
          bgcolor: '#ddd',
        },
      }}
      label={
        <Stack direction='row' gap={1}>
          <>
            {name}
            <Icon style={{ color: 'gray' }} name='caret-down' />
          </>
        </Stack>
      }
      color='secondary'
      onClick={onClick}
      avatar={
        <Badge
          overlap='circular'
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          badgeContent={todos.length}
          color='tertiary'
          variant='dot'
        >
          <Avatar src={src} alt={alt} />
        </Badge>
      }
    />
  )
})

UserButton.displayName = 'UserButton'

export default UserButton
