import { styled } from '@mui/material'
import { DragOverlay } from '@dnd-kit/core'

import Field from './Table/Field'

const StyledField = styled(Field)`
  background-color: #d6d6d6;
  box-shadow: var(--mui-shadows-5);
  border-radius: 0.25rem;
`

const Overlay = ({ dragged_attribute_name }) => (
  <DragOverlay style={{ pointerEvents: 'none' }}>
    <StyledField attribute={dragged_attribute_name} type='text' name='' />
  </DragOverlay>
)

export default Overlay
