import Settings from 'pages/Product/Document/Settings'

import { get_form_data, upsert } from 'utilities/routes'

const action = async ({ params, request }) => {
  const [payload, _section] = await get_form_data(request)
  const { id } = params

  console.log(id, payload)

  upsert('products', { id, settings: payload })

  return null
}

const route = {
  path: ':id/settings',
  element: <Settings />,
  action,
}

export default route
