import { create } from 'zustand'
import { mountStoreDevtool } from 'simple-zustand-devtools'
import { useParams } from 'react-router-dom'
import { collection } from 'utilities/routes'

const useTenantsStore = create((set) => {
  return {
    loading: true,
    tenants: [],
    fetchTenants: async () => {
      const tenants = await collection({
        path: 'tenants',
        builder: (query) => query.select('*, customer:customer_id( * )'),
      })

      const processed = tenants
        .map((tenant) => ({ ...tenant.customer, ...tenant }))
        .toSorted((tenant) => tenant.name)

      set({ tenants: processed, loading: false })
    },
    addTenant: (tenant) =>
      set(({ tenants }) => ({ tenants: [...tenants, tenant] })),
    updateTenant: (updated) =>
      set((state) => ({
        tenants: state.tenants.map((tenant) =>
          tenant.id === updated.id ? { ...tenant, ...updated } : tenant
        ),
      })),
    deleteTenant: ({ id }) =>
      set((state) => ({
        tenants: state.tenants.filter((tenant) => tenant.id !== id),
      })),
  }
})

export const useLoading = () => useTenantsStore((state) => state.loading)
export const useTenants = () => useTenantsStore((state) => state.tenants)
export const useTenant = (id) =>
  useTenantsStore((state) => state.tenants.find((tenant) => tenant.id === id))
export const useCurrentTenant = () => {
  const { id } = useParams()
  return (
    useTenantsStore((state) =>
      state.tenants.find((tenant) => tenant.id === id)
    ) || {}
  )
}

export const addTenant = useTenantsStore.getState().addTenant
export const updateTenant = useTenantsStore.getState().updateTenant
export const deleteTenant = useTenantsStore.getState().deleteTenant
export const fetchTenants = useTenantsStore.getState().fetchTenants
export default useTenantsStore

if (process.env.NODE_ENV === 'development') {
  mountStoreDevtool('Tenants', useTenantsStore)
}
