import { v4 as uuid } from 'uuid'
import { create } from 'zustand'

const deleteById = (array, id) => {
  const index = array.findIndex((item) => item.id === id)

  return [...array.slice(0, index), ...array.slice(index + 1)]
}

const useSnackbarStore = create((set, get) => ({
  messages: [],
  append: (message) => {
    const messages = get().messages
    const duplicate = messages.find((msg) => msg.text === message.text)
    if (duplicate) return

    const id = uuid()
    set(({ messages }) => ({ messages: [...messages, { ...message, id }] }))
  },
  close: (id) => {
    console.log('Closing', id)
    set(({ messages }) => ({
      messages: deleteById(messages, id),
    }))
  },
}))

export const useMessages = () => useSnackbarStore((state) => state.messages)
export const useClose = () => useSnackbarStore((state) => state.close)
export const useAppend = () => useSnackbarStore((state) => state.append)
export const append = useSnackbarStore.getState().append
export const appendError = (text, delay = 6) =>
  append({ text, type: 'error', delay })
export const appendInfo = (text, delay = 6) =>
  append({ text, type: 'info', delay })
export const appendSuccess = (text, delay = 6) =>
  append({ text, type: 'success', delay })
