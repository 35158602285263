import { redirect, Link, Navigate, useRouteError } from 'react-router-dom'
import { Button, Typography } from '@mui/material'

import Customer from 'pages/Customer/Document'
import Modal from 'pages/Customer/Document/Contacts/Modal'
import Icon from 'components/Icon'
import { useCurrentCustomer } from 'state/useCustomersStore'
import { upsert, get_form_data } from 'utilities/routes'

const action = async ({ params, request }) => {
  const [data, section] = await get_form_data(request)

  switch (section) {
    case 'general':
      upsert('customers', { id: params.id, ...data })
      break
    case 'update_contact':
      upsert('contacts', { customer_id: params.id, ...data })
      return redirect(`/customers/${params.id}`)
    case 'delete_contact':
      delete ('contacts', { id: data.id })
      break
  }

  return null
}

const ErrorBoundary = (props) => {
  const customer = useCurrentCustomer()

  let error = useRouteError()
  console.error(error)

  if (!customer?.id)
    return (
      <>
        <Typography variant='h4'>This customer is not available</Typography>
        <Typography variant='body1' mt={2}>
          If you recently switched organisations, you will no longer be able to
          access resources belonging to the other organisation.
        </Typography>
        <Typography variant='body1' mt={1}>
          Please go back to the customer list and select a customer from the
          current organisation.
        </Typography>
        <Button
          variant='outlined'
          color='primary'
          sx={{ mt: 3 }}
          component={Link}
          to='/customers'
        >
          <Icon name='arrow-left' style={{ marginRight: '.5rem' }} /> Back to
          customer list
        </Button>
      </>
    )

  return <Navigate to='.' replace={true} />
}

const route = {
  path: ':id',
  element: <Customer />,
  errorElement: <ErrorBoundary />,
  action,
  children: [
    {
      path: 'contacts/:contact_id',
      element: <Modal open />,
      action,
    },
  ],
}

export default route
