import Product from './Product'

const Products = ({
  products: productConfigurations = [],
  update,
  availableProducts,
}) => {
  const handleUpdate = (index, product) => {
    update([
      ...productConfigurations.slice(0, index),
      product,
      ...productConfigurations.slice(index + 1),
    ])
  }

  return productConfigurations.map((configuration, index) => {
    const product = availableProducts.find(
      (product) => product.id === configuration.id
    )

    if (!product) return

    return (
      <Product
        key={configuration.id + '-' + index}
        {...{
          configuration,
          product,
          availableProducts,
          update: (product) => handleUpdate(index, product),
        }}
      />
    )
  })
}

export default Products
