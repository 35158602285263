import { Toolbar as MuiToolbar, styled } from '@mui/material'

const Toolbar = styled(MuiToolbar)`
  display: flex;
  alignitems: center;
  justifycontent: flex-end;
  padding: 0 0.4rem !important;
  overflow: hidden;

  height: ${({ open }) => (open ? '0px' : '64px')};
  min-height: ${({ open }) => (open ? '0px !important' : '64px')};
  opacity: ${({ open }) => (open ? '0' : '1')};
  transition: ${({ open, theme }) =>
    open
      ? theme.transitions.create(['min-height', 'height', 'opacity'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        })
      : theme.transitions.create(['min-height', 'height', 'opacity'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        })};
`

export default Toolbar
