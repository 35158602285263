import { get_form_data } from 'utilities/routes'

import Users from 'pages/Organisation/Document/Users'
import Modal from 'pages/Organisation/Document/Users/Modal'

const action = async ({ params, request }) => {
  const [payload, _section] = await get_form_data(request)
}

const route = {
  path: ':id/users',
  element: <Users />,
  children: [
    {
      path: ':user_id',
      element: <Modal />,
      action,
    },
  ],
}

export default route
