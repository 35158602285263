import { forwardRef } from 'react'
import { NavLink as NavLinkBase } from 'react-router-dom'

const NavLink = forwardRef((props, ref) => (
  <NavLinkBase
    ref={ref}
    {...props}
    className={({ isActive }) =>
      isActive ? props.className + ' Mui-selected' : props.className
    }
  />
))

NavLink.displayName = 'NavLink'

export default NavLink
