import { styled } from '@mui/material'

const Dialog = styled('dialog')`
  top: 50%;
  transform: translateY(-50%);
  background: transparent;
  border: none;
  padding: 0;
  aspect-ratio: 1;
  max-width: 40%;
  display: flex;
  align-items: center;
  z-index: 10000;

  &::backdrop {
    background: transparent;
  }
`

const Backdrop = styled('div')`
  /* Full-screen backdrop */
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent backdrop */
  z-index: 10000;
`

const Modal = ({ children, open, onClose = () => {} }) => {
  if (!open) return null

  return (
    <>
      <Backdrop
        onClick={() => {
          onClose()
        }}
      />
      <Dialog open>{children}</Dialog>
    </>
  )
}

export default Modal
