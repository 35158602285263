import { Link, Typography } from '@mui/material'

const Copyright = (props) => {
  return (
    <Typography
      variant='body2'
      color='text.secondary'
      align='center'
      {...props}
    >
      {'Copyright © '}
      <Link color='inherit' href='https://accodeing.com/'>
        Accodeing to you
      </Link>
      {' 2019-'}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  )
}

export default Copyright
