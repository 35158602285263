import { styled } from '@mui/material'

const DropIndicator = styled('div')`
  position: relative;
  min-width: 1rem;
  height: 100%;
  min-height: 2rem;
  border: 1px dashed var(--mui-palette-success-dark);
  background-color: var(--mui-palette-success-light);
  opacity: 0;
  transition: opacity 150ms ease-out;

  .first & {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    margin-left: 0.25rem;
  }

  .last & {
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    margin-right: 0.25rem;
  }

  /* Drag over table */
  .drag-over & {
    opacity: 0.5;
  }

  /* Drag over a specific drop target */
  .drag-over > & {
    min-width: 1rem;
    width: max(
      1rem,
      calc(
        var(--width) / ${({ used_attribute_count }) => used_attribute_count + 1}
      )
    );
  }
`

export default DropIndicator
