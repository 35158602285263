import {
  Button,
  CardActions,
  CardContent,
  CardHeader,
  IconButton,
  Modal,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Card as MuiCard,
  styled,
} from '@mui/material'
import { Form, useNavigate, useParams } from 'react-router-dom'

import { useLoading, useAccount } from 'state/useAccountsStore'
import Field from 'components/Field'
import Icon from 'components/Icon'
import { useRoles } from 'state/useRolesStore'

const Card = styled(MuiCard)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 80vw;
  max-height: 100vh;
  background-color: #fff;
  box-shadow: 24;
`

const Main = () => {
  const navigate = useNavigate()
  const { user_id } = useParams()

  const account = useAccount(user_id)
  const roles = useRoles()
  const loading = useLoading()

  if (loading) return null

  console.log(
    'Role',
    account.role_id,
    roles.find(({ id }) => id === account.role_id)
  )

  return (
    <Modal
      open
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <Form method='POST'>
        <Card>
          <CardHeader
            title={account?.created_at ? 'Update account' : 'Create account'}
            subheader={
              account?.created_at
                ? 'Update this account in your organisation.'
                : 'Create a new account for your organisation.'
            }
            action={
              <IconButton aria-label='close' onClick={() => navigate(`..`)}>
                <Icon name='xmark' />
              </IconButton>
            }
          />
          <CardContent>
            <Field
              readOnly
              label='Name'
              helperText='The name of the account.'
              defaultValue={account?.name || ''}
              name='name'
              style={{ marginBottom: '1rem' }}
            />
            <FormControl fullWidth>
              <InputLabel id='role-select-label'>Role</InputLabel>
              <Select
                labelId='role-select-label'
                id='role-select'
                defaultValue={account.role_id}
                label='Age'
              >
                {roles.map(({ id, name }) => (
                  <MenuItem key={id} value={id}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </CardContent>
          <CardActions sx={{ justifyContent: 'end', padding: '0 1rem 1rem' }}>
            <Button
              type='submit'
              name='section'
              value='invite'
              variant='outlined'
              color='secondary'
              size='small'
            >
              <Icon name='lock' style={{ marginRight: '.5rem' }} />
              {account?.created_at ? 'Update account' : 'Create account'}
            </Button>
          </CardActions>
        </Card>
      </Form>
    </Modal>
  )
}

export default Main
