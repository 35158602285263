import { useEffect, useRef } from 'react'
import {
  Button,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  Modal,
  Card as MuiCard,
  styled,
} from '@mui/material'
import { Form, useNavigate, useParams } from 'react-router-dom'

import Field from 'components/Field'
import Icon from 'components/Icon'
import { useSession } from 'state/useSession'
import { set } from 'lodash'

const Card = styled(MuiCard)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 80vw;
  max-height: 100vh;
  background-color: #fff;
  box-shadow: 24;
`

const Main = () => {
  const navigate = useNavigate()
  const { invitation_id } = useParams()
  const {
    user: { id: user_id },
  } = useSession()

  const input = useRef(null)

  useEffect(() => {
    setTimeout(() => {
      if (!input.current) return
      input.current.focus()
    }, 100)
  }, [])

  return (
    <Modal
      open
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <Form method='POST'>
        <Card>
          <CardHeader
            title={'Invite user'}
            subheader={
              'Enter email of user you want to invite to your organisation.'
            }
            action={
              <IconButton aria-label='close' onClick={() => navigate(`../`)}>
                <Icon name='xmark' />
              </IconButton>
            }
          />
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <input type='hidden' name='id' value={invitation_id} />
                <input type='hidden' name='account_id' value={user_id} />
                <Field
                  inputRef={input}
                  autoFocus
                  label='Email'
                  defaultValue={''}
                  name='email'
                />
              </Grid>
            </Grid>
          </CardContent>
          <CardActions sx={{ justifyContent: 'end', padding: '0 1rem 1rem' }}>
            <Button
              type='submit'
              name='section'
              value='invite'
              variant='outlined'
              color='secondary'
              size='small'
            >
              <Icon name='paper-plane' style={{ marginRight: '.5rem' }} />
              Send invite!
            </Button>
          </CardActions>
        </Card>
      </Form>
    </Modal>
  )
}

export default Main
