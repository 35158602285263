import { forwardRef, useState } from 'react'
import { useClose, useMessages } from 'state/useSnackbarStore'
import {
  Alert as MUIAlert,
  Snackbar as MUISnackbar,
  Slide,
  Stack,
} from '@mui/material'

const SlideTransition = (props) => {
  return <Slide {...props} direction='up' />
}

const Alert = forwardRef(function Alert(props, ref) {
  return <MUIAlert elevation={6} ref={ref} variant='filled' {...props} />
})

const Snackbar = ({ id, type = 'info', delay: raw_delay = 5000, children }) => {
  const [open, setOpen] = useState(true)
  const close = useClose()
  const delay = raw_delay < 100 ? raw_delay * 1000 : raw_delay

  const handleClose = (_event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setOpen(false)
    setTimeout(() => close(id), delay)
  }

  return (
    <MUISnackbar
      open={open}
      autoHideDuration={delay}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      onClose={handleClose}
      TransitionComponent={SlideTransition}
      sx={{
        position: 'relative',
        width: 'fit-content',
        inset: 0,
        transform: 'none',
      }}
    >
      <Alert onClose={handleClose} severity={type} sx={{ width: '100%' }}>
        {children}
      </Alert>
    </MUISnackbar>
  )
}

const SnackbarStack = () => {
  const messages = useMessages().slice(-3)

  return (
    <Stack
      direction='column'
      gap={2}
      sx={{
        position: 'absolute',
        bottom: 0,
        left: 0,
        padding: '2rem',
        overflow: 'hidden',
      }}
    >
      {messages.map((message) => (
        <Snackbar key={message.id} {...message}>
          {message.text}
        </Snackbar>
      ))}
    </Stack>
  )
}

export default SnackbarStack
