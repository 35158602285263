import { createBrowserRouter } from 'react-router-dom'

import Dashboard from './pages/Dashboard'
import Debug from './pages/Debug'
import Error from './pages/Error'
import customers from './routes/customers'
import organisations from './routes/organisations'
import products from './routes/products'
import users from './routes/users'
import Template from './template'

export let routes = []

const router = (extra_routes = []) => {
  routes = [
    {
      path: '/',
      element: <Template />,
      errorElement: <Error />,
      children: [
        {
          index: true,
          element: <Dashboard />,
        },
        {
          path: 'debug',
          element: <Debug />,
        },
        customers,
        products,
        organisations,
        users,
        ...extra_routes,
      ],
    },
  ]

  return createBrowserRouter(routes)
}

export default router
