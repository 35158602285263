import { Outlet } from 'react-router-dom'

import { upsert, get_form_data } from 'utilities/routes'
import User from 'pages/User/Document'

const action = async ({ params, request }) => {
  const [data, section] = await get_form_data(request)

  switch (section) {
    case 'userinfo':
      upsert('accounts', { id: params.id, ...data })
      break
    case 'settings':
      upsert('accounts', { id: params.id, settings: data })
      break
  }

  return null
}

const route = {
  id: 'users',
  path: 'users',
  element: <Outlet />,
  children: [
    {
      path: ':id',
      element: <User />,
      action,
    },
  ],
}

export default route
