import { useState } from 'react'
import { DndContext } from '@dnd-kit/core'
import classNames from 'classnames'
import { styled } from '@mui/material'

import { useCurrent } from 'state/useProductInstancesStore'
import { FlexColumn } from 'components/Flex'

import FieldSettings from './FieldSettings'
import DropTarget from './Table/DropTarget'
import Field from './Table/Field'
import Table from './Table'
import Overlay from './Overlay'
// {
//   ...
//   sections: [
//     {
//       wrapped: true,
//       fields: [
//         {
//           attribute: 'customer.name',
//           type: 'text' | 'timestamp' | 'action',
//           width: '1fr',
//           justify: 'center',
//           prefix: 'Belongs to', // Only used for type != 'action'
//           suffix: '...', // Only used for type != 'action'
//           icon: 'user', // Only used for type == 'action'
//           label: 'Some label', // Only used for type == 'action'
//         },
//       ],
//     },
//   ]
// }

const attributes_from = (
  { definition: { variables = [] } } = { definition: {} }
) => {
  const attributes = variables.map(({ variable_name }) => variable_name)

  return ['created_at', 'customer.name', ...attributes]
}

const List = styled(FlexColumn)`
  width: fit-content;
  gap: 0;
  margin-bottom: 3rem;
`

const TableRowEditor = ({
  component_definition = {
    sections: [],
    fields: [],
  },
  set_component_definition,
}) => {
  const product = useCurrent()

  const [dragged_attribute_name, set_dragged_attribute_name] = useState(null)
  const [attribute_name_for_settings, set_attribute_name_for_settings] =
    useState(null)
  const [table_attributes, set_table_attributes] = useState([])
  const [mouse_over_table, set_mouse_over_table] = useState(false)
  const [popover_ref, set_popover_ref] = useState(null)

  if (component_definition?.type !== 'table_row') return null

  const attributes = attributes_from(product)
  const list_attributes = attributes.filter(
    (name) => !table_attributes.includes(name)
  )
  const table_class_names = classNames({
    'drag-over': mouse_over_table && dragged_attribute_name,
    'mouse-over': mouse_over_table || popover_ref,
  })

  function remove_used_attribute(attribute) {
    set_table_attributes((attributes) =>
      attributes.filter((name) => name !== attribute)
    )
  }

  function drag(event) {
    set_dragged_attribute_name(event.active.id)
  }

  function drop(event) {
    const from = event.active.data.current.from
    const to = event.over.id

    const dragging_from_table = from === 'table'
    const dropping_in_table = to.startsWith('index')
    const index = dropping_in_table ? parseInt(to.split('-')[1]) : null

    if (dropping_in_table) {
      set_table_attributes((attributes) => {
        const new_attributes = dragging_from_table
          ? [...attributes.filter((name) => name !== dragged_attribute_name)]
          : [...attributes]

        new_attributes.splice(index, 0, dragged_attribute_name)

        return new_attributes
      })
    } else {
      remove_used_attribute(dragged_attribute_name)
    }
    set_dragged_attribute_name(null)
  }

  const open_settings = (attribute_name) => (ref) => {
    set_attribute_name_for_settings(attribute_name)
    set_popover_ref(ref)
  }

  const close_settings = () => {
    set_attribute_name_for_settings(null)
    set_popover_ref(null)
  }

  const update_field = (attribute, change) => {
    set_component_definition((component_definition) => ({
      ...component_definition,
      fields: {
        ...component_definition.fields,
        [attribute]: {
          ...component_definition.fields?.[attribute],
          ...change,
        },
      },
    }))
  }

  return (
    <DndContext onDragStart={drag} onDragEnd={drop}>
      <Table
        set_mouse_over={set_mouse_over_table}
        fields={component_definition?.fields}
        attributes={table_attributes}
        open_settings={open_settings}
        update_field={update_field}
        className={table_class_names}
      />

      <DropTarget id='list'>
        <List>
          {list_attributes.map((attribute) => (
            <Field
              attribute={attribute}
              type='text'
              key={attribute}
              parent='list'
              name={attribute}
            />
          ))}
        </List>
      </DropTarget>

      <FieldSettings
        attribute={attribute_name_for_settings}
        popover_ref={popover_ref}
        close={close_settings}
        update={(change) => update_field(attribute_name_for_settings, change)}
        {...component_definition.fields?.[attribute_name_for_settings]}
      />

      <Overlay dragged_attribute_name={dragged_attribute_name} />
    </DndContext>
  )
}

export default TableRowEditor
