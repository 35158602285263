import { v4 as uuid } from 'uuid'

const default_pinned_event = () => ({
  id: uuid(),
  created_at: new Date(),
  title: 'Pinned sections',
  type: 'pinned',
  notes: [],
  user: {
    name: 'System',
    avatar_url:
      'https://accodeing.com/assets/images/photos/profile/android.webp',
  },
})

const pinned = (events) =>
  events
    .filter((event) => event.pinned)
    .reduce((pinned_event, { notes = [] }) => {
      pinned_event.notes.push(notes[notes.length - 1])
      return pinned_event
    }, default_pinned_event())

export default pinned
