import { LoadingButton } from '@mui/lab'
import { Paper, Stack } from '@mui/material'

export const Action = ({ isLoading, icon, onClick, children, ...props }) => (
  <LoadingButton
    loading={isLoading}
    loadingPosition='start'
    variant='contained'
    startIcon={icon}
    onClick={onClick}
    color='tertiary'
    sx={{ bgcolor: 'tertiary.dark', color: 'tertiary.light' }}
    {...props}
  >
    {children}
  </LoadingButton>
)

const ActionBar = ({ children }) => (
  <Paper
    sx={{
      position: 'sticky',
      bottom: 0,
      right: 0,
      width: '100%',
      backgroundColor: 'tertiary.main',
      padding: '8px 16px',
      zIndex: 1,
    }}
    elevation={4}
  >
    <Stack direction='row' justifyContent='end'>
      {children}
    </Stack>
  </Paper>
)

export default ActionBar
