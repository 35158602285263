import { useEffect, useState } from 'react'
import { format } from 'prettier/standalone'

import {
  FormControl,
  FormHelperText,
  FormLabel,
  Typography,
} from '@mui/material'

import Field from 'components/Field'
import CodeEditor from 'components/CodeEditor'

export const Editor = ({ code, onChange, state }) => {
  const [log, setLog] = useState([])
  const [run, setRun] = useState(false)

  const [result, setResult] = useState()

  const compartment = new window.Compartment({
    log: (...args) => {
      const strings = args.map((arg) => arg?.toString())
      const line = strings.join('')
      compartment.globalThis.lines.push(line)
    },
    lines: [],
    ...state,
  })

  const setup = () => {
    setLog([])
    compartment.globalThis.lines = []
    setRun(true)
  }

  useEffect(() => {
    const executeAsync = async () => {
      if (!run) return
      setRun(false)
      setLog(compartment.globalThis.lines)

      try {
        const formatted = await format(code, { semi: false, parser: 'babel' })
        onChange({ code: formatted })
        setResult(compartment.evaluate(formatted))
      } catch (err) {
        setLog([...log, `ERROR: ${err.name} - ${err.message}`])
      }
    }
    executeAsync()
  }, [run, code, log, onChange])

  return (
    <FormControl fullWidth>
      <FormLabel style={{ marginBottom: '.5em' }}>Code editor</FormLabel>
      <CodeEditor
        code={code}
        onChange={(code) => onChange({ code })}
        onSave={() => setup()}
      />

      <Typography variant='caption' style={{ margin: '14px 10px 0' }}>
        Log
      </Typography>
      <Field
        style={{ background: '#232323', marginTop: 0 }}
        inputProps={{
          style: { color: '#ddd', WebkitTextFillColor: 'inherit' },
        }}
        InputProps={{
          readOnly: true,
          disabled: true,
        }}
        variant='outlined'
        value={log.join('\n')}
        multiline
        readOnly
      />
      <Field
        size='small'
        label='Result'
        InputProps={{
          readOnly: true,
          disabled: true,
        }}
        readOnly
        value={result ? JSON.stringify(result) : ''}
      />
      <FormHelperText>
        Use cmd+s or ctrl+s to run, see the resulting log and output.
      </FormHelperText>
    </FormControl>
  )
}
