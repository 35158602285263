import { Fragment } from 'react'

import {
  Divider,
  IconButton,
  List,
  Stack,
  Typography,
  styled,
} from '@mui/material'

import Icon from 'components/Icon'

import Item from './Item'

const Grid = styled('div')`
  display: grid;
  grid-template-columns: 1fr auto;
`

const TableOfContent = ({
  title = 'Table of contents',
  subtitle,
  items = [],
  update,
  action,
}) => (
  <List
    dense
    sx={{
      width: '100%',
      bgcolor: 'transparent',
      top: '2rem',
    }}
    component='nav'
    aria-labelledby='nested-list-subheader'
    subheader={
      <Grid>
        <Typography
          color='background.contrastText'
          component='h3'
          variant='h5'
          mb={subtitle ? 0 : 2}
        >
          {title}
        </Typography>
        {action && (
          <IconButton
            sx={{
              alignSelf: 'center',
            }}
            onClick={action}
          >
            <Icon sx={{ color: 'background.contrastText' }} name='add' />
          </IconButton>
        )}
        {subtitle && (
          <Typography
            color='background.contrastText'
            component='p'
            variant='body2'
            mb={2}
            sx={{ gridColumn: '1 / -1' }}
          >
            {subtitle}
          </Typography>
        )}
      </Grid>
    }
  >
    {items.map((item, index) => (
      <Fragment key={item.id}>
        <Divider component='li' />
        <Item
          {...item}
          update={(change) =>
            update([
              ...items.slice(0, index),
              { ...item, ...change },
              ...items.slice(index + 1),
            ])
          }
        />
      </Fragment>
    ))}
  </List>
)

export default TableOfContent
