import { useEffect, useState } from 'react'
import { Button, CardActions } from '@mui/material'
import { Form, useParams } from 'react-router-dom'

import { useCurrent } from 'state/useProductDefinitionsStore'
import Icon from 'components/Icon'
import Columns from 'components/Columns'
import Column from 'components/Column'
import SectionHeader from 'components/SectionHeader'
import JsonEditor from 'components/JsonEditor'

import TableRow from './TableRowEditor'
import General from './General'

// {
//   id: '60657eb9-ea8a-42d1-a7db-5ad3f7deee5d',
//   name: 'Some teable row',
//   description: 'Table row used for the main overview page',
//   type: 'table_row',
//   ...
// }

const types = {
  table_row: { name: 'Table row', icon: 'table-list' },
  card: { name: 'Card', icon: 'square-poll-horizontal' },
  default: { name: 'Component', icon: 'puzzle-piece' },
}

const Components = () => {
  const product_definition = useCurrent()
  const { component_id } = useParams()
  const [component_definition, set_component_definition] = useState({})
  const [instance, set_instance] = useState({})

  const { name: type_name, icon: type_icon } =
    types[product_definition.type] || types.default

  useEffect(() => {
    if (!product_definition?.variables) return

    const component_definition = product_definition?.components?.find(
      ({ id }) => id === component_id
    )
    const variable_names = product_definition?.variables?.map(
      ({ variable_name }) => variable_name
    )
    const variables = variable_names.reduce((acc, key) => {
      acc[key] = ''
      return acc
    }, {})

    set_instance({
      created_at: new Date().toISOString(),
      customer: { name: 'Example AB' },
      ...variables,
    })
    set_component_definition(component_definition || { id: component_id })
  }, [product_definition?.variables])

  return (
    <Columns>
      <Column></Column>
      <Column>
        <Form method='POST'>
          <SectionHeader
            icon={type_icon}
            title={type_name}
            subtitle={`Edit ${type_name.toLowerCase()} properties`}
          />
          <General />
          <TableRow
            component_definition={component_definition}
            set_component_definition={set_component_definition}
            instance={instance}
          />
          <JsonEditor
            item={component_definition}
            set_item={set_component_definition}
          />
          <pre>{JSON.stringify(component_definition, null, 2)}</pre>

          <JsonEditor item={instance} set_item={set_instance} />
          <pre>{JSON.stringify(instance, null, 2)}</pre>

          <CardActions sx={{ justifyContent: 'end', padding: '0 1rem 1rem' }}>
            <Button
              type='submit'
              name='section'
              value='main'
              variant='outlined'
              color='secondary'
              size='small'
            >
              <Icon name='puzzle' style={{ marginRight: '.5rem' }} />
              Save
            </Button>
          </CardActions>
        </Form>
      </Column>
    </Columns>
  )
}

export default Components
