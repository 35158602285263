import { Tooltip } from '@mui/material'
import { Row, Section } from 'components/Table'

const Header = () => (
  <Row>
    <br />

    <Section from='2' to='7' justify='center'>
      <Tooltip
        arrow
        title='Allow listing and showing individual entities. This will allow the user to read any entity of this type within the organisation or company the role is assigned for.'
        placement='top'
      >
        <span>read</span>
      </Tooltip>
      <Tooltip
        arrow
        title='Allow creation of new entities of this type. This will allow the user to create new entities of this type, where available they will be set as the creator of the item. If they are later removed the entity will still remain, but be owned by noone.'
        placement='top'
      >
        <span>create</span>
      </Tooltip>
      <Tooltip
        arrow
        title='Allow updating of existing enteties. This will allow the user to update already existing entities of this type. They will not be made the owner of such an entity when they update it.'
        placement='top'
      >
        <span>update</span>
      </Tooltip>
      <Tooltip
        arrow
        title='Allow deletion of existing entities. This will allow the user to delete existing entities of this type. No further restriction is placed on which entities they are allowed to delete or not.'
        placement='top'
      >
        <span>delete</span>
      </Tooltip>
      <Tooltip
        arrow
        title='Allow the user full access to any entities of this type that they own. This includes the ability to create new entities and then perform all the other actions on the entities that they created. This kan be used on top of the other permissions or stand-alone.'
        placement='top'
      >
        <span>own</span>
      </Tooltip>
    </Section>

    <br />
  </Row>
)

export default Header
