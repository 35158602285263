import { useState, useEffect } from 'react'
import { validate, version } from 'uuid'
import { Card, CardContent, Typography } from '@mui/material'

import Modal from 'components/Modal'
import useSupabase from 'hooks/useSupabase'
import useQueryParams from 'hooks/useQueryParams'
import Icon from 'components/Icon'

const InvalidInvitationModal = () => {
  const supabase = useSupabase()
  const { token } = useQueryParams()
  const [invalid, set_invalid] = useState(false)

  useEffect(() => {
    if (!token || invalid) return

    ;(async () => {
      if (!validate(token) || version(token) !== 4) {
        set_invalid(true)
        return
      }

      const { data: valid } = await supabase.functions.invoke(
        'validate-invitation',
        { body: { token } }
      )
      if (!valid) {
        set_invalid(true)
      }
    })()
  }, [token, invalid, set_invalid, supabase])

  return (
    <Modal open={invalid}>
      <Card>
        <CardContent style={{ padding: '3rem' }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              marginBottom: '2rem',
            }}
          >
            <Typography variant='h2' mb={2}>
              Invalid invitation
            </Typography>
            <span
              style={{
                display: 'inline-flex',
                alignItems: 'center',
                marginBottom: '3rem',
              }}
            >
              <Icon
                color='secondary'
                name='envelope'
                style={{ fontSize: '2rem' }}
              />
              +
              <Icon
                color='secondary'
                name='fire'
                style={{ fontSize: '2rem' }}
              />
              =
              <Icon
                color='secondary'
                name='face-sad-cry'
                style={{ fontSize: '2rem', marginRight: '1rem' }}
              />
            </span>
          </div>

          <Typography variant='h5' mb={1} mt={2}>
            What Happened?
          </Typography>

          <Typography component='p' mb={1}>
            It looks like the invitation link you've followed is either expired
            or invalid. This could be due to:
          </Typography>

          <ul style={{ marginBottom: '3rem', paddingLeft: '1.5rem' }}>
            <Typography component='li' mb={1}>
              The invitation link has expired.
            </Typography>
            <Typography component='li' mb={1}>
              The link has already been used.
            </Typography>
            <Typography component='li' mb={1}>
              The link is incorrect or incomplete.
            </Typography>
          </ul>

          <Typography variant='h5' mb={2}>
            What Can You Do?
          </Typography>
          <Typography component='p' mb={2}>
            <b>Check the Link:</b> If you copied the link, make sure you've
            copied the entire link from your invitation email.
            <br />
            <b>Contact the Organization:</b> If you're sure the link is correct,
            contact the organization that invited you to request a new
            invitation.
            <br />
            <b>Check Your Email:</b> Sometimes, multiple invitations are sent.
            Make sure you're using the most recent one.
          </Typography>
          <Typography component='p' mb={1}>
            If you're still experiencing issues, please ask the one who invited
            you to contact our support team.
          </Typography>
        </CardContent>
      </Card>
    </Modal>
  )
}

export default InvalidInvitationModal
