import { expired } from './expired'

export const pending_invitations = (invitations) =>
  invitations.filter((invitation) => {
    const has_not_expired = !expired(invitation)
    const is_invited = invitation.status === 'pending'
    const is_emailed = invitation.status === 'invited'
    const is_validated = invitation.status === 'validated'
    const is_active = is_invited || is_emailed || is_validated

    return has_not_expired && is_active
  })
