import { styled } from '@mui/material'

import Icon from 'components/Icon'
import { eventTypes as types } from 'constants'

const iconMap = types.reduce((map, { icon, value }) => {
  map[value] = icon
  return map
}, {})

const Dot = styled('div')`
  display: flex;
  box-shadow: var(--mui-shadows-1);
  width: 2rem;
  aspect-ratio: 1;

  border-radius: 50%;
  border: 2px solid transparent;

  color: var(--mui-palette-grey-50);
  background-color: var(--mui-palette-grey-400);

  justify-content: center;
  align-items: center;
`

const Line = styled('div')`
  position: absolute;
  top: 3rem;
  left: calc(50% - 1px);
  width: 2px;
  height: calc(100% - 2rem);
  background-color: var(--mui-palette-grey-400);
`

const Separator = ({ event }) => (
  <div style={{ position: 'relative', height: '100%' }}>
    <Dot
      style={{
        backgroundColor: 'var(--mui-palette-tertiary-light)',
        color: 'var(--mui-palette-tertiary-dark)',
      }}
    >
      <Icon name={iconMap[event.type]} />
    </Dot>
    <Line />
  </div>
)

export default Separator
