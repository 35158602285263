import { Button, Divider, Typography } from '@mui/material'

const ColorTest = ({ colorName, index }) => (
  <>
    <Typography
      variant='h6'
      component='span'
      sx={{ width: '120px', display: 'inline-block' }}
    >
      {colorName.slice(0, 1).toUpperCase() + colorName.slice(1)}
    </Typography>
    <Button color={colorName} variant='text'>
      Text
    </Button>
    <Button color={colorName} variant='contained'>
      Contained
    </Button>
    <Button color={colorName} variant='outlined'>
      Outlined
    </Button>
    {index < 4 && <Divider sx={{ mt: 2, mb: 2 }} />}
  </>
)

const ColorTests = () =>
  ['primary', 'secondary', 'tertiary', 'quaternary', 'quinary'].map(
    (colorName, index) => (
      <ColorTest key={colorName} colorName={colorName} index={index} />
    )
  )

export default ColorTests
