import {
  Avatar,
  CardContent,
  CardHeader,
  IconButton,
  Modal,
  Card as MuiCard,
} from '@mui/material'
import { styled } from '@mui/material/styles'

import Icon from 'components/Icon'

const Card = styled(MuiCard)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 80vw;
  max-height: 80vh;
  background-color: #fff;
  box-shadow: 24;
`

const CardButton = styled(MuiCard)`
  cursor: pointer;
  box-shadow:
    0px 2px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%),
    0px 1px 3px 0px rgb(0 0 0 / 12%);
  &:hover {
    box-shadow:
      0px 3px 3px -2px rgb(0 0 0 / 20%),
      0px 3px 4px 0px rgb(0 0 0 / 14%),
      0px 1px 8px 0px rgb(0 0 0 / 12%);
  }
`

const Variable = ({ name, description, icon, onClick }) => (
  <CardButton onClick={onClick} variant='outlined' sx={{ mb: 2 }}>
    <CardHeader
      title={name}
      subheader={description}
      avatar={
        <Avatar variant='rounded' sx={{ width: 32, height: 32 }}>
          <Icon fontSize='small' name={icon} />
        </Avatar>
      }
    />
  </CardButton>
)

const variableTypes = [
  {
    type: 'input',
    name: 'Input',
    description: 'An analogue to the HTML input element.',
    icon: 'input-pipe',
  },
  {
    type: 'calculation',
    name: 'Calculation',
    description:
      'JS code to dynamically create new values from previous values.',
    icon: 'code',
  },
  {
    type: 'product',
    name: 'Product',
    description:
      'Include an area to add instances of another product as addons',
    icon: 'bags-shopping',
  },
]

const VariableSelect = ({ open, onSelect, onClose }) => (
  <Modal
    open={open}
    onClose={onClose}
    aria-labelledby='modal-modal-title'
    aria-describedby='modal-modal-description'
  >
    <Card>
      <CardHeader
        title='Add variable'
        subheader='Select a variable type to add to the product'
        action={
          <IconButton aria-label='close' onClick={onClose}>
            <Icon name='xmark' />
          </IconButton>
        }
      />
      <CardContent>
        {variableTypes.map((variable) => {
          return (
            <Variable
              key={variable.type}
              {...variable}
              onClick={() => onSelect(variable.type)}
            />
          )
        })}
      </CardContent>
    </Card>
  </Modal>
)

export default VariableSelect
