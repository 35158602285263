import { IconButton, Tooltip } from '@mui/material'

import { Table, Row, Section } from 'components/Table'
import Icon from 'components/Icon'
import get_nested_value from 'utilities/get_nested_value'

import columns_from_row_definition from './columns_from_row_definition'
import calculate_grid_column from './calculate_grid_column'
import * as formatters from './formatters'

const Field = ({ icon, label, prefix, value, suffix, type, justify }) =>
  type === 'action' ? (
    <Tooltip title={label} placement='left' arrow>
      <IconButton onClick={() => {}}>
        <Icon name={icon} />
      </IconButton>
    </Tooltip>
  ) : (
    <span
      style={{
        justifySelf: justify,
      }}
    >
      {formatters[type](prefix, value, suffix)}
    </span>
  )

const NoWrapper = ({ children }) => children

const Fields = ({ section, instance, from, to }) => {
  const Component = section.wrapped ? Section : NoWrapper

  return (
    <Component from={from} to={to}>
      {section.fields.map(({ attribute, ...props }) => (
        <Field
          key={attribute}
          value={get_nested_value(instance, attribute)}
          {...props}
        />
      ))}
    </Component>
  )
}

const ProductInstance = ({ row_definition, instance }) => {
  let column = 0

  return (
    <Table columns={columns_from_row_definition(row_definition)}>
      <Row>
        {row_definition?.sections?.map((section) => {
          let to, from
          ;[to, from, column] = calculate_grid_column(column, section)

          return (
            <Fields
              key={section.description}
              section={section}
              instance={instance}
              from={from}
              to={to}
            />
          )
        })}
      </Row>
    </Table>
  )
}

export default ProductInstance
