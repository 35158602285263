import {
  Button,
  CardActions,
  CardContent,
  CardHeader,
  IconButton,
  Modal,
  Card as MuiCard,
  styled,
} from '@mui/material'
import { Form, useNavigate, useParams } from 'react-router-dom'

import { useLoading, useRole } from 'state/useRolesStore'
import Field from 'components/Field'
import Icon from 'components/Icon'

import Permissions from './PermissionsTable'

const Card = styled(MuiCard)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 80vw;
  max-height: 100vh;
  background-color: #fff;
  box-shadow: 24;
`

const Main = () => {
  const navigate = useNavigate()
  const { role_id } = useParams()

  const role = useRole(role_id)
  const loading = useLoading()

  if (loading) return null

  return (
    <Modal
      open
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <Form method='POST'>
        <Card>
          <CardHeader
            title={role?.created_at ? 'Update role' : 'Create role'}
            subheader={
              role?.created_at
                ? 'Update this role in your organisation.'
                : 'Create a new role for your organisation.'
            }
            action={
              <IconButton aria-label='close' onClick={() => navigate(`../`)}>
                <Icon name='xmark' />
              </IconButton>
            }
          />
          <CardContent>
            <Field
              required
              autoFocus
              label='Name'
              helperText='The name of the role.'
              defaultValue={role?.name || ''}
              name='name'
            />
            <Field
              required
              label='Description'
              helperText='A short description of the role.'
              defaultValue={role?.description || ''}
              name='description'
            />
            <Permissions permissions={role?.permissions || {}} />
          </CardContent>
          <CardActions sx={{ justifyContent: 'end', padding: '0 1rem 1rem' }}>
            <Button
              type='submit'
              name='section'
              value='invite'
              variant='outlined'
              color='secondary'
              size='small'
            >
              <Icon name='lock' style={{ marginRight: '.5rem' }} />
              {role?.created_at ? 'Update role' : 'Create role'}
            </Button>
          </CardActions>
        </Card>
      </Form>
    </Modal>
  )
}

export default Main
