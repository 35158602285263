import { useState } from 'react'
import { Outlet } from 'react-router-dom'
import { Stack } from '@mui/material'

import Columns from 'components/Columns'
import Column from 'components/Column'
import SectionHeader from 'components/SectionHeader'

import SideNavigation from './SideNavigation'
import UserInfo from './UserInfo'
import Settings from './Settings'

const Organisation = () => {
  const [userinfo_expanded, set_userinfo_expanded] = useState(true)
  const [settings_expanded, set_settings_expanded] = useState(true)

  const sections = [
    {
      id: 'userinfo',
      name: 'User info',
      icon: 'user-gear',
      subtitle: 'Your personal information',
      action: () => set_userinfo_expanded(true),
    },
    {
      id: 'settings',
      name: 'Settings',
      icon: 'list',
      subtitle: 'Application settings and preferences',
      action: () => set_settings_expanded(true),
    },
  ]

  return (
    <>
      <Columns>
        <Column>
          <SideNavigation items={sections} />
        </Column>
        <Column>
          <Stack spacing={5}>
            <UserInfo
              expanded={userinfo_expanded}
              set_expanded={set_userinfo_expanded}
              {...sections[0]}
            />
            <Settings
              expanded={settings_expanded}
              set_expanded={set_settings_expanded}
              {...sections[1]}
            />
          </Stack>
        </Column>
      </Columns>
      <Outlet />
    </>
  )
}

export default Organisation
