import { fetchCustomers } from 'state/useCustomersStore'
import { fetchProducts } from 'state/useProductsStore'
import { fetchContacts } from 'state/useContactsStore'
import { fetchEvents } from 'state/useEventsStore'
import { fetchTenants } from 'state/useTenantsStore'
import { fetchInvitations } from 'state/useInvitationsStore'
import { fetchAccounts } from 'state/useAccountsStore'
import { fetchRoles } from 'state/useRolesStore'
import { fetch as fetchProductDefinitions } from 'state/useProductDefinitionsStore'
import { fetch as fetchProductInstances } from 'state/useProductInstancesStore'

const preload = () =>
  Promise.all([
    fetchAccounts(),
    fetchCustomers(),
    fetchContacts(),
    fetchProducts(),
    fetchEvents(),
    fetchTenants(),
    fetchInvitations(),
    fetchRoles(),
    fetchProductDefinitions(),
    fetchProductInstances(),
  ])

export default preload
