import { Paper, Typography } from '@mui/material'

const PinnedPanel = ({ event }) => {
  if (event.notes.length === 0) return null

  return (
    <>
      <div />
      <div />
      <Paper
        sx={{
          position: 'relative',
          padding: '.5rem 1rem',
          background: 'var(--mui-palette-quinary-main)',
        }}
        variant='outlined'
      >
        <Typography variant='h6' component='span'>
          {event.title}
        </Typography>
        {event.notes.map((note) => (
          <Typography key={note.event_id} variant='body1' component='p'>
            {note.notes}
          </Typography>
        ))}
      </Paper>
    </>
  )
}

export default PinnedPanel
