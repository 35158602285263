import React from 'react'
import { Icon as MUIIcon, styled } from '@mui/material'

MUIIcon.name = 'Icon'

const IconElement = styled(MUIIcon)`
  position: relative;
  & > .fa-fw {
    position: absolute;
    inset: 0;
  }
`

const Icon = React.forwardRef(
  (
    {
      name = 'notdef',
      spin = false,
      bounce = false,
      className = '',
      baseClassName = 'fas',
      children,
      ...props
    },
    ref
  ) => (
    <IconElement
      ref={ref}
      {...props}
      className={`${className} fa-${name} fa-fw ${
        spin ? 'fa-spin fa-spin-pulse' : ''
      } ${bounce ? 'fa-bounce' : ''}`}
      baseClassName={baseClassName}
    >
      {children}
    </IconElement>
  )
)

export default Icon
