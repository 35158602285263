import { Avatar, Chip, Stack, Typography } from '@mui/material'

import { absolute_time, relative_time } from 'utilities/datetime'

const Summary = ({ event, versions, relative = true }) => (
  <Stack gap={2} alignItems='end'>
    <Typography variant='body2'>
      {versions > 0
        ? `Updated ${
            relative
              ? relative_time(event.updated_at)
              : absolute_time(event.updated_at)
          } (v${versions + 1})`
        : '\u00A0'}
    </Typography>

    <Typography variant='body2'>
      {`Created ${
        relative
          ? relative_time(event.created_at)
          : absolute_time(event.created_at)
      }`}
    </Typography>

    {event.account && (
      <Chip
        avatar={
          <Avatar alt={event.account.name} src={event.account.avatar_url} />
        }
        label={event.account.name}
        sx={{
          backgroundColor: 'var(--mui-palette-secondary-light)',
          border: '1px solid var(--mui-palette-secondary-main)',
        }}
      />
    )}
  </Stack>
)

export default Summary
