import {
  IconButton,
  ListItem,
  ListItemButton,
  ListItemText,
} from '@mui/material'

import Icon from 'components/Icon'
import scrollTo from 'utilities/scrollTo'

const Action = ({ icon, external_url }) => {
  if (!external_url)
    return (
      <Icon
        sx={{ color: 'var(--mui-palette-action-active)' }}
        fontSize='small'
        name={icon}
      />
    )

  return (
    <IconButton
      size='small'
      edge='end'
      aria-label='comments'
      onClick={() => {
        if (external_url) window.open(external_url, '_blank')
      }}
    >
      <Icon
        sx={{ color: 'background.contrastText' }}
        fontSize='small'
        name={icon}
      />
    </IconButton>
  )
}

const SubItem = ({ id, icon, external_url, title, subtitle }) => (
  <ListItem
    secondaryAction={<Action icon={icon} external_url={external_url} />}
    disablePadding
    dense
  >
    <ListItemButton onClick={() => scrollTo(id)}>
      <ListItemText
        inset
        primaryTypographyProps={{
          variant: 'body1',
          color: 'background.contrastText',
        }}
        secondaryTypographyProps={{
          variant: 'caption',
          color: 'background.contrastText',
        }}
        primary={title}
        secondary={subtitle}
        sx={{
          m: 0,
        }}
      />
    </ListItemButton>
  </ListItem>
)

export default SubItem
