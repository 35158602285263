import {
  Divider,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Menu as MuiMenu,
} from '@mui/material'

import Icon from 'components/Icon'

const Menu = ({ onClick, debug, ...props }) => (
  <MuiMenu
    {...props}
    anchorOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
  >
    <MenuItem onClick={() => onClick()}>
      <ListItemText inset primary='Close' />
      <Icon name='x' />
    </MenuItem>
    <Divider />
    {debug ? (
      <MenuItem onClick={() => onClick('debug')}>
        <ListItemIcon>
          <Icon name='bug-slash' />
        </ListItemIcon>
        <ListItemText primary='Normal' secondary='Return to normal mode' />
      </MenuItem>
    ) : (
      <MenuItem onClick={() => onClick('debug')}>
        <ListItemIcon>
          <Icon name='bug' />
        </ListItemIcon>
        <ListItemText
          primary='Debug'
          secondary='Switch to debug mode of the product'
        />
      </MenuItem>
    )}
    <Divider />
    <MenuItem
      onClick={() => onClick('delete')}
      sx={{
        'backgroundColor': 'error.main',
        'color': '#eee',
        '&:hover': { backgroundColor: 'error.light', color: '#fff' },
      }}
    >
      <ListItemIcon>
        <Icon sx={{ color: '#fff' }} name='trash' />
      </ListItemIcon>
      <ListItemText
        primary='Delete product'
        secondary='Remove product from active use'
        secondaryTypographyProps={{ sx: { color: '#fffa' } }}
      />
    </MenuItem>
  </MuiMenu>
)

export default Menu
