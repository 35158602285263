import { useState } from 'react'
import { Button } from '@mui/material'

import Field from 'components/Field'
import Icon from 'components/Icon'

const SelectUrl = ({ onSubmit }) => {
  const [url, setUrl] = useState('')
  return (
    <>
      <Field
        label='Image URL'
        helperText='Paste a URL to an image'
        placeholder='https://example.com/image/image.png'
        value={url}
        onChange={({ target: { value } }) => setUrl(value)}
      />
      <Button
        variant='contained'
        endIcon={<Icon name='save' />}
        onClick={() => onSubmit(url)}
      >
        Save
      </Button>
    </>
  )
}

export default SelectUrl
