import {
  Avatar,
  Divider,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from '@mui/material'

import { useCustomerHistory } from 'state/useCustomerHistoryStore'
import Icon from 'components/Icon'

import NavLink from './NavLink'
import ListItemButton from './ListItemButton'

const RecentCustomers = () => {
  const history = useCustomerHistory()

  if (!history?.length) return null

  return (
    <>
      <Divider sx={{ my: 1 }} />
      {history.length > 0 && (
        <ListItem>
          <ListItemIcon style={{ minWidth: '42px' }}>
            <Icon name='rectangle-history-circle-user' />
          </ListItemIcon>
          <ListItemText primary='Recent customers' />
        </ListItem>
      )}
      {history.map((customer) => (
        <ListItemButton
          key={customer.number}
          component={NavLink}
          to={`/customers/${customer.id}`}
          style={{ paddingLeft: '8px' }}
        >
          <Tooltip title={customer.name} arrow placement='right'>
            <ListItemAvatar>
              <Avatar
                alt={`${customer.name}'s logotype`}
                src={customer.logo_url}
              />
            </ListItemAvatar>
          </Tooltip>
          <ListItemText
            title={customer.legal_name}
            primary={customer.name}
            secondary={customer.number}
          />
        </ListItemButton>
      ))}
    </>
  )
}

export default RecentCustomers
