const iconMap = {
  email: 'envelope',
  phone: 'phone',
  fax: 'fax',
  mobile: 'mobile',
  address: 'map-marker-alt',
  website: 'globe',
  facebook: 'facebook',
  twitter: 'twitter',
  linkedin: 'linkedin',
  instagram: 'instagram',
  youtube: 'youtube',
  github: 'github',
}

const icon_for = (type) => iconMap[type] || iconMap['other']

export default icon_for
