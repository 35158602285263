import { useState } from 'react'
import { IconButton, Tooltip, styled } from '@mui/material'

import useSupabase from 'hooks/useSupabase'
import Confirm from 'components/Confirm'
import Icon from 'components/Icon'
import { Section } from 'components/Table'

import Status from './Status'

const Actions = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: end;
  & > * {
    aspectratio: '1';
  }
`

const BackRow = ({ id, status, timestamp }) => {
  const supabase = useSupabase()
  const [confirming_delete, set_confirming_delete] = useState(false)
  const [confirming_invite, set_confirming_invite] = useState(false)
  const show_rewoke =
    status === 'pending' || status === 'invited' || status === 'validated'
  const show_resend = status !== 'accepted'

  const rewoke = () => {
    supabase
      .from('invitations')
      .update({ status: 'revoked' })
      .eq('id', id)
      .then(({ error }) => {
        if (error) {
          console.error(error)
          return
        }
      })
  }

  const send = () => {
    supabase.functions
      .invoke('send-invitation', { body: { id } })
      .then(({ error }) => {
        if (error) {
          console.error(error)
          return
        }
      })
  }

  return (
    <Section from='5' to='-1' style={{ marginLeft: '3rem' }}>
      <Status status={status} timestamp={timestamp} />
      <Actions>
        {show_rewoke && (
          <Tooltip title='Rewoke invitation' placement='top' arrow>
            <IconButton onClick={() => set_confirming_delete(true)}>
              <Icon name='user-slash' color='secondary' />
            </IconButton>
          </Tooltip>
        )}
        {show_resend && (
          <Tooltip title='Resend invitation' placement='top' arrow>
            <IconButton onClick={() => set_confirming_invite(true)}>
              <Icon name='paper-plane' color='secondary' />
            </IconButton>
          </Tooltip>
        )}
      </Actions>
      {confirming_delete && (
        <Confirm
          title='Rewoke invite'
          subheader='Are you sure you want to rewoke this invitation?'
          yes_text='yes'
          no_text='no'
          close={(answer) => {
            set_confirming_delete(false)

            if (!answer) return

            rewoke()
          }}
        />
      )}
      {confirming_invite && (
        <Confirm
          title='Send invite'
          subheader='Are you sure you want to send this invitation?'
          yes_text='yes'
          no_text='no'
          close={(answer) => {
            set_confirming_invite(false)

            if (!answer) return

            send()
          }}
        />
      )}
    </Section>
  )
}

export default BackRow
