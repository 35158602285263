import { styled } from '@mui/material'

const shouldForwardProp = (propName) => !['from', 'to'].includes(propName)

const RowSection = styled('div', { shouldForwardProp })`
  display: grid;
  grid-template-columns: subgrid;
  grid-column: ${({ from = 1, to = -1 }) => `${from} / ${to}`};
  align-items: ${({ align }) => (align ? align : 'center')};
  justify-items: ${({ justify }) => (justify ? justify : 'start')};

  position: relative;

  &::before {
    content: '';
    position: absolute;
    inset: 0 -0.75rem;
    background-color: var(--section-background-color);
  }

  & > * {
    white-space: nowrap;
  }
`

const Section = ({ children, ...props }) => {
  return (
    <RowSection {...props} data-type='section'>
      {children}
    </RowSection>
  )
}

export default Section
