import { create } from 'zustand'

import { useCurrentTenant } from 'state/useTenantsStore'
import { useCurrentCustomer } from 'state/useCustomersStore'

const useCustomerHistoryStore = create((set) => ({
  history: {},
  push: (organisation_id, customer) =>
    set((state) => {
      const current_history = state.history[organisation_id] || []
      const filtered_history = current_history.filter(
        (item) => item.id !== customer.id
      )
      const updated_history = [customer, ...filtered_history.slice(0, 2)]

      if (
        updated_history.map(({ id }) => id).join('') ===
        current_history.map(({ id }) => id).join('')
      )
        return state

      return {
        history: { ...state.history, [organisation_id]: updated_history },
      }
    }),
}))

export const useCustomerHistory = () => {
  const { id: organisation_id } = useCurrentTenant()

  return useCustomerHistoryStore(
    (state) => state.history[organisation_id] || []
  )
}

export const usePushCustomerHistory = () => {
  const customer = useCurrentCustomer()
  const { id: organisation_id } = useCurrentTenant()
  const push = useCustomerHistoryStore((state) => state.push)

  return () => push(organisation_id, customer)
}

export default useCustomerHistoryStore
