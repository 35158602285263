import { supabase } from 'hooks/useSupabase'
import { redirect } from 'react-router-dom'

import { get_form_data } from 'utilities/routes'
import Invitations from 'pages/Organisation/Document/Invitations'
import Modal from 'pages/Organisation/Document/Invitations/Modal'

const action = async ({ request }) => {
  const [payload] = await get_form_data(request)

  await supabase.from('invitations').insert(payload)

  return redirect(`../`)
}

const route = {
  path: ':id/invitations',
  element: <Invitations />,
  children: [
    {
      path: ':invitation_id',
      element: <Modal />,
      action,
    },
  ],
}

export default route
