import { Chip as MUIChip, Tooltip, styled } from '@mui/material'

const status_colors = {
  pending: 'var(--mui-palette-info-dark)',
  invited: 'var(--mui-palette-info-main)',
  validated: 'var(--mui-palette-info-light)',
  accepted: 'var(--mui-palette-success-main)',
  expired: 'var(--mui-palette-error-main)',
  revoked: 'var(--mui-palette-error-light)',
}

const Chip = styled(MUIChip)`
  color: #fff;
  background-color: ${({ label }) => status_colors[label]};
  justify-self: normal;
`

const Status = ({ status, timestamp }) => {
  const is_validated = status === 'validated'

  if (!is_validated) return <Chip label={status} />

  return (
    <Tooltip title={timestamp} placement='right'>
      <Chip label={status} />
    </Tooltip>
  )
}

export default Status
