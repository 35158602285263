export const url_for = ({ type, value }) => {
  switch (type) {
    case 'email':
      return `mailto:${value}`
    case 'phone':
    case 'fax':
    case 'mobile':
      return `tel:${value}`
    case 'facebook':
      return `https://facebook.com/${value}/`
    case 'twitter':
      return `https://twitter.com/${value}`
    case 'linkedin':
      return `https://www.linkedin.com/in/${value}/`
    case 'instagram':
      return `https://www.instagram.com/${value}/`
    case 'youtube':
      return `https://www.youtube.com/${value}/`
    case 'github':
      return `https://github.com/${value}`
    default:
      return value
  }
}

export default url_for
