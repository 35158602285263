import { useEffect, useState } from 'react'
import { Divider, ListItemIcon, ListItemText } from '@mui/material'

import { useItems as useProductDefinitions } from 'state/useProductDefinitionsStore'

import Icon from 'components/Icon'
import ListItemButton from './ListItemButton'
import NavLink from './NavLink'

const Products = () => {
  const [products, set_products] = useState([])
  const product_definitions = useProductDefinitions()

  useEffect(() => {
    if (!product_definitions) return

    const products = product_definitions
      .filter(({ settings }) => settings?.index_page?.active)
      .map(
        ({
          icon,
          name,
          settings: {
            index_page: { url },
          },
        }) => ({ icon, text: name, to: `/${url}` })
      )
    set_products(products)
  }, [product_definitions, set_products])

  if (!products?.length) return null

  return (
    <>
      <Divider sx={{ my: 1 }} />
      {products.map(({ icon, text, to, ...props }) => (
        <ListItemButton
          key={text}
          {...props}
          component={NavLink}
          end={true}
          to={to}
        >
          <ListItemIcon>
            <Icon name={icon} />
          </ListItemIcon>
          <ListItemText primary={text} />
        </ListItemButton>
      ))}
    </>
  )
}

export default Products
