import { v4 as uuid } from 'uuid'
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'

import Icon from 'components/Icon'
import Field from 'components/Field'
import { eventTypes as types } from 'constants'

const Form = ({
  event: { id = uuid(), notes = '', title = '', type = 'phone' },
}) => (
  <Grid container spacing={2}>
    <Grid item xs={4}>
      <FormControl fullWidth size='small' sx={{ mt: 1 }}>
        <InputLabel id='event-type'>Type</InputLabel>
        <Select
          labelId='event-type'
          id='event-type-select'
          defaultValue={type}
          name='type'
          label='Type'
          SelectDisplayProps={{
            style: {
              display: 'flex',
              alignItems: 'center',
            },
          }}
        >
          {types.map(({ value, text, icon }) => (
            <MenuItem key={value} value={value}>
              <ListItemIcon>
                <Icon name={icon} fontSize='small' />
              </ListItemIcon>
              <ListItemText>{text}</ListItemText>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Grid>
    <Grid item xs={8}>
      <input type='hidden' name='id' value={id} />
      <Field autoFocus label='Title' defaultValue={title} name='title' />
    </Grid>
    <Grid item xs={12}>
      <Field
        multiline
        minRows={5}
        label='Notes'
        defaultValue={notes}
        name='notes'
      />
    </Grid>
  </Grid>
)

export default Form
