import { styled } from '@mui/material'

const Row = styled('section')`
  display: grid;
  grid-template-columns: subgrid;
  grid-column: 1 / -1;

  align-items: ${({ align }) => (align ? align : 'center')};
  justify-items: ${({ justify }) => (justify ? justify : 'start')};

  &:nth-of-type(even) {
    border-radius: 0.5rem;
    background: var(--section-background-color);
  }

  &:first-of-type > [data-type="section"]::before,  /* Top row */
  hr + & [data-type="section"]::before {
    /* Row after Divider */
    top: -0.5rem;
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
  }

  &:last-of-type > [data-type="section"]::before,   /* Bottom row */
  &:has(+ hr) [data-type="section"]::before {
    /* Row before Divider */
    bottom: -0.5rem;
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }
`

export default Row
