import { useNavigate } from 'react-router-dom'
import { Chip, Tooltip, IconButton, Stack, Typography } from '@mui/material'

import Icon from 'components/Icon'
import { Row, Section } from 'components/Table'
import { useFormatedTime } from 'utilities/datetime'
import { useRole, useLoading } from 'state/useRolesStore'

const User = (user) => {
  const navigate = useNavigate()
  const role = useRole(user.role_id)
  const loading = useLoading()
  const timestamp = useFormatedTime(user.created_at)

  if (loading) return null

  return (
    <Row>
      <Typography variant='body1'>{user.name}</Typography>
      <Typography variant='body1' style={{ fontStyle: 'italic' }}>
        ( {user.email} )
      </Typography>
      <Typography variant='body1'>Joined {timestamp}</Typography>
      <Chip label={role.name} color='primary' />
      <Section from='-2' to='-1'>
        <Stack direction='row' spacing={1}>
          <Tooltip title='Click to copy user UUID'>
            <IconButton
              onClick={() => {
                navigator.clipboard.writeText(user.id)
              }}
            >
              <Icon name='fingerprint' />
            </IconButton>
          </Tooltip>
          <Tooltip title='Email account holder'>
            <IconButton component='a' href={`mailto:${user.email}`}>
              <Icon name='envelope' />
            </IconButton>
          </Tooltip>
          <Tooltip title='Edit user details'>
            <IconButton onClick={() => navigate(`./${user.id}`)}>
              <Icon name='user-pen' />
            </IconButton>
          </Tooltip>
        </Stack>
      </Section>
    </Row>
  )
}

export default User
