import { expired } from './expired'

export const expired_invitations = (invitations) =>
  invitations
    .filter((invitation) => {
      const has_expired = expired(invitation) || invitation.status === 'revoked'
      const is_not_accepted = invitation.status !== 'accepted'

      return has_expired && is_not_accepted
    })
    .map((invitation) => {
      const revoked = invitation.status === 'revoked'
      const status = revoked ? 'revoked' : 'expired'

      return { ...invitation, status }
    })
