import { styled } from '@mui/material/styles'

const AttributeContainer = styled('div')`
  padding: 0.25rem;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  user-select: none;
  & .MuiIcon-root.MuiIcon-fontSizeLarge,
  & p {
    transition: all 0.2s ease-in-out;
    color: var(--mui-palette-tertiary-dark);
  }
  & p {
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &:hover .MuiIcon-root.MuiIcon-fontSizeLarge {
    color: var(--mui-palette-tertiary-light);
  }
`

export default AttributeContainer
