import { create } from 'zustand'
import { mountStoreDevtool } from 'simple-zustand-devtools'
import { useParams } from 'react-router-dom'

import { collection } from 'utilities/routes'

const useStore = create((set) => {
  return {
    loading: true,
    items: [],
    fetch: async () => {
      const items = await collection({
        path: 'product_instances',
        builder: (query) =>
          query
            .select(
              '*, definition:product_definitions( * ), customer:customers( * )'
            )
            .order('created_at', { ascending: true }),
      })

      set({ items, loading: false })
    },
    add: (item) => set(({ items }) => ({ items: [...items, item] })),
    update: (updated) =>
      set((state) => ({
        items: state.items.map((item) =>
          item.id === updated.id ? { ...item, ...updated } : item
        ),
      })),
    remove: ({ id }) =>
      set((state) => ({
        items: state.items.filter((item) => item.id !== id),
      })),
  }
})

export const useLoading = () => useStore((state) => state.loading)
export const useItems = () => useStore((state) => state.items)
export const useItem = (id) =>
  useStore((state) => state.items.find((item) => item.id === id))
export const useCurrent = () => {
  const { id } = useParams()
  return useStore((state) => state.items.find((item) => item.id === id))
}

export const add = useStore.getState().add
export const update = useStore.getState().update
export const remove = useStore.getState().remove
export const fetch = useStore.getState().fetch
export default useStore

if (process.env.NODE_ENV === 'development') {
  mountStoreDevtool('Product instances', useStore)
}
