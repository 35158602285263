import { Fragment, useState } from 'react'
import { v4 as uuid } from 'uuid'
import { AnimatePresence, motion } from 'framer-motion'

import LineAdd from 'components/LineAdd'

import Calculation from './VariableTypes/Calculation'
import SimpleInput from './VariableTypes/SimpleInput'
import VariableSelect from './VariableSelect'

const Variable = ({ type, ...props }) => {
  switch (type) {
    case 'calculation':
      return <Calculation {...props} />
    default:
      return <SimpleInput {...props} />
  }
}

const Variables = ({ variables, update, debugState }) => {
  const [selectOpen, setSelectOpen] = useState(false)
  const [appendIndex, setAppendIndex] = useState()

  const appendVariableAt = (index) => {
    setAppendIndex(index)
    setSelectOpen(true)
  }

  const appendVariable = (type) => {
    if (appendIndex === undefined) return

    const id = uuid()
    update([
      ...variables.slice(0, appendIndex),
      {
        id,
        icon: 'notdef',
        name: `New ${type}`,
        variable_name: '',
        description: '',
        type,
        required: true,
        visible: true,
        attributes: {},
      },
      ...variables.slice(appendIndex),
    ])
    setAppendIndex()
  }

  const deleteVariable = (index) =>
    update([...variables.slice(0, index), ...variables.slice(index + 1)])

  return (
    <>
      <AnimatePresence>
        {variables.map((variable, index) => (
          <Fragment key={variable.id + '_container'}>
            <motion.div
              key={index}
              initial={{ height: 0, x: '100vw' }}
              animate={{ height: 'auto', x: '0' }}
              exit={{ height: 0, x: '100vw' }}
            >
              <LineAdd onClick={() => appendVariableAt(index)} />
            </motion.div>

            <motion.div
              key={variable.id}
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: 'auto' }}
              exit={{ opacity: 0, height: 0 }}
            >
              <Variable
                {...variable}
                onDelete={() => deleteVariable(index)}
                onChange={(change) => {
                  update([
                    ...variables.slice(0, index),
                    { ...variable, ...change },
                    ...variables.slice(index + 1),
                  ])
                }}
                debugState={debugState}
              />
            </motion.div>
          </Fragment>
        ))}
      </AnimatePresence>

      <LineAdd onClick={() => appendVariableAt(variables.length)} />

      <VariableSelect
        open={selectOpen}
        onClose={() => {
          setAppendIndex()
          setSelectOpen(false)
        }}
        onSelect={(type) => {
          appendVariable(type)
          setSelectOpen(false)
        }}
      />
    </>
  )
}

export default Variables
