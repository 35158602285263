import { useState } from 'react'
import {
  Checkbox,
  Collapse,
  IconButton,
  Paper,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material'
import { useNavigate } from 'react-router-dom'

import { absolute_time, relative_time } from 'utilities/datetime'
import useSupabase from 'hooks/useSupabase'
import Icon from 'components/Icon'
import { diff } from 'utilities/diff'

const Version = ({ text, created_at, version }) => (
  <>
    <Typography variant='body2' component='p' mt={1}>
      {version}
    </Typography>
    <Paper
      variant='outlined'
      sx={{
        'backgroundColor': 'var(--mui-palette-grey-100)',
        'p': '.5rem 1rem',
        'flexGrow': 1,
        '& .added': {
          backgroundColor: 'var(--mui-palette-LinearProgress-successBg)',
          color: 'var(--mui-palette-success-dark)',
        },
        '& .removed': {
          backgroundColor: 'var(--mui-palette-LinearProgress-errorBg)',
          color: 'var(--mui-palette-error-dark)',
        },
      }}
      dangerouslySetInnerHTML={{ __html: text }}
    />
    <Typography variant='body2' component='p' mt={1}>
      {`Created ${created_at}`}
    </Typography>
  </>
)

const Versions = ({ versions, relative = true }) => (
  <div
    style={{
      display: 'grid',
      gridTemplateColumns: 'auto 1fr auto',
      margin: '.5rem 0',
      gap: '1rem',
      alignItems: 'start',
      justifyContent: 'space-between',
    }}
  >
    {versions.map((note, index) => {
      const text =
        index <= versions.length - 2
          ? diff(versions[index + 1].notes, note.notes)
          : note.notes
      const created_at = relative
        ? relative_time(note.updated_at)
        : absolute_time(note.updated_at)
      const version = index === 0 ? 'Current' : `v${versions.length - index}`
      return (
        <Version
          key={note.updated_at}
          text={text}
          created_at={created_at}
          version={version}
        />
      )
    })}
  </div>
)

const Panel = ({ event, versions, relative = true }) => {
  const supabase = useSupabase()
  const navigate = useNavigate()
  const [show_versions, set_show_versions] = useState(false)

  return (
    <>
      <Paper
        sx={{
          position: 'relative',
          padding: '.5rem 1rem',
        }}
      >
        <Stack
          direction='row'
          gap={2}
          alignItems='center'
          justifyContent='space-between'
        >
          <Typography variant='h6' component='span'>
            {event.title}
          </Typography>
          <Stack direction='row' gap={0} alignItems='center'>
            <Tooltip
              title={!!event.pinned ? 'Pin event' : 'Unpin event'}
              placement='top'
              arrow
            >
              <Checkbox
                checked={!!event.pinned}
                name='pinned'
                inputProps={{ 'aria-label': 'toggle pinned note' }}
                icon={
                  <Icon
                    name='thumbtack'
                    sx={{
                      opacity: 1.0,
                      color: 'var(--mui-palette-action-active)',
                    }}
                  />
                }
                checkedIcon={
                  <Icon
                    name='thumbtack'
                    style={{ color: 'var(--mui-palette-action-active)' }}
                  >
                    <Icon name='slash' />
                  </Icon>
                }
                onClick={async ({ target: { checked } }) => {
                  await supabase
                    .from('events')
                    .update({ pinned: checked })
                    .eq('id', event.id)
                }}
              />
            </Tooltip>
            <Tooltip title={`Edit, creates new version`} placement='top' arrow>
              <IconButton onClick={() => navigate(`./${event.id}`)}>
                <Icon name='pencil' />
              </IconButton>
            </Tooltip>
            <Tooltip
              title={`Show ${versions} previous versions`}
              placement='top'
              arrow
            >
              <div>
                <IconButton
                  onClick={() => set_show_versions(!show_versions)}
                  disabled={versions === 0}
                >
                  <Icon name='notes' />
                </IconButton>
              </div>
            </Tooltip>
          </Stack>
        </Stack>
        <Collapse in={!show_versions}>
          <Typography variant='body1' component='p'>
            {event.notes}
          </Typography>
        </Collapse>
        <Collapse in={show_versions}>
          <Versions versions={event.previous} relative={relative} />
        </Collapse>
      </Paper>
    </>
  )
}

export default Panel
