import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Tooltip, Popover, Stack, IconButton, styled } from '@mui/material'

import { useFormatedTime } from 'utilities/datetime'
import { Row, Section } from 'components/Table'
import { useAccounts, useLoading } from 'state/useAccountsStore'
import Icon from 'components/Icon'

import Permissions from '../PermissionsTable'
import Users from './Users'

const Text = styled('span')`
  padding: 0.5rem 1rem;
`

const Role = ({
  id,
  name,
  description,
  created_at,
  account_id,
  user_ids = [],
  permissions,
}) => {
  const users = useAccounts()
  const loading = useLoading()
  const navigate = useNavigate()
  const [permissions_open, set_permissions_open] = useState(false)
  const [users_list_open, set_users_list_open] = useState(false)
  const timestamp = useFormatedTime(created_at)

  if (loading) return null

  const user = users.find((user) => user.id === account_id)
  const number_of_users = user_ids?.length || 0
  const role_users = users.filter(({ id }) => user_ids?.includes(id))

  return (
    <Row align='start'>
      <Text>
        <b>{name}</b>
      </Text>
      <Text>{description}</Text>
      <Text>
        Created {timestamp} by {user.name}
      </Text>
      <Text>
        Used by {number_of_users} {number_of_users === 1 ? 'user' : 'users'}.
      </Text>
      <Section from='-2' to='-1' align='end' style={{ marginRight: '.75rem' }}>
        <Stack direction='row' gap={1} style={{ padding: '.5rem 0' }}>
          <Tooltip title='Toggle permission details'>
            <IconButton onClick={() => set_permissions_open(true)}>
              <Icon name='list-tree' />
            </IconButton>
          </Tooltip>
          <Tooltip title='Toggle users list'>
            <IconButton onClick={() => set_users_list_open(true)}>
              <Icon name='users' />
            </IconButton>
          </Tooltip>
          <Tooltip title='Edit role'>
            <IconButton onClick={() => navigate(`./${id}`)}>
              <Icon name='edit' />
            </IconButton>
          </Tooltip>
        </Stack>
      </Section>
      <Popover
        open={permissions_open}
        onClose={() => set_permissions_open(false)}
        anchorReference='anchorEl'
        anchorEl={document.getElementById('root')}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
      >
        <div style={{ margin: '1rem' }}>
          <Permissions permissions={permissions} readOnly />
        </div>
      </Popover>
      <Popover
        open={users_list_open}
        onClose={() => set_users_list_open(false)}
        anchorReference='anchorEl'
        anchorEl={document.getElementById('root')}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
      >
        <Users name={name} users={role_users} />
      </Popover>
    </Row>
  )
}

export default Role
