import { upsert, get_form_data } from 'utilities/routes'

import Components from 'pages/Product/Document/Components'
import Component from 'pages/Product/Document/Components/Document'

const action = async ({ params, request }) => {
  const [data] = await get_form_data(request)

  //upsert('product_definitions', { id: params.id, ...data })

  return null
}

const route = {
  path: ':id/components',
  children: [
    {
      index: true,
      element: <Components />,
    },
    {
      path: ':component_id',
      element: <Component />,
      action,
    },
  ],
}

export default route
