const columns_from_row_definition = (definition) => {
  return (
    definition?.sections
      ?.reduce((widths, section) => {
        const sectionWidths = section.fields.reduce((fieldWidths, field) => {
          fieldWidths.push(field.width)
          return fieldWidths
        }, [])
        return widths.concat(sectionWidths)
      }, [])
      .join(' ') || ''
  )
}

export default columns_from_row_definition
