import { IconButton, styled, Typography } from '@mui/material'
import { useRef, useState } from 'react'

import Icon from 'components/Icon'

const Header = styled('div')`
  display: grid;
  grid-template-columns: ${({ icon }) => (icon ? 'auto 1fr auto' : '1fr auto')};
  grid-template-rows: 40px auto;
  align-items: end;
  margin-bottom: 1rem;
  gap: 0.5rem 1rem;
  color: color-mix(in srgb, var(--mui-palette-primary-dark) 80%, transparent);
`

const SectionHeader = ({
  id,
  icon,
  title,
  name,
  subtitle,
  on_menu_select,
  menu: Menu,
  action,
}) => {
  const ref = useRef()
  const [open, set_open] = useState(false)

  return (
    <Header id={id} icon={icon}>
      {icon && (
        <Icon
          name={icon}
          style={{
            gridRow: '1 / 3',
            alignSelf: 'center',
            fontSize: '2rem',
          }}
        />
      )}
      <Typography
        component='h2'
        variant='h4'
        style={{
          whiteSpace: 'nowrap',
          lineHeight: '1.5rem',
          color:
            'color-mix(in srgb, var(--mui-palette-primary-dark) 80%, transparent)',
        }}
      >
        {title || name}
      </Typography>
      <Typography component='p' variant='body2'>
        {subtitle}
      </Typography>
      <div style={{ gridArea: '1 / -2 / 3 / -1', alignSelf: 'end' }}>
        {Menu && (
          <>
            <IconButton ref={ref} onClick={() => set_open(true)}>
              <Icon name='bars' />
            </IconButton>
            <Menu
              anchorEl={ref.current}
              open={open}
              debug={false}
              onClose={() => set_open(false)}
              onClick={(action) => {
                on_menu_select(action)
                set_open(false)
              }}
            />
          </>
        )}
        {action && action}
      </div>
    </Header>
  )
}

export default SectionHeader
