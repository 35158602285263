import Field from 'components/Field'

const Input = ({
  //icon,
  name,
  variable_name,
  description = '',
  type,
  required,
  //visible,
  attributes: { default_value = '', regex, min, max },
  state,
  update,
}) => (
  <Field
    required={required}
    label={name}
    defaultValue={state[variable_name] ? undefined : default_value}
    helperText={description}
    update={(value) => update({ [variable_name]: value })}
    value={state[variable_name]}
    type={type}
  />
)

export default Input
