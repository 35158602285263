import {
  Button,
  Card,
  CardActions,
  CardContent,
  Collapse,
  IconButton,
  styled,
} from '@mui/material'
import { Form } from 'react-router-dom'

import Field from 'components/Field'
import ImageSelect from 'components/ImageSelect'
import SectionHeader from 'components/SectionHeader'
import Icon from 'components/Icon'
import { useCurrentAccount } from 'state/useAccountsStore'

const Grid = styled('div')`
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
  align-items: start;
  justify-content: center;
`

const UserInfo = ({ expanded, set_expanded, ...header_props }) => {
  const user = useCurrentAccount()

  if (!user.id) return null

  return (
    <Form method='post'>
      <SectionHeader
        {...header_props}
        action={
          <IconButton
            onClick={() => set_expanded(!expanded)}
            aria-expanded={expanded}
            aria-label='show more'
            size='small'
          >
            <Icon name={expanded ? 'chevron-up' : 'chevron-down'} />
          </IconButton>
        }
      />
      <Card id={user.id}>
        <Collapse in={expanded} timeout='auto' unmountOnExit>
          <CardContent>
            <Grid>
              <Field
                label='Name'
                helperText='Your name as it appears in the system'
                defaultValue={user.name}
                name='name'
                style={{ margin: 0 }}
              />
              <Field
                label='Email'
                helperText='The email address you registerd with'
                defaultValue={user.email}
                readOnly
                name='email'
                style={{ margin: 0 }}
              />
              <ImageSelect
                name='avatar_url'
                url={user.avatar_url}
                path={user.id}
              />
            </Grid>
          </CardContent>
        </Collapse>
        <CardActions sx={{ justifyContent: 'end' }}>
          <Button
            type='submit'
            name='section'
            value='userinfo'
            variant='text'
            color='secondary'
            size='small'
          >
            Save
          </Button>
        </CardActions>
      </Card>
    </Form>
  )
}

export default UserInfo
