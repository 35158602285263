import { Outlet } from 'react-router-dom'

import {
  useItems as useProductInstances,
  useLoading as useProductInstancesLoading,
} from 'state/useProductInstancesStore'
import {
  useItems as useProductDefinitions,
  useLoading as useProductDefinitionsLoading,
} from 'state/useProductDefinitionsStore'
import CollectionHeader from 'components/CollectionHeader'
import { Table } from 'components/Table'
import search from 'utilities/search'
import useQueryParam from 'hooks/useQueryParam'

import ProductInstance from './ProductInstance'

const Dynamic = () => {
  const product_url = window.location.pathname.split('/')[1]

  const search_term = useQueryParam('search')
  const filter_fields = ['name'] // TODO: make this dynamic in settings

  const product_instances = useProductInstances()
  const product_definitions = useProductDefinitions()

  const instances_loading = useProductInstancesLoading()
  const definitions_loading = useProductDefinitionsLoading()
  const loading = instances_loading || definitions_loading

  if (loading) return <h1>Loading...</h1>

  const product_definition = product_definitions.find(
    ({ settings }) => settings?.index_page?.url === product_url
  )

  if (!product_definition) return <h1>Product definition not found</h1>

  const items = product_instances.filter(
    ({ id }) => id === product_definition.id
  )
  const filtered_items = search(items, search_term, ...filter_fields)

  return (
    <>
      <CollectionHeader
        title={product_definition.name + ' instances'}
        fields={filter_fields.join(', ')}
      />
      {filtered_items.map((item) => (
        <ProductInstance key={item.id} instance={item} />
      ))}
      {filtered_items.length === 0 && <h3>No instances found</h3>}
      <Outlet />
    </>
  )
}

export default Dynamic
