import Textarea from './Textarea'
import Radio from './Radio'
import Checkbox from './Checkbox'
import InputField from './Input'

const Input = (props) => {
  switch (props.attributes.type) {
    case 'text':
      return <Textarea {...props} />
    case 'radio':
      return <Radio {...props} />
    case 'checkbox':
      return <Checkbox {...props} />
    default:
      return <InputField {...props} />
  }
}

export default Input
