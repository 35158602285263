import React from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Badge,
  Card,
  CardActions,
  CardContent,
  Divider,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material'
import { styled } from '@mui/material/styles'

import Can from 'components/Can'
import Icon from 'components/Icon'
import Logotype from 'components/Logotype'
import { relative_time } from 'utilities/datetime'

const StyledBadge = styled(Badge)`
  & .MuiBadge-badge {
    right: 2px;
    top: 14px;
    min-width: 0;
    min-height: 0;
    padding: 0;
    height: auto;
    width: auto;
    color: var(--mui-palette-primary-light);
    background: #fff;
    border: 1px solid #fff;
  }
`

const Grid = styled('section')`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr auto;
  grid-template-areas:
    'b  a'
    'c  c';
  grid-gap: 1em;
`

const Header = styled('div')`
  grid-area: b;
  display: flex;
  flex-direction: column;
`

const Description = styled('div')`
  grid-area: c;
`

const Organisation = ({
  id,
  created_at,
  settings = {},
  name,
  dns_name,
  domains = [],
  description,
  logo_url,
}) => {
  const navigate = useNavigate()
  const active_page = window.location.pathname === `./${id}`

  return (
    <Card
      style={{
        cursor: active_page ? 'auto' : 'pointer',
        containerType: 'inline-size',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <CardContent
        onClick={() => {
          navigate(`./${id}`)
        }}
      >
        <Grid className='my-grid'>
          <Logotype src={logo_url} alt={`${name} logo`} />
          <Header>
            <Typography variant='h5' mb={0} noWrap>
              {name || '-'}
            </Typography>
            <Typography variant='subtitle1' pt={0} noWrap>
              {dns_name || '-'}
            </Typography>
            <Typography variant='subtitle2' pt={0} noWrap>
              Created {relative_time(created_at)}
            </Typography>
            <Typography variant='h6' noWrap>
              Valid login domains
            </Typography>
            <Typography variant='body2' style={{ whiteSpace: 'pre-line' }}>
              {domains.join(',\n') || '-'}
            </Typography>
          </Header>
          <Description>
            <Divider />
            <Typography
              variant='body'
              paragraph
              mt={2}
              sx={{ minHeight: '1.5rem' }}
            >
              {description}
            </Typography>
          </Description>
        </Grid>
      </CardContent>
      <CardActions>
        <Can create='invitations' tenant_id={id}>
          <Tooltip title='Invitations'>
            <IconButton
              onClick={() => {
                navigate(`./${id}/invitations`)
              }}
              aria-label='Invitations'
            >
              <StyledBadge
                badgeContent={
                  <Icon
                    name='circle-plus'
                    style={{ fontSize: '12px', width: 'unset' }}
                  />
                }
                color='primary'
              >
                <Icon name='envelopes' />
              </StyledBadge>
            </IconButton>
          </Tooltip>
        </Can>
      </CardActions>
    </Card>
  )
}

export default React.memo((props) => <Organisation {...props} />)
