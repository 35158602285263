import { useEffect, useState } from 'react'
import { styled } from '@mui/material'

import CodeEditor from 'components/CodeEditor'

const Grid = styled('main')`
  display: grid;
  grid-template-columns: 1fr;
  grid-row: span 2;
`

const Error = styled('div')`
  background-color: var(--mui-palette-error-main);
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 4px;

  &::before {
    content: '';
    display: block;
    color: #888;
    font-style: italic;
  }

  &:empty {
    color: transparent;
    background-color: transparent;
  }
  &:empty::before {
    content: 'No errors';
  }
`

const JsonEditor = ({ item, set_item }) => {
  const [code, set_code] = useState(JSON.stringify(item, null, 2) || '')
  const [error, set_error] = useState()

  useEffect(() => {
    set_code(JSON.stringify(item, null, 2))
  }, [item])

  const update = (code) => {
    set_code(code)
    try {
      set_item(JSON.parse(code))
      set_error()
    } catch (e) {
      set_error(e.message)
    }
  }

  return (
    <Grid>
      <CodeEditor code={code} onChange={update} language='json' line_numbers />
      <Error>{error}</Error>
    </Grid>
  )
}

export default JsonEditor
