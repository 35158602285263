import { Stack, Switch, Typography } from '@mui/material'

const Toggle = ({
  name,
  checked,
  defaultChecked,
  set_checked = () => {},
  title,
  unchecked_label,
  checked_label,
  align = 'center',
}) => (
  <Stack direction='column' alignItems={align}>
    <Typography>{title}</Typography>
    <Stack
      direction='row'
      spacing={1}
      alignItems='center'
      sx={{ marginBottom: '-.5rem ' }}
    >
      <Typography variant='body2' component='span'>
        {unchecked_label}
      </Typography>
      <Switch
        checked={checked}
        defaultChecked={defaultChecked}
        onChange={() =>
          set_checked(checked !== undefined ? !checked : !defaultChecked)
        }
        sx={{
          '& .MuiSwitch-switchBase': {
            color: 'var(--mui-palette-secondary-dark)',
          },
        }}
        inputProps={{
          'name': name,
          'aria-label': 'Toggle relative/absolute timestamps',
        }}
      />
      <Typography variant='body2' component='span'>
        {checked_label}
      </Typography>
    </Stack>
  </Stack>
)

export default Toggle
