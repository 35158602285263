import { Outlet, useNavigate } from 'react-router-dom'
import { v4 as uuid } from 'uuid'

import { Table } from 'components/Table'
import { useLoading, useRoles } from 'state/useRolesStore'
import CollectionHeader from 'components/CollectionHeader'
import LineAdd from 'components/LineAdd'
import search from 'utilities/search'
import useQueryParam from 'hooks/useQueryParam'

import Role from './Role'

const Roles = () => {
  const roles = useRoles()
  const loading = useLoading()
  const search_term = useQueryParam('search')

  const navigate = useNavigate()

  if (loading) return null

  const filtered_roles = search(roles, search_term, 'name', 'email')

  return (
    <div>
      <CollectionHeader title='Roles' />
      <LineAdd
        tooltip='Create new role'
        onClick={() => navigate(`./${uuid()}`)}
      />
      <Table columns='1fr 2fr 3fr 1fr auto'>
        {filtered_roles.map((role) => (
          <Role key={role.id} {...role} />
        ))}
      </Table>
      <Outlet />
    </div>
  )
}

export default Roles
