import { useCurrentEvent, useNewEvent } from 'state/useEventsStore'

import Main from './Main'

const Modal = () => {
  const event = useCurrentEvent()
  const exists = useNewEvent(event.id)

  const latest_note =
    event.notes && event.notes.length ? event.notes[event.notes.length - 1] : {}

  const full_event = {
    ...event,
    ...latest_note,
  }

  return <Main event={full_event} exists={exists} />
}

export default Modal
