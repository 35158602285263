import { useState } from 'react'
import {
  Button,
  IconButton,
  ListItem,
  ListItemText,
  ListSubheader,
  Stack,
  Typography,
} from '@mui/material'

import Field from 'components/Field'
import Icon from 'components/Icon'
import DraggableList from 'components/DraggableList'

export const RadioGroup = ({ alternatives = [], onChange }) => {
  const [newAlternative, setNewAlternative] = useState({
    value: '',
    label: '',
  })

  const onDelete = (index) => {
    onChange({
      alternatives: [
        ...alternatives.slice(0, index),
        ...alternatives.slice(index + 1),
      ],
    })
  }

  return (
    <>
      <Typography variant='h5'>Alternatives</Typography>
      <DraggableList
        dense
        subheader={
          alternatives.length ? null : (
            <ListSubheader component='div'>
              Add alternatives below
            </ListSubheader>
          )
        }
        values={alternatives}
        onChange={onChange}
      >
        {alternatives.map(({ value, label }, index) => (
          <ListItem
            key={value}
            secondaryAction={
              <IconButton
                edge='end'
                aria-label='delete'
                onClick={() => onDelete(index)}
              >
                <Icon name='trash' />
              </IconButton>
            }
          >
            <ListItemText primary={label} secondary={value} />
          </ListItem>
        ))}
      </DraggableList>

      <Typography variant='h6'>New alternative</Typography>
      <Stack direction='row' gap={2} alignItems='center'>
        <Field
          label='Name'
          value={newAlternative.label}
          helperText='The alternative as it will be shown in the UI'
          update={(value) =>
            setNewAlternative({ ...newAlternative, label: value })
          }
        />
        <Field
          label='Value'
          value={newAlternative.value}
          helperText='The value as it will be saved in the variable'
          update={(value) => setNewAlternative({ ...newAlternative, value })}
        />
        <Button
          variant='outlined'
          onClick={() => {
            onChange({ alternatives: [...alternatives, newAlternative] })
            setNewAlternative({ value: '', label: '' })
          }}
        >
          Add
        </Button>
      </Stack>
    </>
  )
}
