import Grid from '@mui/material/Unstable_Grid2'

import { styled } from '@mui/material/styles'

const AttributeButton = styled(Grid)`
  border-radius: 4px;
  padding: 0;
  box-shadow: ${({ odd }) =>
    odd ? 'var(--mui-shadows-2)' : 'var(--mui-shadows-1)'};
  background-color: ${({ odd }) =>
    odd ? 'inherit' : 'var(--mui-palette-common-background)'};
  transition: all 0.2s ease-in-out;
  &:hover {
    box-shadow: ${({ odd }) =>
      odd ? 'var(--mui-shadows-4)' : 'var(--mui-shadows-3)'};
  }
`

export default AttributeButton
