import {
  Button,
  Card,
  CardActions,
  CardContent,
  Collapse,
  IconButton,
  styled,
} from '@mui/material'
import { Form } from 'react-router-dom'

import Field from 'components/Field'
import ImageSelect from 'components/ImageSelect'
import SectionHeader from 'components/SectionHeader'
import Icon from 'components/Icon'
import { useCurrentCustomer } from 'state/useCustomersStore'

const Grid = styled('div')`
  display: grid;
  grid-template-columns: 3fr 2fr;
  grid-template-areas: 'common_name number' 'legal_name vat' 'description description' 'logo logo';
  gap: 1rem;
  align-items: start;
  justify-content: center;
`

// TODO: Add check button to EU VAT registry: https://ec.europa.eu/taxation_customs/vies/rest-api/ms/SE/vat/se556541072601

const General = ({ expanded, set_expanded }) => {
  const customer = useCurrentCustomer()

  return (
    <Form id='general' method='post'>
      <SectionHeader
        id='company-info'
        title='Company info'
        subtitle='Legal information about the company'
        icon='square-info'
        action={
          <IconButton
            onClick={() => set_expanded(!expanded)}
            aria-expanded={expanded}
            aria-label='show more'
            size='small'
          >
            <Icon name={expanded ? 'chevron-up' : 'chevron-down'} />
          </IconButton>
        }
      />
      <Card id={customer.id}>
        <Collapse in={expanded} timeout='auto' unmountOnExit>
          <CardContent>
            <Grid>
              <Field
                label='Common name'
                helperText='Colloquial company name, like "Accodeing"'
                defaultValue={customer.name}
                name='name'
                style={{ margin: 0, gridArea: 'common_name' }}
              />
              <Field
                label='Legal name'
                helperText='Legal company name, like "Accodeing to you KB"'
                defaultValue={customer.legal_name}
                name='legal_name'
                style={{ margin: 0, gridArea: 'legal_name' }}
              />
              <Field
                label='Organisational number (999999-9999)'
                helperText='The TIN number for the legal entity'
                defaultValue={customer.number}
                name='number'
                style={{ margin: 0, gridArea: 'number' }}
              />
              <Field
                label='VAT number (SE999999999901)'
                helperText='European Union VAT identification number'
                defaultValue={customer.vat}
                name='vat'
                style={{ margin: 0, gridArea: 'vat' }}
              />
              <ImageSelect
                name='logo_url'
                url={customer.logo_url}
                path={customer.name}
                style={{ gridArea: 'logo' }}
              />
              <Field
                multiline
                minRows={3}
                label='Description'
                helperText='Description of the company and its focus'
                defaultValue={customer.description}
                name='description'
                style={{ margin: 0, gridArea: 'description' }}
              />
            </Grid>
          </CardContent>
        </Collapse>
        <CardActions sx={{ justifyContent: 'end' }}>
          <Button
            type='submit'
            name='section'
            value='general'
            variant='text'
            color='secondary'
            size='small'
          >
            Save
          </Button>
        </CardActions>
      </Card>
    </Form>
  )
}

export default General
