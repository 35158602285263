import Organisation from 'pages/Organisation/Document'

import invitations from './invitations'

const route = {
  path: ':id',
  element: <Organisation />,
  children: [invitations],
}

export default route
