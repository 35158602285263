import { createClient } from '@supabase/supabase-js'

import {
  addProduct,
  updateProduct,
  deleteProduct,
} from 'state/useProductsStore'
import {
  addCustomer,
  updateCustomer,
  deleteCustomer,
} from 'state/useCustomersStore'
import {
  addContact,
  updateContact,
  deleteContact,
} from 'state/useContactsStore'
import { fetchEvents } from 'state/useEventsStore'
import { subscriptionHandler as invitationsHandler } from 'state/useInvitationsStore'
import { subscriptionHandler as accountsHandler } from 'state/useAccountsStore'
import { subscriptionHandler as rolesHandler } from 'state/useRolesStore'

export const supabase = createClient(
  'https://gkgeuxqzyyypmsitvxmn.supabase.co',
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImdrZ2V1eHF6eXl5cG1zaXR2eG1uIiwicm9sZSI6ImFub24iLCJpYXQiOjE2NjUyMTY1NjUsImV4cCI6MTk4MDc5MjU2NX0.znMrYbBep2ApRne0GyzwqiZvgAcWEk_bcK-9SXFKOIA'
)

const update = (payload, insert, update, remove) => {
  console.log(`${payload.table} changed`, payload)
  switch (payload.eventType) {
    case 'INSERT':
      insert(payload.new)
      break
    case 'UPDATE':
      update(payload.new)
      break
    case 'DELETE':
      remove(payload.new.id)
      break
    default:
      console.log('unknown event type')
  }
}

supabase
  .channel('schema-db-changes')
  .on(
    'postgres_changes',
    {
      event: '*',
      schema: 'public',
    },
    (payload) => {
      console.log('postgres_changes', payload)
      switch (payload.table) {
        case 'products':
          update(payload, addProduct, updateProduct, deleteProduct)
          break
        case 'customers':
          update(payload, addCustomer, updateCustomer, deleteCustomer)
          break
        case 'contacts':
          update(payload, addContact, updateContact, deleteContact)
          break
        case 'events':
          fetchEvents()
          break
        case 'event_notes':
          fetchEvents()
          break
        case 'invitations':
          invitationsHandler(payload.eventType, payload.new || payload.old)
          break
        case 'accounts':
          accountsHandler(payload.eventType, payload.new || payload.old)
          break
        case 'roles':
          rolesHandler(payload.eventType, payload.new || payload.old)
        default:
          console.log('unknown change', payload)
      }
    }
  )
  .subscribe()

const useSupabase = () => {
  return supabase
}

export default useSupabase
