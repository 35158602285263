import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Checkbox as MuiCheckbox,
  RadioGroup,
  Typography,
} from '@mui/material'

const Checkbox = ({
  icon,
  name,
  variable_name,
  description = '',
  required,
  visible,
  attributes: { default_value, alternatives },
  state: { [variable_name]: selectedValues = [] },
  update,
}) => {
  const handleChange = (value, checked) => {
    if (checked) {
      update({ [variable_name]: [...selectedValues, value] })
    } else {
      update({
        [variable_name]: [...selectedValues.filter((x) => x !== value)],
      })
    }
  }

  return (
    <FormControl sx={{ paddingLeft: '14px' }}>
      <FormLabel id={`${variable_name}-radio-buttons-group-label`}>
        <Typography variant='caption'>{name}</Typography>
      </FormLabel>
      <RadioGroup
        aria-labelledby={`${variable_name}-radio-buttons-group-label`}
        defaultValue={default_value || alternatives[0].value}
        name={`${variable_name}-radio-buttons-group`}
      >
        {alternatives.map(({ value, label }) => (
          <FormControlLabel
            key={value}
            value={value}
            control={
              <MuiCheckbox
                onChange={({ target: { checked } }) =>
                  handleChange(value, checked)
                }
                checked={selectedValues.includes(value)}
              />
            }
            label={<Typography variant='body1'>{label}</Typography>}
          />
        ))}
      </RadioGroup>
      <Typography variant='caption' sx={{ opacity: 0.5 }}>
        {description}
      </Typography>
    </FormControl>
  )
}

export default Checkbox
