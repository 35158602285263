import { useState } from 'react'
import { Typography, ToggleButton, ToggleButtonGroup } from '@mui/material'

import useSettings from 'hooks/useSettings'

const DisplayTimeAs = () => {
  const settings = useSettings()
  const { display_time_as } = useSettings()
  const [value, set_value] = useState(display_time_as)

  console.log('settings', settings)

  return (
    <>
      <Typography>Display times as </Typography>
      <ToggleButtonGroup
        color='primary'
        value={value}
        exclusive
        onChange={(_event, new_value) => {
          if (new_value !== null) {
            set_value(new_value)
          }
        }}
        aria-label='Platform'
      >
        <ToggleButton value='absolute'>Absolute</ToggleButton>
        <ToggleButton value='relative'>Relative</ToggleButton>
      </ToggleButtonGroup>
      <input
        type='text'
        name='display_time_as'
        readOnly
        value={value}
        style={{ display: 'none' }}
      />
    </>
  )
}

export default DisplayTimeAs
