const Textarea = ({
  icon,
  name,
  variable_name,
  description = '',
  required,
  visible,
  attributes: { default_value = '' },
  state,
  update,
}) => {}

export default Textarea
