import { v4 as uuid } from 'uuid'
import { Outlet, useNavigate } from 'react-router-dom'

import LineAdd from 'components/LineAdd'

const Components = ({ components = [] }) => {
  const navigate = useNavigate()

  return (
    <>
      {components.map(({ name, description, sections }) => (
        <div key={name}>
          <h2>{name}</h2>
          <p>{description}</p>
          {sections.map(({ description, wrapped, fields }) => (
            <div key={description}>
              {fields.map(
                ({
                  attribute,
                  type,
                  width,
                  justify,
                  prefix,
                  suffix,
                  icon,
                  label,
                }) => (
                  <div key={attribute}>
                    <span>key</span>
                    <span>{attribute}</span>
                    <br />
                    <span>attribute</span>
                    <span>{attribute}</span>
                    <br />
                    <span>type</span>
                    <span>{type}</span>
                    <br />
                    <span>width</span>
                    <span>{width}</span>
                    <br />
                    <span>justify</span>
                    <span>{justify}</span>
                    <br />
                    <span>prefix</span>
                    <span>{prefix}</span>
                    <br />
                    <span>suffix</span>
                    <span>{suffix}</span>
                    <br />
                    <span>icon</span>
                    <span>{icon}</span>
                    <br />
                    <span>label</span>
                    <span>{label}</span>
                    <br />
                  </div>
                )
              )}
            </div>
          ))}
        </div>
      ))}
      <LineAdd
        tooltip='Add component'
        onClick={() => navigate(`./${uuid()}`)}
      />
      <Outlet />
    </>
  )
}

export default Components
