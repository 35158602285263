import { Fragment } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Divider,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material'

import DraggableList from 'components/DraggableList'
import scrollTo from 'utilities/scrollTo'
import SubItem from './SubItem'
import Icon from 'components/Icon'

const Item = ({
  id,
  icon,
  name,
  subtitle,
  external_url,
  children,
  action,
  update,
}) => {
  const navigate = useNavigate()
  return (
    <>
      <ListItemButton
        onClick={() => {
          external_url ? navigate(external_url) : scrollTo(id)
          if (action) action()
        }}
      >
        <ListItemIcon>
          <Icon sx={{ color: 'background.contrastText' }} name={icon} />
        </ListItemIcon>
        <ListItemText
          primaryTypographyProps={{
            variant: 'body1',
            color: 'background.contrastText',
            style: { fontSize: '1rem', lineHeight: '1rem' },
          }}
          secondaryTypographyProps={{
            variant: 'caption',
            color: 'background.contrastText',
            style: { lineHeight: '.75rem', marginTop: '.2rem' },
          }}
          primary={name}
          secondary={subtitle}
          sx={{
            m: 0,
          }}
        />
      </ListItemButton>
      {children && (
        <DraggableList
          values={children}
          onChange={(children) => update({ children })}
          component='div'
          disablePadding
        >
          {children.map((item) => (
            <Fragment key={item.id}>
              <Divider variant='inset' component='li' />
              <SubItem {...item} />
            </Fragment>
          ))}
        </DraggableList>
      )}
    </>
  )
}

export default Item
