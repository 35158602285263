import { Stack } from '@mui/material'
import { useParams } from 'react-router-dom'

import SideNavigationButton from 'components/SideNavigationButton'

const SideNavigation = () => {
  const { id } = useParams()
  return (
    <div style={{ position: 'sticky', top: '2rem' }}>
      <Stack spacing={1}>
        <SideNavigationButton
          icon='envelopes'
          url={`/organisations/${id}/invitations`}
          label='Invitations'
        />
        <SideNavigationButton icon='users' url={`./users`} label='Users' />
        <SideNavigationButton
          icon='lock-keyhole'
          url={`./roles`}
          label='Roles'
        />
      </Stack>
    </div>
  )
}

export default SideNavigation
