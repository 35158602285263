import Grid from 'components/Grid'
import { useTenants } from 'state/useTenantsStore'
import CollectionHeader from 'components/CollectionHeader'
import Can from 'components/Can'

import Card from './Card'
import Invitations from './Invitations'

const Organisations = () => {
  const organisations = useTenants()

  return (
    <>
      <CollectionHeader title='Organisations' />
      <Can list='organisations'>
        <Grid>
          {organisations.map((organisation) => (
            <Card key={organisation.id} {...organisation} />
          ))}
        </Grid>
      </Can>
      <Invitations />
    </>
  )
}

export default Organisations
