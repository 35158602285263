import { create } from 'zustand'
import { mountStoreDevtool } from 'simple-zustand-devtools'
import { useParams } from 'react-router-dom'
import { collection } from 'utilities/routes'
import { getSession } from 'state/useSession'

const useCustomersStore = create((set) => {
  return {
    loading: true,
    customers: [],
    fetchCustomers: async () => {
      const {
        user: {
          app_metadata: { current_tenant },
        },
      } = getSession()

      // The extra `tenants!tenants_customer_id_fkey` is to get any linked tenants.
      // This is a workaround for the fact that we can't do a left join in Supabase.
      // We need this info to be able to filer out the company that is the legal entity for this tenant.
      const customers = await collection({
        path: 'customers',
        builder: (query) =>
          query
            .eq('tenant_id', current_tenant)
            .select('*, tenants!tenants_customer_id_fkey(id)')
            .order('name', { ascending: true }),
      })

      // And here we filter out the company that is the legal entity for this tenant.
      // Also removing the tenants array from the customer object before returning it.
      const filtered_customers = customers
        .filter((customer) => customer.tenants.length === 0)
        .map((customer) => {
          const { tenant, ...rest } = customer
          return rest
        })

      set({ customers: filtered_customers, loading: false })
    },
    addCustomer: (customer) =>
      set(({ customers }) => ({ customers: [...customers, customer] })),
    updateCustomer: (updated) =>
      set((state) => ({
        customers: state.customers.map((customer) =>
          customer.id === updated.id ? { ...customer, ...updated } : customer
        ),
      })),
    deleteCustomer: ({ id }) =>
      set((state) => ({
        customers: state.customers.filter((customer) => customer.id !== id),
      })),
  }
})

export const useLoading = () => useCustomersStore((state) => state.loading)
export const useCustomers = () => useCustomersStore((state) => state.customers)
export const useCustomer = (id) =>
  useCustomersStore((state) =>
    state.customers.find((customer) => customer.id === id)
  )
export const useCurrentCustomer = () => {
  const { id } = useParams()
  return (
    useCustomersStore((state) =>
      state.customers.find((customer) => customer.id === id)
    ) || {}
  )
}

export const addCustomer = useCustomersStore.getState().addCustomer
export const updateCustomer = useCustomersStore.getState().updateCustomer
export const deleteCustomer = useCustomersStore.getState().deleteCustomer
export const fetchCustomers = useCustomersStore.getState().fetchCustomers
export default useCustomersStore

if (process.env.NODE_ENV === 'development') {
  mountStoreDevtool('Customers', useCustomersStore)
}
