import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'

const ConfirmationDialog = ({ title, children, onClose, open, ...other }) => (
  <Dialog
    sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
    maxWidth='xs'
    open={open}
    {...other}
  >
    <DialogTitle>{title}</DialogTitle>
    <DialogContent dividers>{children}</DialogContent>
    <DialogActions>
      <Button autoFocus onClick={() => onClose(false)}>
        Cancel
      </Button>
      <Button onClick={() => onClose(true)}>Ok</Button>
    </DialogActions>
  </Dialog>
)

export default ConfirmationDialog
