import { useNavigate } from 'react-router-dom'
import { Avatar, Stack, Tooltip, Typography, IconButton } from '@mui/material'

import { Table, Row } from 'components/Table'
import Icon from 'components/Icon'
import Can from 'components/Can'

const User = ({ id, name, email, avatar_url }) => {
  const navigate = useNavigate()

  return (
    <Row alignItems='center'>
      <Avatar alt={name} src={avatar_url} />
      <Typography variant='body1'>{name}</Typography>
      <Stack direction='row' gap={1}>
        <Tooltip title='Email account holder'>
          <IconButton component='a' href={`mailto:${email}`}>
            <Icon name='envelope' />
          </IconButton>
        </Tooltip>
        <Can read='users'>
          <Tooltip title='Edit account details'>
            <IconButton
              onClick={() => navigate(`../users/${id}`, { relative: 'path' })}
            >
              <Icon name='user-pen' />
            </IconButton>
          </Tooltip>
        </Can>
      </Stack>
    </Row>
  )
}

const Users = ({ name, users = [] }) => (
  <Table columns='auto 1fr auto' style={{ margin: '1rem' }}>
    <Typography
      variant='h5'
      style={{ gridColumn: '1 / -1', marginBottom: '1rem' }}
    >
      Users with the {name} role
    </Typography>
    {users.map((user) => (
      <User key={user.id} {...user} />
    ))}
  </Table>
)

export default Users
