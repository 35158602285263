import 'ses'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { Experimental_CssVarsProvider as CssVarsProvider } from '@mui/material/styles'
import { CssBaseline } from '@mui/material'

import Application from './Application'
import theme from './theme'

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
  <React.StrictMode>
    <CssBaseline />

    <CssVarsProvider theme={theme}>
      <Application />
    </CssVarsProvider>
  </React.StrictMode>
)
