import { validate } from 'uuid'

import TableOfContent from 'components/TableOfContent'

const productToToc = (
  { id, name, icon, description, ...props },
  availableAddons = []
) => {
  // Get the IDs of the configured addons, maintaining the order
  const addonIds = Object.entries(props)
    .filter(([key, _]) => validate(key))
    .map(([id]) => id)

  // Find the matching full Product for each addon and create an entry for it
  const children = addonIds.map((addonId) => {
    const match = availableAddons.find(
      (availableAddon) => addonId === availableAddon.id
    )
    return productToToc({ ...match, ...match.configuration })
  })

  return {
    id,
    name,
    icon,
    subtitle: description,
    children,
  }
}

const SideNavigation = ({
  productConfigurations = [],
  availableProducts = [],
  update,
}) => {
  if (productConfigurations.length === 0 || availableProducts.length === 0)
    return

  return (
    <div style={{ position: 'sticky', top: 0 }}>
      <TableOfContent
        title='Product structure'
        items={productConfigurations
          .map((configuration) => {
            const product = availableProducts.find(
              (product) => product.id === configuration.id
            )

            if (!product) return null

            const addons = availableProducts.filter(
              (addon) => addon.parent === product.id
            )

            return productToToc(
              { ...product.configuration, ...configuration },
              addons
            )
          })
          .filter(Boolean)}
        //update={(items) => update(reorder(variables, items[0].children))}
      />
    </div>
  )
}

export default SideNavigation
