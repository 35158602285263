import { IconButton, Tooltip, styled, useColorScheme } from '@mui/material'

import Icon from 'components/Icon'

const Stack = styled('div')`
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
  &::before,
  &::after {
    content: '';
    width: 100%;
    height: 2px;
    background: ${({ mode }) => (mode === 'light' ? '#bbb' : '#555')};
    opacity: ${({ background }) => (background === 'light' ? 0.5 : 1)};
  }
`

const Button = styled(IconButton)`
  border: 1px solid ${({ mode }) => (mode === 'light' ? '#bbb' : '#444')};
  background: ${({ mode }) => (mode === 'light' ? '#ccc' : '#555')};
  transition: background-color 250ms;
  opacity: ${({ background }) => (background === 'light' ? 0.5 : 1)};
  aspect-ratio: 1;
  &:hover {
    background: ${({ mode }) => (mode === 'light' ? '#bbb' : '#444')};
  }
`

const LineAdd = ({
  tooltip = 'Add',
  background = 'light',
  onClick,
  ...props
}) => {
  const { mode } = useColorScheme()
  return (
    <Stack
      mode={mode}
      background={background}
      direction='row'
      justifyContent='center'
      {...props}
    >
      <Tooltip title={tooltip} placement='top' arrow>
        <Button mode={mode} background={background} onClick={onClick}>
          <Icon name='add' />
        </Button>
      </Tooltip>
    </Stack>
  )
}

export default LineAdd
