import _ from 'lodash'

import Variable from './Variable'

const runCalculation = (state, { variable_name, attributes: { code } }) => {
  const compartment = new window.Compartment({
    ...state,
    log: () => {},
  })
  state[variable_name] = compartment.evaluate(code)
  return state
}

const runCalculations = (calculations, state) => {
  return calculations.reduce(runCalculation, state)
}

const Form = ({
  product: {
    configuration: { variables },
  },
  values,
  update,
}) => {
  const { input = [], calculation = [] } = _.groupBy(variables, (v) => v.type)

  const handleUpdate = (change) => {
    update(runCalculations(calculation, { ...values, ...change }))
  }

  return input.map((variable) => (
    <Variable
      key={variable.variable_name}
      {...variable}
      state={values}
      update={handleUpdate}
    />
  ))
}

export default Form
