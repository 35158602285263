import React, { useEffect, useState } from 'react'
import { IconButton, Checkbox, styled } from '@mui/material'

import Icon from 'components/Icon'
import { Row, Section } from 'components/Table'

const Label = styled('span')`
  .sub-permission & {
    position: relative;
    margin-left: ${({ level }) => level * 2}rem;
  }

  .sub-permission &::before {
    content: ' ';
    position: absolute;
    left: -1rem;
    width: 0.5rem;
    border: 1px solid #7d7d7d;
    border-top: none;
    border-right: none;
  }

  *:not(.sub-permission) + .sub-permission &::before {
    height: 1.5rem;
    top: -0.7rem;
  }

  .sub-permission + .sub-permission &::before {
    height: 2.6rem;
    top: -1.85rem;
  }
`

const available_permissions = ['read', 'create', 'update', 'delete', 'own']
const default_parent_permissions = {
  read: false,
  create: false,
  update: false,
  delete: false,
  own: false,
}

const comma_separated_string_to_object = (permission_string) => {
  const permission_strings = permission_string.split(',')
  let defaults = {}

  available_permissions.forEach(
    (key) =>
      (defaults[key] = permission_strings.includes(key) ? true : undefined)
  )

  return defaults
}

const object_to_array = (permissions) => {
  return Object.entries(permissions)
    .map(([key, value]) => (value ? key : null))
    .filter(Boolean)
}

const Resource = ({
  label,
  name,
  permission_string = '',
  level = 0,
  parent_permissions = default_parent_permissions,
  mark_state,
  set_mark_state,
  sub_resources = [],
}) => {
  const [permissions, set_permissions] = useState({})
  const persisted_permissions =
    comma_separated_string_to_object(permission_string)
  const all_possible_checked =
    object_to_array(permissions).length ===
    (level === 0
      ? available_permissions.length
      : object_to_array(parent_permissions).length)

  const value = (key) => {
    if (persisted_permissions[key]) return true
    if (parent_permissions[key] !== true) return undefined
    return permissions[key]
  }

  useEffect(() => {
    const res = available_permissions.reduce(
      (result, key) => ({ ...result, [key]: value(key) }),
      {}
    )

    set_permissions(res)
  }, [JSON.stringify(parent_permissions)])

  const indeterminate = (key) =>
    parent_permissions[key] === true && permissions[key] === undefined

  const disabled = (key) => level > 0 && parent_permissions[key] !== true

  const handleChange = (key) => {
    set_mark_state(permissions[key] === false ? true : false)
    set_permissions((permissions) => ({
      ...permissions,
      [key]: indeterminate(key) ? false : !permissions[key],
    }))
  }

  const handle_drag = (key) => {
    if (disabled(key)) return

    set_permissions((permissions) => ({
      ...permissions,
      [key]: mark_state,
    }))
  }

  const set_all = (value) => {
    set_permissions({
      ...available_permissions.reduce(
        (result, key) => ({
          ...result,
          [key]: disabled(key) ? undefined : value,
        }),
        {}
      ),
    })
  }

  return (
    <>
      <Row align='center' className={level > 0 ? 'sub-permission' : ''}>
        <Label level={level}>{label}</Label>

        <Section from='2' to='7' style={{ justifyItems: 'center' }}>
          {available_permissions.map((key) => (
            <Checkbox
              key={key}
              name={`permissions.${name}.${key}`}
              indeterminate={indeterminate(key)}
              checked={indeterminate(key) || permissions[key] === true}
              onMouseDown={() => handleChange(key)}
              onMouseEnter={({ buttons }) => {
                if (buttons !== 0) handle_drag(key)
              }}
              disabled={disabled(key)}
            />
          ))}
        </Section>

        <div style={{ alignSelf: 'center', justifySelf: 'center' }}>
          {all_possible_checked ? (
            <IconButton
              size='large'
              color='secondary'
              onClick={() => set_all(false)}
            >
              <Icon baseClassName='fa-regular' name='square' />
            </IconButton>
          ) : (
            <IconButton
              size='large'
              color='secondary'
              onClick={() => set_all(true)}
            >
              <Icon name={'square-check'} />
            </IconButton>
          )}
        </div>
      </Row>
      {sub_resources.map((permission) => (
        <Resource
          key={permission.name}
          {...permission}
          level={level + 1}
          parent_permissions={permissions}
          name={`${name}.${permission.name}`}
        />
      ))}
    </>
  )
}

export default Resource
