import { TextField } from '@mui/material'
import { forwardRef } from 'react'

const Field = forwardRef(
  (
    { update = () => {}, InputProps = {}, InputLabelProps = {}, ...props },
    ref
  ) => (
    <TextField
      key={props.defaultValue || props.name}
      inputRef={ref}
      fullWidth
      margin='dense'
      size='small'
      variant='filled'
      onChange={({ target: { value } }) => update(value)}
      {...props}
      InputProps={{
        readOnly: props.readOnly,
        ...InputProps,
      }}
      InputLabelProps={{
        shrink: true,
        ...InputLabelProps,
      }}
    />
  )
)

Field.displayName = 'Field'

export default Field
