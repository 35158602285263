import { Fab as MuiFab } from '@mui/material'
import { styled } from '@mui/material/styles'

const Fab = styled(MuiFab)`
  position: fixed;
  bottom: 7em;
  right: 2em;
`

const PrimaryAction = ({ children, ...props }) => (
  <Fab color='secondary' {...props}>
    {children}
  </Fab>
)

export default PrimaryAction
