import { FormControlLabel, Switch } from '@mui/material'

import Field from 'components/Field'

const TextSpecificFields = ({ type, prefix, suffix, example_data, update }) => {
  if (type !== 'text' && type !== 'timestamp') return null

  return (
    <>
      <FormControlLabel
        style={{ justifyContent: 'space-between', margin: 0 }}
        control={
          <Switch
            checked={type === 'timestamp'}
            onChange={(_event, value) =>
              update({
                type: value ? 'timestamp' : 'text',
                example_data:
                  example_data === '' && value ? new Date().toISOString() : '',
              })
            }
          />
        }
        label='Format as timestamp'
        labelPlacement='start'
      />
      <Field
        label='Prefix'
        value={prefix}
        onChange={({ target: { value } }) => update({ prefix: value })}
        name='prefix'
      />
      <Field
        label='Suffix'
        value={suffix}
        onChange={({ target: { value } }) => update({ suffix: value })}
        name='suffix'
      />
    </>
  )
}

export default TextSpecificFields
