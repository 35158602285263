import Field from 'components/Field'

export const Number = ({ min = '', max = '', step = '', onChange }) => {
  return (
    <>
      <Field
        label='Minimum value'
        value={min}
        helperText='Defaults to zero'
        update={(value) => onChange({ min: value })}
      />
      <Field
        label='Maximum value'
        value={max}
        helperText='Leave empty for no limit'
        update={(value) => onChange({ max: value })}
      />
      <Field
        label='Step size'
        value={step}
        helperText='Defaults to 1'
        update={(value) => onChange({ step: value })}
      />
    </>
  )
}
