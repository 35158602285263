import { Stack } from '@mui/material'
import { motion } from 'framer-motion'
import { useState, ErrorBoundary } from 'react'
import { useLocation, Outlet } from 'react-router-dom'

import useShouldAnimate from 'hooks/useShouldAnimate'
import Column from 'components/Column'
import Columns from 'components/Columns'

import Contacts from './Contacts'
import General from './General'
import SideNavigation from './SideNavigation'
import { useCurrentCustomer } from 'state/useCustomersStore'

const Customer = () => {
  const location = useLocation()
  const event = useCurrentCustomer()
  const shouldAnimate = useShouldAnimate(`/customers/${event.id}`)
  const [general_expanded, set_general_expanded] = useState(true)
  const [contacts_expanded, set_contacts_expanded] = useState(false)
  const sections = [
    {
      id: 'company-info',
      name: 'Company info',
      icon: 'square-info',
      subtitle: 'Legal information about the company',
      action: () => set_general_expanded(true),
    },
    {
      id: 'contacts',
      name: 'Contacts',
      icon: 'address-book',
      subtitle: 'Owners, technical contacts and other stakeholders',
      action: () => set_contacts_expanded(true),
    },
  ]

  return (
    <motion.div
      key={location.pathname}
      initial={shouldAnimate ? { x: '-50%', opacity: 0.5 } : false}
      animate={{ x: '0%', opacity: 1 }}
      exit={{ x: '-50%', opacity: 0.5 }}
    >
      <Columns>
        <Column>
          <SideNavigation items={sections} />
        </Column>
        <Column>
          <Stack spacing={5}>
            <General
              expanded={general_expanded}
              set_expanded={set_general_expanded}
            />
            <Contacts
              expanded={contacts_expanded}
              set_expanded={set_contacts_expanded}
            />
          </Stack>
        </Column>
      </Columns>
      <Outlet />
    </motion.div>
  )
}

export default Customer
