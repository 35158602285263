import { Chip, Stack, Typography, styled } from '@mui/material'

const Feature = styled(Chip)`
  background-color: var(--mui-palette-LinearProgress-errorBg);
  padding: 0.5rem 1rem;
  color: var(--mui-palette-error-dark);
  border: 1px solid currentColor;
  font-family: 'Source Code Pro', monospace;

  & > span {
    display: none;
  }

  &:after {
    content: '${({ label }) => label}';
  }

  @supports (${({ rule }) => rule}) {
    background-color: var(--mui-palette-LinearProgress-successBg);
    color: var(--mui-palette-success-dark);
  }
`

/**
 * There isn't a direct test for this using the `@supports` rule. The test
 * that does exist is for a different feature that is assumed to only be
 * supported if `@supports` is supported.
 * This tests the `@property` rule directly.
 */
const AtProperty = styled(Feature)`
  @property --color {
    syntax: '<color>';
    initial-value: #afcdb1;
    inherits: false;
  }

  background-color: var(--color);
  color: var(--mui-palette-success-dark);
`

const FeatureTests = () => (
  <div>
    <Typography variant='h5' component='h3' gutterBottom>
      Feature Tests
    </Typography>
    <Stack direction='row' spacing={2}>
      <Feature rule='display: grid' label='display grid' />
      <Feature rule='--custom-properties: #fff' label='--custom-properties' />
      <Feature rule='grid-template-rows: subgrid' label='subgrid' />
      <Feature rule='color: color-mix(in srgb, #000, #fff)' label='color-mix' />
      <Feature rule='selector(&)' label='CSS nesting' />
      <AtProperty label='@property' />
    </Stack>
  </div>
)

export default FeatureTests
