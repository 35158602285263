import { InputAdornment, Stack, Typography } from '@mui/material'
import { useHotkeys } from 'react-hotkeys-hook'
import { useSearchParams } from 'react-router-dom'

import Field from 'components/Field'
import Icon from 'components/Icon'

const CollectionHeader = ({ title, children, fields, show_search = true }) => {
  let [searchParams, setSearchParams] = useSearchParams()
  const search = searchParams.get('search')
  const ref = useHotkeys(
    'esc',
    (event) => {
      event.preventDefault()
      event.stopPropagation()
      setSearchParams({})
    },
    { enableOnFormTags: true }
  )

  return (
    <Stack
      direction='row'
      spacing={4}
      mb={10}
      justifyContent='space-between'
      alignItems='end'
      style={{ minHeight: '40px' }}
    >
      <Typography
        variant='h4'
        style={{
          whiteSpace: 'nowrap',
          lineHeight: '1.5rem',
          color:
            'color-mix(in srgb, var(--mui-palette-primary-dark) 80%, transparent)',
        }}
      >
        {title}
      </Typography>
      {children}
      {show_search && (
        <Field
          ref={ref}
          autoFocus
          placeholder={`Filter items ${fields ? `by ${fields}` : ''}`}
          value={search || ''}
          update={(value) => {
            setSearchParams({ search: value })
          }}
          variant='outlined'
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <Icon name='search' />
              </InputAdornment>
            ),
          }}
          style={{ maxWidth: '33%' }}
        />
      )}
    </Stack>
  )
}

export default CollectionHeader
