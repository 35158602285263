import { Button as MUIButton, styled } from '@mui/material'

import { Section } from 'components/Table'

const Span = styled('span')`
  white-space: nowrap;
  justify-self: start;
`

const Button = styled(MUIButton)`
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  max-width: 100%;
  width: fit-content;
  text-transform: ${({ transform }) => (transform ? transform : 'initial')};
  justify-self: ${({ justify }) => (justify ? justify : 'center')};
`

const FrontRow = ({
  organisation_id,
  inviter_id,
  inviter_name,
  invitee_email,
  timestamp,
}) => {
  const inviter_text = inviter_name || 'An unknown user'
  const inviter_href = inviter_id
    ? `/organisations/${organisation_id}/users/${inviter_id}`
    : '#'

  return (
    <Section from='1' to='5' style={{ padding: '.5rem 1rem' }}>
      <Button
        justify='end'
        variant='outlined'
        component='a'
        href={inviter_href}
      >
        {inviter_text}
      </Button>
      <Span>invited</Span>
      <Button
        justify='start'
        transform='lowercase'
        variant='outlined'
        component='a'
        href={`mailto:${invitee_email}`}
      >
        {invitee_email}
      </Button>
      <Span>{timestamp}</Span>
    </Section>
  )
}

export default FrontRow
