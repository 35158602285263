import React from 'react'

import TableOfContent from 'components/TableOfContent'

const SideNavigation = ({ sections }) => (
  <div style={{ position: 'sticky', top: '2rem' }}>
    <TableOfContent title='Sections' items={Object.values(sections)} />
  </div>
)

export default SideNavigation
