import { useState } from 'react'
import {
  Button,
  CardActions,
  CardContent,
  CardHeader,
  IconButton,
  Modal,
  Card as MuiCard,
  Stack,
  styled,
  Typography,
} from '@mui/material'
import { v4 as uuid } from 'uuid'
import { Form, useNavigate } from 'react-router-dom'

import Icon from 'components/Icon'
import LineAdd from 'components/LineAdd'

import MainForm from './MainForm'
import AttributeForm from './AttributeForm'

const Card = styled(MuiCard)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80vw;
  max-height: 100vh;
  background-color: #fff;
  box-shadow: 24;
`

const Main = ({ title: modal_title = 'Add contact', contact }) => {
  const navigate = useNavigate()

  const [attributes, update_attributes] = useState(contact?.attributes || [])

  return (
    <Modal
      open
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <Form action='' method='POST'>
        <Card>
          <CardHeader
            title={modal_title}
            subheader='Edit details for contact'
            action={
              <IconButton aria-label='close' onClick={() => navigate(-1)}>
                <Icon name='xmark' />
              </IconButton>
            }
          />
          <CardContent>
            <MainForm contact={contact} />
          </CardContent>
          <CardContent
            sx={{
              'maxHeight': 'calc(100vh - 22rem)',
              'overflowY': 'auto',
              'background': 'var(--mui-palette-background-default)',
              'scrollbarWidth': 'thin',
              'scrollbarColor': 'var(--mui-palette-secondary-dark) transparent',
              '&::-webkit-scrollbar': {
                backgroundColor: 'transparent',
                width: '.5rem',
                borderRight: '4px solid var(--mui-palette-secondary-dark)',
              },
              '&::-webkit-scrollbar-thumb': {
                background: 'var(--mui-palette-secondary-dark)',
                borderTopLeftRadius: '.5rem',
                borderBottomLeftRadius: '.5rem',
              },
            }}
          >
            <Stack spacing={0}>
              <Typography variant='h6'>Contact methods</Typography>
              <Typography variant='caption'>
                Add any and all ways to contact this person.
              </Typography>
            </Stack>
            {attributes.map((attribute, index) => (
              <AttributeForm
                key={attribute.name + attribute.value + attribute.type}
                {...attribute}
                index={index}
                remove={() =>
                  update_attributes([
                    ...attributes.slice(0, index),
                    ...attributes.slice(index + 1),
                  ])
                }
              />
            ))}
            <LineAdd
              background='dark'
              tooltip='Add contact method'
              onClick={() =>
                update_attributes([
                  ...attributes,
                  {
                    id: uuid(),
                  },
                ])
              }
            />
          </CardContent>
          <CardActions sx={{ justifyContent: 'end' }}>
            <Button
              type='submit'
              name='section'
              value='update_contact'
              variant='outlined'
              color='secondary'
              size='small'
            >
              Save contact
            </Button>
          </CardActions>
        </Card>
      </Form>
    </Modal>
  )
}

export default Main
