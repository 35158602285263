import { Fragment, useRef, useState, useCallback } from 'react'
import { styled } from '@mui/material'

import { Table as BaseTable, Section, Row } from 'components/Table'

import Field from './Field'
import DropTarget from './DropTarget'
import DropIndicator from './DropIndicator'

const DropTable = styled(BaseTable)`
  max-width: 100%;
  padding: 2rem 1rem;
  margin-bottom: 2rem;
`

function valid_css_length(value) {
  const el = document.createElement('div')
  el.style.width = value
  return el.style.width !== ''
}

const Table = ({
  fields = [],
  attributes = [],
  update_field,
  open_settings,
  className,
  set_mouse_over,
}) => {
  const table_row = useRef(null)

  const columns = useCallback(() => {
    const columns = attributes.reduce(
      (columns, attribute) => {
        const width = fields?.[attribute]?.width
        const last_good_width = fields?.[attribute]?.last_good_width || '1fr'
        const valid = valid_css_length(width)
        const column = valid ? width : last_good_width
        if (valid && width !== last_good_width) {
          update_field(attribute, { last_good_width: width })
        }
        columns.push(column, 'auto')

        return columns
      },
      ['auto']
    )

    return columns.join(' ')
  }, [fields, attributes])

  return (
    <DropTable
      className={className}
      columns={columns()}
      gap='0'
      onMouseEnter={() => {
        set_mouse_over(true)
      }}
      onMouseLeave={() => {
        set_mouse_over(false)
      }}
    >
      {/* Empty first row to force the editor row to have the odd background color so it shows up better. */}
      <Row />
      <Row
        ref={table_row}
        style={{
          '--width': table_row.current?.getBoundingClientRect()?.width + 'px',
        }}
      >
        <DropTarget
          className={attributes.length > 0 ? 'first' : ''}
          key={attributes.join('-') + '-index-0'}
          id='index-0'
        >
          <DropIndicator used_attribute_count={attributes.length} />
        </DropTarget>
        {attributes.map((attribute, index) => (
          <Fragment key={attribute}>
            <Field
              attribute={attribute}
              open_settings={open_settings(attribute)}
              {...fields?.[attribute]}
            />
            <DropTarget
              className={index === attributes.length - 1 ? 'last' : ''}
              key={attributes.join('-') + `-index-${index + 1}`}
              id={`index-${index + 1}`}
            >
              <DropIndicator used_attribute_count={attributes.length} />
            </DropTarget>
          </Fragment>
        ))}
      </Row>
    </DropTable>
  )
}

export default Table
