export const eventTypes = [
  {
    value: 'note',
    icon: 'notebook',
    text: 'Note',
  },
  {
    value: 'phone',
    icon: 'phone-office',
    text: 'Phone call',
  },
  {
    value: 'email',
    icon: 'at',
    text: 'E-mail',
  },
  {
    value: 'mail',
    icon: 'envelope',
    text: 'Snail mail',
  },
  {
    value: 'inperson',
    icon: 'comments',
    text: 'In-person',
  },
  {
    value: 'videocall',
    icon: 'camera-web',
    text: 'Video call',
  },
]
