import Product from 'pages/Product/Document'

import settings from './settings'

const route = {
  path: ':id',
  element: <Product />,
}

export default route
