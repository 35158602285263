import { useCurrentAccount } from 'state/useAccountsStore'

const defaults = {
  display_time_as: 'relative',
}

const useSettings = () => {
  const { settings } = useCurrentAccount()

  return { ...defaults, ...settings }
}

export default useSettings
