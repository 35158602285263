import { useState } from 'react'

import Field from 'components/Field'
import useSupabase from 'hooks/useSupabase'
import { appendError, appendSuccess } from 'state/useSnackbarStore'

const UploadImage = ({ path, onUpload }) => {
  const [upload_enabled, set_upload_enabled] = useState(true)
  const supabase = useSupabase()

  const uploadFile = async (file) => {
    set_upload_enabled(false)
    const full_path = `${path}/${file.name}`

    const { error } = await supabase.storage
      .from('images')
      .upload(full_path, file, {
        cacheControl: '3600',
        upsert: true,
      })

    if (error) appendError('Image upload failed')

    const { data } = await supabase.storage
      .from('images')
      .getPublicUrl(full_path)

    set_upload_enabled(true)
    appendSuccess('Upload complete')
    onUpload(data.publicUrl)
  }

  return (
    <>
      <Field
        label='Upload file'
        helperText='Select an image file from you computer to upload'
        type='file'
        disabled={!upload_enabled}
        inputProps={{
          accept: 'image/*',
          style: { opacity: 0, cursor: 'pointer' },
        }}
        onChange={({
          target: {
            files: [file],
          },
        }) => uploadFile(file)}
      />
    </>
  )
}

export default UploadImage
