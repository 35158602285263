import get_nested_value from './get_nested_value'

const search = (collection, search_term, ...fields) => {
  if (!search_term) return collection

  return collection.filter((item) => {
    return fields.some((field) => {
      const value = get_nested_value(item, field)
      console.log('search', value, search_term)
      return value.toLowerCase().includes(search_term.toLowerCase())
    })
  })
}

export default search
