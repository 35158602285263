import { useEffect, useState } from 'react'
import { Alert, Box, Button, Grid, Paper, Typography } from '@mui/material'

import useSupabase from 'hooks/useSupabase'
import Footer from 'components/Footer'

import InvalidInvitationModal from './InvalidInvitationModal'

export default function SignInSide() {
  const supabase = useSupabase()
  const [error, setError] = useState(false)

  // ?error=server_error&error_description=Error+creating+identity
  useEffect(() => {
    const query = window.location.search
      .slice(1)
      .split('&')
      .reduce((map, pair) => {
        const [name, value] = pair.split('=')
        map[name] = value
        return map
      }, {})

    if (query.error) setError(true)
  }, [])

  return (
    <>
      <Grid container component='main' sx={{ height: '100vh' }}>
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: 'url(/images/splash.webp)',
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) =>
              t.palette.mode === 'light'
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
        <Grid
          item
          xs={12}
          sm={8}
          md={5}
          component={Paper}
          elevation={6}
          square
          style={{ position: 'relative' }}
        >
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              height: 'calc(100% - 128px)',
            }}
          >
            <img src='/android-icon-192x192.png' alt='' />
            <Typography variant='h1' mb={2}>
              amain
            </Typography>
            <div>
              <Typography variant='body2'>British English</Typography>
              <Typography variant='body1'>
                <i>(əˈmeɪn) adverb. archaic or poetic</i>
                <br />
                1. <b>with great strength, speed, or haste</b>
                <br />
                2. <b>to lead, conduct, manage</b>
              </Typography>
              <Typography variant='body2'>Norman</Typography>
              <Typography variant='body1'>
                <i>
                  adjective. borrowed from Old Norse “almanna“ (
                  <b>“for everyone”</b>)
                </i>
                <br />
                1. <b>of easy use</b>
              </Typography>
            </div>
            {error && (
              <Alert severity='error' sx={{ mt: 6 }}>
                The server responded with an error. The most probable reason is
                that you tried to log in with an account that does not belong to
                a reqistered domain.
              </Alert>
            )}
            <Box sx={{ mt: error ? 2 : 10 }}>
              <Button
                type='submit'
                fullWidth
                variant='contained'
                onClick={() =>
                  supabase.auth.signInWithOAuth({
                    provider: 'google',
                  })
                }
                sx={{ mt: 3, mb: 2 }}
              >
                <img src='/images/google.svg' alt='Google G logo' />
                Sign In With Google
              </Button>
              <Typography>
                Sign in with your domain account for your organisations
                registered domain.
              </Typography>
              <Footer sx={{ position: 'absolute' }} />
            </Box>
          </Box>
        </Grid>
      </Grid>
      <InvalidInvitationModal />
    </>
  )
}
