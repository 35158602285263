import { Children } from 'react'
import { Outlet } from 'react-router-dom'
import { Stack } from '@mui/material'

import Columns from 'components/Columns'
import Column from 'components/Column'

export const Navigation = ({ children }) => children

export const Sections = ({ children }) => children

const Document = ({ children }) => {
  let navigation, sections

  Children.forEach(children, (child) => {
    if (child.type === Navigation) {
      navigation = child
    } else if (child.type === Sections) {
      sections = child
    }
  })

  return (
    <>
      <Columns>
        <Column>{navigation}</Column>
        <Column>
          <Stack spacing={5}>{sections}</Stack>
        </Column>
      </Columns>
      <Outlet />
    </>
  )
}

export default Document
