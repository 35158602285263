import { Link, useNavigate } from 'react-router-dom'
import {
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Typography,
} from '@mui/material'

import Icon from 'components/Icon'

const Product = ({
  id,
  configuration: { name, description, icon, variables },
  addons = [],
}) => {
  const navigate = useNavigate()

  return (
    <Card
      onClick={() => {
        navigate(`/products/${id}`)
      }}
      style={{ cursor: 'pointer' }}
    >
      <CardHeader
        title={<Typography variant='h5'>{name}</Typography>}
        subheader={description}
        avatar={<Icon name={icon} sx={{ fontSize: '2.5rem' }} />}
        action={
          <IconButton aria-label='close'>
            <Icon name='delete' />
          </IconButton>
        }
      />
      <CardContent>
        <ul>
          {variables.map((variable) => (
            <li key={variable.id}>{variable.name}</li>
          ))}
          <hr />
          {addons.map((addon) => (
            <li key={addon.id}>
              <Link
                to={`/products/${addon.id}`}
                onClick={(event) => event.stopPropagation()}
              >
                {addon.configuration.name}
              </Link>
            </li>
          ))}
        </ul>
      </CardContent>
    </Card>
  )
}

export default Product
