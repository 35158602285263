import {
  Container,
  IconButton,
  ImageListItemBar,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  ImageList as MuiImageList,
  ImageListItem as MuiImageListItem,
  Popover,
  Typography,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import { useEffect, useRef, useState } from 'react'

import Icon from 'components/Icon'
import useSupabase from 'hooks/useSupabase'

const ImageActions = () => {
  const [open, setOpen] = useState(false)
  const ref = useRef()
  return (
    <>
      <IconButton
        ref={ref}
        sx={{ color: '#fff' }}
        onClick={() => setOpen(true)}
      >
        <Icon name='ellipsis-vertical' />
      </IconButton>
      <Popover
        anchorEl={ref.current}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={open}
        onClose={() => setOpen()}
      >
        <MenuList>
          <IconButton style={{ marginLeft: '.3em' }} onClick={() => setOpen()}>
            <Icon fontSize='small' name='circle-xmark' />
          </IconButton>
          <MenuItem>
            <ListItemIcon>
              <Icon fontSize='small' name='trash' />
            </ListItemIcon>
            <ListItemText>Delete</ListItemText>
          </MenuItem>
          <MenuItem>
            <ListItemIcon>
              <Icon fontSize='small' name='download' />
            </ListItemIcon>
            <ListItemText>Download</ListItemText>
          </MenuItem>
        </MenuList>
      </Popover>
    </>
  )
}

const ImageListItem = styled(MuiImageListItem)`
  border-radius: 4px;
  border: 1px solid #ccc;
  overflow: hidden;
`

const ImageList = ({ path, onSelect }) => {
  const [images, setImages] = useState([])
  const supabase = useSupabase()

  const loading = useRef(true)

  useEffect(() => {
    if (!loading.current) {
      return
    }

    loading.current = false
    ;(async () => {
      const { data, error } = await supabase.storage.from('images').list(path)

      if (error) {
        console.log(error)
        return
      }

      setImages(data)
    })()
  }, [])

  const empty = !loading.current && images.length === 0

  return (
    <Container
      style={{
        backgroundColor: '#ddd',
        padding: '0',
      }}
    >
      <MuiImageList
        variant='masonry'
        cols={3}
        gap={8}
        style={{ margin: '0', padding: '2em' }}
      >
        {loading.current && (
          <Typography variant='body2'>Loading ...</Typography>
        )}
        {empty && (
          <Typography variant='body2'>
            Upload images to have them show here.
          </Typography>
        )}
        {images.map((item) => {
          const url = `https://gkgeuxqzyyypmsitvxmn.supabase.co/storage/v1/object/public/images/${path}/${item.name}`
          return (
            <ImageListItem key={item.id}>
              <ImageListItemBar
                title={item.name}
                subtitle={item.metadata.mimetype}
                actionIcon={<ImageActions image={item} />}
                actionPosition='right'
              />
              <img
                src={url}
                srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                alt={item.title}
                loading='lazy'
                onClick={() => onSelect(url)}
              />
            </ImageListItem>
          )
        })}
      </MuiImageList>
    </Container>
  )
}

export default ImageList
