import { diff_match_patch } from 'diff-match-patch'

export const diff = (text1, text2) => {
  const dmp = new diff_match_patch()
  dmp.Diff_Timeout = 1.0

  let raw_diff = dmp.diff_main(text1, text2)
  dmp.diff_cleanupSemantic(raw_diff)

  let html = dmp.diff_prettyHtml(raw_diff)
  html = html.replace(/style="background:#e6ffe6;"/g, 'class="added"')
  html = html.replace(/style="background:#ffe6e6;"/g, 'class="removed"')

  return html
}
