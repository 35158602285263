import { useNavigate } from 'react-router-dom'

import { useSession } from 'state/useSession'

const is_permitted = (permissions, resource, action) => {
  const action_on_resource = permissions?.[resource]?.[action]
  const manage_resource = permissions?.[resource]?.manage
  const action_globally = permissions?.all?.[action]
  const manage_globally = permissions?.all?.manage

  return (
    action_on_resource || manage_resource || action_globally || manage_globally
  )
}

function Can({ children, to, component, tenant_id, ...props }) {
  const navigate = useNavigate()
  const {
    user: {
      app_metadata: { current_tenant, tenants },
    },
  } = useSession()
  const permissions = tenants[tenant_id || current_tenant] || {}

  // Extract the action and resource from the props
  const [action] = Object.keys(props).filter((key) =>
    ['list', 'read', 'update', 'create', 'delete', 'manage'].includes(key)
  )
  const resource = props[action]

  // Check if the user has the required permission
  if (is_permitted(permissions, resource, action)) return children
  if (to) {
    navigate(to)
    return null
  }
  return component
}

export default Can
