import { useNavigate, Link } from 'react-router-dom'
import {
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
} from '@mui/material'
import { useColorScheme } from '@mui/material/styles'

import Icon from 'components/Icon'
import useSupabase from 'hooks/useSupabase'
import { useSession } from 'state/useSession'
import Can from 'components/Can'

const Item = ({ text, icon, onClick, to }) => (
  <MenuItem onClick={onClick} dense component={Link} to={to}>
    <ListItemIcon>
      <Icon name={icon} sx={{ color: 'quinary.light' }} fontSize='small' />
    </ListItemIcon>
    <ListItemText>{text}</ListItemText>
  </MenuItem>
)

const UserMenu = ({ anchorEl, open, onClose }) => {
  const { mode, setMode } = useColorScheme()
  const supabase = useSupabase()
  const navigate = useNavigate()
  const {
    user: { id: user_id },
  } = useSession()

  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      onClick={onClose}
      PaperProps={{
        elevation: 0,
        sx: {
          'bgcolor': 'secondary.main',
          'color': '#fff',
          'minWidth': '200px',
          'overflow': 'visible',
          'filter': 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
          'mt': 1.5,
          '& .MuiAvatar-root': {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
          '&:before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: 'secondary.main',
            transform: 'translateY(-50%) rotate(45deg)',
            zIndex: 0,
          },
        },
      }}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
    >
      <MenuList>
        <Can read='organisations'>
          <Item
            icon='users-gear'
            text='Organizations and teams'
            to='/organisations'
          />
        </Can>
        <Can own='accounts'>
          <Item
            icon='user-gear'
            text='Account settings'
            to={`/users/${user_id}`}
          />
        </Can>
        <Can read='billing'>
          <Item icon='credit-card' text='Billing and plan' />
        </Can>
        <Item
          icon='right-from-bracket'
          text='Logout'
          onClick={() => {
            supabase.auth.signOut()
            navigate('/')
          }}
        />
        <Item
          text={
            mode === 'light' ? 'Switch to dark theme' : 'Switch to light theme'
          }
          icon={mode === 'light' ? 'lightbulb-slash' : 'lightbulb-on'}
          onClick={() => {
            setMode(mode === 'light' ? 'dark' : 'light')
          }}
        />
      </MenuList>
    </Menu>
  )
}

export default UserMenu
