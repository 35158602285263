import { Divider, IconButton, List, Stack, Typography } from '@mui/material'

import Icon from 'components/Icon'

import Drawer from './Drawer'
import Toolbar from './Toolbar'
import StaticMenuItems from './StaticMenuItems'
import DynamicMenuItems from './DynamicMenuItems'
import RecentCustomers from './RecentCustomers'

const SideMenu = ({ open, toggleSideMenu }) => (
  <Drawer variant='permanent' open={open} sideMenuWidth={240}>
    <Toolbar open={open}>
      <IconButton onClick={toggleSideMenu}>
        <Icon sx={{ fontSize: 20 }} name={'arrow-right-to-line'} />
      </IconButton>
    </Toolbar>
    <Stack direction='row' alignItems='center'>
      <img
        style={{ padding: 'calc((54px - 36px) / 2)' }}
        src='/android-icon-36x36.png'
      />
      <Typography component='h1' variant='h6' color='inherit' noWrap>
        amain.app
      </Typography>
      <IconButton sx={{ marginLeft: 'auto' }} onClick={toggleSideMenu}>
        <Icon sx={{ fontSize: 20 }} name={'arrow-left-from-line'} />
      </IconButton>
    </Stack>
    <Divider />
    <List component='nav'>
      <StaticMenuItems />
      <DynamicMenuItems />
      <RecentCustomers />
    </List>
  </Drawer>
)

export default SideMenu
