import { Box, Container, IconButton } from '@mui/material'
import { AnimatePresence } from 'framer-motion'
import { Suspense, useEffect, useState } from 'react'
import { Await, Outlet, useLocation } from 'react-router-dom'

import Icon from 'components/Icon'
import { LoadingError, LoadingProgress } from 'components/LoadingModals'
import SideMenu from 'components/SideMenu'
import UserMenu from 'components/UserMenus'
import { usePushCustomerHistory } from 'state/useCustomerHistoryStore'
import { usePush as usePushLocation } from 'state/useLocationHistoryStore'
import preload from 'utilities/preload'

import NavLink from './components/SideMenu/NavLink'

const admin_pages = ['/organisations']

const DashboardContent = () => {
  const [open, setOpen] = useState(false)
  const push_customer_history = usePushCustomerHistory()
  const location = useLocation()
  const toggleSideMenu = () => {
    setOpen(!open)
  }
  const push_location = usePushLocation()

  useEffect(() => {
    push_location(location.pathname)
    push_customer_history()
  }, [location, push_location])

  const admin_page = admin_pages.some((prefix) =>
    location.pathname.startsWith(prefix)
  )

  return (
    <Suspense fallback={<LoadingProgress />}>
      <Await resolve={preload} errorElement={<LoadingError />}>
        <Box sx={{ display: 'flex' }}>
          <SideMenu open={open} toggleSideMenu={toggleSideMenu} />
          <Box
            component='main'
            id='content'
            sx={{
              position: 'relative',
              backgroundColor: admin_page
                ? 'var(--mui-palette-secondary-light)'
                : 'background.default',
              flexGrow: 1,
              height: '100vh',
              overflow: 'auto',
              scrollbarGutter: 'stable',
              transition: 'background-color 0.2s ease-in-out',
            }}
          >
            <UserMenu />
            <Container maxWidth='xl' sx={{ pb: 5 }}>
              <AnimatePresence mode='wait' location={location}>
                <Outlet />
              </AnimatePresence>
            </Container>
          </Box>
        </Box>
        {process.env.NODE_ENV === 'development' && (
          <IconButton
            size='small'
            style={{ position: 'absolute', bottom: '1rem', right: '1rem' }}
            component={NavLink}
            to='/debug'
          >
            <Icon name='pi' color='secondary' sx={{ fontSize: '.75rem' }} />
          </IconButton>
        )}
      </Await>
    </Suspense>
  )
}

export default DashboardContent
