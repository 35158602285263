import { useState, useEffect } from 'react'
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Collapse,
  IconButton,
  Paper,
  Stack,
  Typography,
} from '@mui/material'
import { Form } from 'react-router-dom'

import SectionHeader from 'components/SectionHeader'
import Icon from 'components/Icon'
import Toggle from 'components/Toggle'
import Field from 'components/Field'
import { routes } from 'router'

const IndexPage = ({
  settings = {},
  expanded,
  set_expanded,
  ...header_props
}) => {
  const [disabled, set_disabled] = useState(true)
  const [url, set_url] = useState('')

  useEffect(() => {
    set_disabled(!settings?.active)
    set_url(settings?.url)
  }, [settings, set_disabled])

  const root_routes =
    routes[0]?.children?.map(({ path }) => path).filter(Boolean) || []
  const reserved_routes = root_routes.filter((path) => path !== settings?.url)
  const error = reserved_routes.includes(url) || url === ''

  const helper_text = () => {
    if (url === '') return 'This field cannot be empty.'
    else if (error) return 'This URL is already in use, please choose another.'
    else
      return 'Root path for the index page, for example /my-product. Must be unique across all products and Amains internal pages.'
  }

  return (
    <Form id='general' method='post'>
      <SectionHeader
        {...header_props}
        action={
          <IconButton
            onClick={() => set_expanded(!expanded)}
            aria-expanded={expanded}
            aria-label='show more'
            size='small'
          >
            <Icon name={expanded ? 'chevron-up' : 'chevron-down'} />
          </IconButton>
        }
      />
      <Card>
        <Collapse in={expanded} timeout='auto' unmountOnExit>
          <CardContent>
            <Paper
              variant='outlined'
              color='info'
              style={{
                padding: '.5rem .75rem',
                backgroundColor:
                  'color-mix(in srgb, var(--mui-palette-info-light) 20%, #fff)',
              }}
            >
              <Icon
                name='info-circle'
                color='info'
                style={{
                  float: 'left',
                  fontSize: '2rem',
                  margin: '.5rem .5rem .5rem 0',
                }}
              />
              <Typography component='p' style={{ marginBottom: '1rem' }}>
                <b>
                  An index page for a product allows you to get a searchable
                  overview of all instances of that product type, regardless of
                  which customer and product instance they belong to.
                </b>
              </Typography>
              <Typography component='p'>
                This is not something that every product will need, but can be
                very useful for products where you would like to get an overview
                of all of the instances of that product type. For example if you
                have a product type and 100 customers, each with 10 instances of
                that product, you can get a searchable overview of all 1000
                instances of that product type.
              </Typography>
            </Paper>
            <Stack
              direction='row'
              justifyContent='space-between'
              alignItems='end'
              style={{ padding: '1rem .75rem' }}
            >
              <Toggle
                name='index_page.active'
                unchecked_label='no'
                checked_label='yes'
                checked={!disabled}
                set_checked={(checked) => set_disabled(!checked)}
                align='left'
              />
              <label>Enable index page for this product type?</label>
            </Stack>

            <Field
              label='URL'
              helperText={helper_text()}
              value={disabled ? '*'.repeat(url.length) : url}
              update={set_url}
              name='index_page.url'
              readOnly={disabled}
              disabled={disabled}
              error={error}
            />
          </CardContent>
        </Collapse>
        <CardActions sx={{ justifyContent: 'end' }}>
          <Button
            disabled={error}
            type='submit'
            name='section'
            value='index_page'
            variant='text'
            color='secondary'
            size='small'
          >
            Save
          </Button>
        </CardActions>
      </Card>
    </Form>
  )
}

export default IndexPage
