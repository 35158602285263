import { Box, Container } from '@mui/material'

import Copyright from './Copyright'

const Footer = ({ sx }) => (
  <Box
    component='footer'
    sx={{
      py: 3,
      px: 2,
      mt: 'auto',
      position: 'fixed',
      bottom: 0,
      right: 0,
      left: 0,
      backgroundColor: (theme) =>
        theme.palette.mode === 'light'
          ? theme.palette.grey[200]
          : theme.palette.grey[800],
      ...sx,
    }}
  >
    <Container maxWidth='sm'>
      <Copyright />
    </Container>
  </Box>
)

export default Footer
