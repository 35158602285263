import { styled } from '@mui/material'
import { useDroppable } from '@dnd-kit/core'

const Target = styled('div')`
  width: 100%;
`

const DropTarget = ({ id, children, className = '' }) => {
  const { isOver, setNodeRef } = useDroppable({
    id,
  })

  return (
    <Target
      className={className + (isOver ? ' drag-over' : '')}
      dataid={id}
      ref={setNodeRef}
    >
      {children}
    </Target>
  )
}

export default DropTarget
