import { useState } from 'react'
import { v4 as uuid } from 'uuid'
import { Switch, Stack, Typography, styled } from '@mui/material'
import { motion } from 'framer-motion'
import { useLocation, useParams, useNavigate, Outlet } from 'react-router-dom'

import { useCurrentCustomer } from 'state/useCustomersStore'
import { useCustomerEvents } from 'state/useEventsStore'
import useShouldAnimate from 'hooks/useShouldAnimate'
import LineAdd from 'components/LineAdd'

import generatePinned from './generatePinned'
import Event, { PinnedPanel } from './Event'

const Timeline = styled('section')`
  display: grid;
  grid-template-columns: auto 2rem 1fr;
  grid-gap: 2rem 1rem;
`
const Events = () => {
  const customer = useCurrentCustomer()
  const location = useLocation()
  const navigate = useNavigate()
  const shouldAnimate = useShouldAnimate(`/customers/${customer.id}/events`)
  const { id } = useParams()
  const events = useCustomerEvents(id).filter((event) => event.notes)
  const pinned = generatePinned(events)

  return (
    <>
      <motion.div
        key={location.pathname}
        initial={shouldAnimate ? { x: '50%', opacity: 0.5 } : false}
        animate={{ x: 0, opacity: 1 }}
        exit={shouldAnimate ? { x: '50%', opacity: 0.5 } : false}
      >
        <Timeline>
          <PinnedPanel event={pinned} />
          <div />
          <LineAdd
            onClick={() => navigate(`/customers/${id}/events/${uuid()}`)}
          />
          {events.map((event) => (
            <Event key={event.id} event={event} />
          ))}
        </Timeline>
      </motion.div>
      <Outlet />
    </>
  )
}

export default Events
