import { useEffect, useState } from 'react'
import { Navigate, RouterProvider } from 'react-router-dom'

import Dynamic from 'components/PageLayouts/Dynamic'
import SnackbarStack from 'components/SnackbarStack'
import useSupabase from 'hooks/useSupabase'
import Login from 'pages/Login'
import router from 'router'
import {
  useItems as useProductDefinitions,
  useLoading as useProductDefinitionsLoading,
} from 'state/useProductDefinitionsStore'
import { useLoading, useSession, useSetSession } from 'state/useSession'

const App = () => {
  const session = useSession()
  const loading = useLoading()
  const set_session = useSetSession()
  const supabase = useSupabase()
  const [extra_routes, set_extra_routes] = useState()
  const product_definitions = useProductDefinitions()
  const product_definitions_loading = useProductDefinitionsLoading()

  useEffect(() => {
    supabase.auth.onAuthStateChange((event, session) => {
      console.log('onAuthStateChange', event, session)
      if (
        event === 'SIGNED_IN' ||
        event === 'INITIAL_SESSION' ||
        event === 'USER_UPDATED' ||
        event === 'TOKEN_REFRESHED'
      ) {
        set_session(session)
      } else {
        set_session(null)
      }
    })
  }, [set_session])

  useEffect(() => {
    if (!product_definitions) return

    const extra_routes = product_definitions
      .filter(({ settings }) => settings?.index_page?.active)
      .map(
        ({
          settings: {
            index_page: { url },
          },
        }) => ({
          path: url,
          element: <Dynamic />,
        })
      )

    set_extra_routes(extra_routes)
  }, [product_definitions, set_extra_routes])

  console.log('App', session)

  const waiting_for_dynamic_routes =
    !!session && (product_definitions_loading || !extra_routes?.length)

  if (loading || waiting_for_dynamic_routes) {
    console.log('still loading')
    return
  }

  if (!session) {
    console.log('not logged in')
    return <Login />
  }

  console.log('logged in')

  return (
    <div className='App' style={{ minHeight: '100vh' }}>
      <RouterProvider router={router(extra_routes)} />
      <SnackbarStack />
    </div>
  )
}

export default App
