import { Stack } from '@mui/material'
import { useParams } from 'react-router-dom'

import TableOfContent from 'components/TableOfContent'
import SideNavigationButton from 'components/SideNavigationButton'

const SideNavigation = ({ items }) => {
  const { id } = useParams()
  return (
    <div style={{ position: 'sticky', top: '2rem' }}>
      <TableOfContent title='Sections' items={items} />
    </div>
  )
}

export default SideNavigation
