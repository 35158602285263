import { useState } from 'react'
import { Button, Collapse, IconButton, Typography } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import { Form, useNavigate } from 'react-router-dom'

import Text from 'components/Text'
import Icon from 'components/Icon'

import Attribute from './Attribute'

const Contact = ({ contact, index }) => {
  const [expanded, set_expanded] = useState(false)
  const odd = index % 2 !== 0
  const navigate = useNavigate()

  return (
    <>
      <Grid container spacing={2} sx={{ m: 0 }}>
        <Grid item xs={6}>
          <Text
            icon={contact.owner ? 'user-tie' : 'user'}
            value={contact.name}
            label='Name'
            helperText='Full name of the contact'
          />
        </Grid>
        <Grid item xs={4}>
          <Text
            value={contact.title}
            label='Title'
            helperText='Working title or job description'
          />
        </Grid>
        <Grid item xs={1}>
          <IconButton
            aria-label='edit'
            sx={{ mt: 2.2 }}
            onClick={() => navigate(`contacts/${contact.id}`)}
          >
            <Icon name='edit' />
          </IconButton>
        </Grid>
        <Grid item xs={1}>
          <Form action='' method='POST'>
            <input type='hidden' name='id' value={contact.id} />
            <IconButton
              aria-label='edit'
              sx={{ mt: 2.2 }}
              type='submit'
              name='section'
              value='delete_contact'
            >
              <Icon name='trash' />
            </IconButton>
          </Form>
        </Grid>
        {contact.attributes.slice(0, 2).map((attribute, index) => (
          <Attribute key={index} {...attribute} index={index} odd={odd} />
        ))}
        <Collapse in={expanded} unmountOnExit>
          {contact.attributes.slice(2).map((attribute, index) => (
            <Attribute key={index} {...attribute} index={index} odd={odd} />
          ))}
          {contact.notes ? (
            <Grid xs={12}>
              <Typography variant='body2' color='text.disabled'>
                {contact.notes}
              </Typography>
            </Grid>
          ) : null}
        </Collapse>
        {contact.notes || contact.attributes.length > 2 ? (
          <Grid item xs={12} sx={{ m: 0 }}>
            <Button
              variant='text'
              size='small'
              color='primary'
              onClick={() => set_expanded(!expanded)}
              sx={{ m: 0 }}
              style={{ textTransform: 'inherit' }}
            >
              {expanded ? 'Hide' : 'Show more'}
            </Button>
          </Grid>
        ) : null}
      </Grid>
    </>
  )
}

export default Contact
