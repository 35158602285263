import { forwardRef } from 'react'
import { InputAdornment, TextField } from '@mui/material'

import Icon from 'components/Icon'

const Text = forwardRef(
  ({ InputLabelProps = {}, value, icon, ...args }, ref) => (
    <TextField
      defaultValue={value}
      inputRef={ref}
      fullWidth
      margin='dense'
      size='small'
      variant='standard'
      disabled
      InputProps={{
        readOnly: true,
        startAdornment: icon ? (
          <InputAdornment position='start'>
            <Icon name={icon} />
          </InputAdornment>
        ) : null,
      }}
      {...args}
      InputLabelProps={{
        shrink: true,
        ...InputLabelProps,
      }}
      inputProps={{
        style: {
          color: 'var(--mui-palette-common-onBackground)',
          WebkitTextFillColor: 'currentColor',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
      }}
    />
  )
)

Text.displayName = 'Text'

export default Text
