import {
  Button,
  CardActions,
  CardContent,
  CardHeader,
  IconButton,
  Modal,
  Card as MuiCard,
  styled,
} from '@mui/material'

import Icon from 'components/Icon'

const Card = styled(MuiCard)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 80vw;
  max-height: 100vh;
  background-color: #fff;
  box-shadow: 24;
`

const Confirm = ({ title, subheader, yes_text, no_text, close, children }) => (
  <Modal open>
    <Card>
      <CardHeader
        title={title}
        subheader={subheader}
        action={
          <IconButton aria-label='close' onClick={close}>
            <Icon name='xmark' />
          </IconButton>
        }
      />
      {children && <CardContent>{children}</CardContent>}
      <CardActions sx={{ justifyContent: 'end', padding: '0 1rem 1rem' }}>
        <Button
          variant='text'
          color='secondary'
          size='small'
          onClick={() => close(false)}
        >
          {no_text}
        </Button>
        <Button
          variant='outlined'
          color='secondary'
          size='small'
          onClick={() => close(true)}
        >
          {yes_text}
        </Button>
      </CardActions>
    </Card>
  </Modal>
)

export default Confirm
