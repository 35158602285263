import { useState } from 'react'
import { Card, CardContent, Tab, Tabs } from '@mui/material'

import SectionHeader from 'components/SectionHeader'

import Menu from '../Menu'
import Common from './Common'
import Static from './Static'

const Normal = ({ state, update, onMenuSelect: on_menu_select }) => {
  const [activeTab, setActiveTab] = useState(0)

  return (
    <>
      <SectionHeader
        icon='shopping-bag'
        title='Product definition'
        subtitle='Set or adjust the product properties and variables'
        on_menu_select={on_menu_select}
        menu={Menu}
      />
      <Card id={state.id}>
        <CardContent>
          <Tabs
            value={activeTab}
            onChange={(_, value) => setActiveTab(value)}
            aria-label='basic tabs example'
          >
            <Tab label='Common' />
            <Tab label='Static' />
            <Tab label='Calculation' />
          </Tabs>

          {activeTab === 0 && <Common {...state} update={update} />}
          {activeTab === 1 && <Static />}
          {activeTab === 2 && <div>Calculation</div>}
        </CardContent>
      </Card>
    </>
  )
}

export default Normal
