import { useState } from 'react'
import { Outlet } from 'react-router-dom'

import Columns from 'components/Columns'
import Column from 'components/Column'
import SectionHeader from 'components/SectionHeader'

import SideNavigation from './SideNavigation'
import CompanyInfo from './CompanyInfo'

const Organisation = () => {
  const [general_expanded, set_general_expanded] = useState(true)

  return (
    <>
      <Columns>
        <Column>
          <SideNavigation />
        </Column>
        <Column>
          <CompanyInfo
            expanded={general_expanded}
            set_expanded={set_general_expanded}
          />
        </Column>
      </Columns>
      <Outlet />
    </>
  )
}

export default Organisation
