import { v4 as uuid } from 'uuid'
import { Checkbox, Grid, InputAdornment } from '@mui/material'

import Icon from 'components/Icon'
import Field from 'components/Field'

const Form = ({
  contact: { id = uuid(), name = '', owner = false, title = '', notes = '' },
  update,
}) => (
  <Grid container spacing={2} sx={{ width: '100%' }}>
    <Grid item xs={6}>
      <input type='hidden' name='id' value={id} />
      <Field
        label='Name'
        helperText='Contacts full legal name. Toggle the icon to set as owner.'
        name='name'
        defaultValue={name}
        InputProps={{
          startAdornment: (
            <InputAdornment position='start'>
              <Checkbox
                defaultChecked={!!owner}
                name='owner'
                inputProps={{ 'aria-label': 'toggle owner status' }}
                icon={<Icon name='user' />}
                checkedIcon={<Icon name='user-tie' />}
                sx={{ left: '-.5rem' }}
              />
            </InputAdornment>
          ),
        }}
      />
      <Field
        label='Position'
        helperText='Role, position or title in the company.'
        name='title'
        defaultValue={title}
      />
    </Grid>
    <Grid item xs={6}>
      <Field
        label='Notes'
        helperText='Any additional notes or information about this contact.'
        name='notes'
        defaultValue={notes}
        multiline
        minRows={5}
      />
    </Grid>
  </Grid>
)

export default Form
