import {
  FormControlLabel,
  ToggleButtonGroup,
  ToggleButton,
} from '@mui/material'

import Icon from 'components/Icon'
import Field from 'components/Field'

const SharedFields = ({
  update,
  width = '1fr',
  example_data = '',
  justify = 'left',
}) => (
  <>
    <FormControlLabel
      style={{ justifyContent: 'space-between', margin: 0 }}
      control={
        <ToggleButtonGroup
          value={justify}
          size='small'
          color='primary'
          exclusive
          onChange={(_event, value) => {
            if (value) update({ justify: value })
          }}
          aria-label='text alignment'
        >
          <ToggleButton value='left' aria-label='left aligned'>
            <Icon name='align-left' />
          </ToggleButton>
          <ToggleButton value='center' aria-label='centered'>
            <Icon name='align-center' />
          </ToggleButton>
          <ToggleButton value='right' aria-label='right aligned'>
            <Icon name='align-right' />
          </ToggleButton>
        </ToggleButtonGroup>
      }
      label='Alignment'
      labelPlacement='start'
    />

    <Field
      label='Example data'
      value={example_data}
      update={(value) => update({ example_data: value })}
      name='example_data'
      helperText='Example data to show in the row'
    />

    <Field
      label='Width'
      value={width}
      update={(value) => update({ width: value })}
      name='width'
      helperText='Column width, e.g. 1fr, 2fr, 100px, auto'
    />
  </>
)

export default SharedFields
