import { useEffect, useState } from 'react'
import { Stack, styled } from '@mui/material'
import { highlightAll } from 'prismjs/components/prism-core'
import 'prismjs/components/prism-clike'
import 'prismjs/components/prism-javascript'
import 'prismjs/components/prism-json'
import 'prismjs/plugins/line-numbers/prism-line-numbers.js'
import 'prismjs/plugins/show-invisibles/prism-show-invisibles.js'
import 'prismjs/themes/prism-twilight.css'
import 'prismjs/plugins/line-numbers/prism-line-numbers.css'
import 'prismjs/plugins/show-invisibles/prism-show-invisibles.css'

import { useHotkeys } from 'react-hotkeys-hook'

const Container = styled('section')`
  --left-gutter: 4rem;
  position: relative;
  overflow: hidden;
  &::after {
    border-bottom: 2px solid #74749b;
    left: 0;
    bottom: 0;
    content: '';
    position: absolute;
    right: 0;
    transform: scaleX(0);
    transition: transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    pointer-events: none;
  }
  &.focused::after {
    transform: scaleX(1) translateX(0);
  }
  align-self: start;
`

const Preview = styled('pre')`
  padding: 8.5px 14px !important;
  margin: 0 !important;
  padding-left: var(--left-gutter) !important;
  min-height: calc(4.5rem + 20px);
  font-size: 1rem !important;
  line-height: 1.5 !important;
  pointer-events: none;
  letter-spacing: normal;
  border-radius: 4px !important;
  border: none !important;

  & code[class*='language-'] {
    text-shadow: none !important;
  }

  & .token.lf:before {
    content: '\\23CE';
  }
`

const Textarea = styled('textarea')`
  padding: 8.5px 14px 8.5px 0;
  border: none;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: var(--left-gutter);
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: normal;
  color: transparent;
  caret-color: #fff;
  background: transparent;
  outline: none;
  resize: none;
`

const LineNumbers = styled('span')`
  position: absolute;
  bottom: 0.5rem;
  right: 1rem;
  color: #555;
  font-family: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;
  user-select: none;
`

const CodeEditor = ({
  code = '',
  onChange,
  onSave,
  line_numbers = false,
  language = 'js',
}) => {
  const [focused, setFocused] = useState(false)
  const [column, set_column] = useState(0)
  const [line, set_line] = useState(0)
  const ref = useHotkeys(
    'ctrl+s, meta+s',
    (event) => {
      console.log('saving', event)
      event.preventDefault()
      event.stopPropagation()
      onSave()
    },
    { enableOnFormTags: true }
  )

  const get_position = ({ target }) => {
    const pos = target.selectionStart
    const lines = target.value.substring(0, pos).split('\n')

    set_line(lines.length)
    set_column(lines[lines.length - 1].length + 1)
  }

  useEffect(() => {
    highlightAll()
  }, [code])

  return (
    <Container className={focused ? 'focused' : ''}>
      <Preview className='line-numbers'>
        <code className={`language-${language}`}>{code}</code>
      </Preview>
      <Textarea
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        ref={ref}
        autoComplete='off'
        autoCorrect='off'
        spellCheck='false'
        autoCapitalize='off'
        value={code}
        onKeyUp={get_position}
        onMouseUp={get_position}
        onChange={({ target: { value } }) => onChange(value)}
      />
      {line_numbers && (
        <LineNumbers>
          Line {line}, Col {column}
        </LineNumbers>
      )}
    </Container>
  )
}

export default CodeEditor
