import Customers from 'pages/Customer/Collection'
import customer_route from 'routes/customers/customer'
import customer_events_route from 'routes/customers/events'
import customer_products_route from 'routes/customers/products'

const route = {
  id: 'customers',
  path: 'customers',
  children: [
    {
      index: true,
      element: <Customers />,
    },
    customer_route,
    customer_events_route,
    customer_products_route,
  ],
}

export default route
