import _ from 'lodash'
import { useParams } from 'react-router'
import { useEffect, useState } from 'react'

import ProductEditor from 'components/ProductEditor'
import Columns from 'components/Columns'
import Column from 'components/Column'
import scrollTo from 'utilities/scrollTo'
import {
  useLoading,
  useProducts,
  useCurrentProduct,
} from 'state/useProductsStore'

import SideNavigation from './SideNavigation'

const Product = () => {
  const loading = useLoading()
  const product = useCurrentProduct()
  const addons = useProducts().filter((p) => product.addons.includes(p.id))
  const [state, setState] = useState(product)
  const [sections, set_sections] = useState(product?.configuration?.variables)

  useEffect(() => {
    setState(product)
  }, [product?.id])

  const update = (change) => {
    const newState = {
      ...state,
      ...change,
    }

    setState(newState)

    if (
      state.configuration.variables.length >=
      (change?.configuration?.variables?.length || 0)
    )
      return

    const newVariableId = _.difference(
      newState.configuration.variables.map((v) => v.id),
      state.configuration.variables.map((v) => v.id)
    )[0]

    scrollTo(newVariableId, 50)
  }

  if (loading || !product?.id) return

  return (
    <>
      <Columns>
        <Column>
          <SideNavigation
            {...state}
            products={addons}
            update={(variables) =>
              update({ configuration: { ...state.configuration, variables } })
            }
          />
        </Column>

        <Column>
          <ProductEditor product={state} update={update} />
        </Column>
      </Columns>
    </>
  )
}

export default Product
