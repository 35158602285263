import { format, formatDistance, parseJSON } from 'date-fns'

import useSettings from 'hooks/useSettings'
import { getCurrentAccount } from 'state/useAccountsStore'

export const relative_time = (timestamp) =>
  formatDistance(parseJSON(timestamp), new Date(), {
    addSuffix: true,
  })

export const absolute_time = (timestamp) =>
  format(parseJSON(timestamp), 'yyyy-MM-dd HH:mm')

export const useFormatedTime = (timestamp) => {
  const { display_time_as } = useSettings()

  if (timestamp === null) {
    return ''
  }

  return display_time_as === 'relative'
    ? relative_time(timestamp)
    : absolute_time(timestamp)
}

export const format_time = (timestamp) => {
  if (!timestamp || timestamp === '') return ''

  const account = getCurrentAccount()

  if (!account.settings) return ''

  const { display_time_as = 'relative' } = account?.settings

  try {
    return display_time_as === 'relative'
      ? relative_time(timestamp)
      : absolute_time(timestamp)
  } catch (error) {
    if (error instanceof RangeError) {
      return ''
    }
    throw error
  }
}
