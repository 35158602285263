import Calculation from './Calculation'
import Input from './Input'

const Variable = (props) => {
  if (!props.visible) return
  switch (props.type) {
    case 'calculation':
      return <Calculation {...props} />
    default:
      return <Input {...props} />
  }
}

export default Variable
