import { v4 as uuid } from 'uuid'
import { Grid } from '@mui/material'
import Field from 'components/Field'
import ImageSelect from 'components/ImageSelect'

const Form = ({
  customer: {
    id = uuid(),
    name = '',
    legal_name = '',
    number = '',
    owners = [],
    description = '',
    logo_url = '',
  } = {},
  customer = {},
  update,
}) => (
  <Grid container spacing={2}>
    <Grid item xs={4}>
      <Field
        label='Common name'
        helperText='Colloquial company name, like "Accodeing"'
        value={name}
        update={(value) => update({ name: value })}
      />
    </Grid>
    <Grid item xs={4}>
      <Field
        label='Legal name'
        helperText='Legal company name, like "Accodeing to you KB"'
        value={legal_name}
        update={(value) => update({ legal_name: value })}
      />
    </Grid>
    <Grid item xs={4}>
      <Field
        label='Organisational number'
        helperText='The TIN number for the legal entity, for example "969786-4776"'
        value={number}
        update={(value) =>
          update({
            number: value,
          })
        }
      />
    </Grid>
    <Grid item xs={8}>
      <Field
        multiline
        minRows={3}
        label='Description'
        helperText='Description of the company and its focus'
        value={description}
        update={(value) => update({ description: value })}
      />
    </Grid>
    <Grid item xs={4}>
      <Field
        label='Owner names'
        helperText='First and last name. Comma seperate several owners'
        value={owners.join(', ')}
        update={(value) =>
          update({
            owners: value.split(',').map((x) => x.trimStart()),
          })
        }
      />
    </Grid>
    <Grid item xs={12}>
      <ImageSelect
        url={logo_url}
        onChange={(value) => update({ logo_url: value })}
      />
    </Grid>
  </Grid>
)

export default Form
