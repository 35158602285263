export const filter_invitations = (invitations, filter) =>
  invitations.filter((invitation) => {
    if (filter === '') return true

    const email = invitation.email.toLowerCase()
    const account = invitation.account.name.toLowerCase()
    const search = filter.toLowerCase()
    const email_match = email.includes(search)
    const account_match = account.includes(search)

    return email_match || account_match
  })
