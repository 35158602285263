import { ListItemIcon, ListItemText } from '@mui/material'

import Icon from 'components/Icon'

import ListItemButton from './ListItemButton'
import NavLink from './NavLink'

const static_menu = [
  { icon: 'chart-tree-map', text: 'Dashboard', to: '/' },
  { icon: 'circle-user', text: 'Customers', to: 'customers' },
  { icon: 'bags-shopping', text: 'Products', to: 'products' },
  // { icon: 'people-simple', text: 'Users', to: '', disabled: true },
  // { icon: 'chart-line-up', text: 'Reports', to: '', disabled: true },
  // { icon: 'puzzle', text: 'Integrations', to: '', disabled: true },
]

const StaticMenuItems = () =>
  static_menu.map(({ icon, text, to, ...props }) => (
    <ListItemButton
      key={text}
      {...props}
      component={NavLink}
      end={true}
      to={to}
    >
      <ListItemIcon>
        <Icon name={icon} />
      </ListItemIcon>
      <ListItemText primary={text} />
    </ListItemButton>
  ))

export default StaticMenuItems
