import { supabase } from 'hooks/useSupabase'
import { appendError, appendSuccess } from 'state/useSnackbarStore'

const upsert = async (path, data) => {
  const { error, statusText } = await supabase
    .from(path)
    .upsert(data, { returning: 'minimal', onConflict: 'id' })
    .single()

  if (error) {
    appendError(`Update of ${path} failed with: ${statusText}`)
    return
  }

  appendSuccess('Update successful')
}

export default upsert
