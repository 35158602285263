import Field from 'components/Field'

export const String = ({
  maxLength = '',
  minLength = '',
  regex = '',
  onChange,
}) => (
  <>
    <Field
      label='Minimum string length'
      value={minLength}
      helperText='Leave empty for no limit'
      update={(value) => onChange({ minLength: value })}
    />
    <Field
      label='Maximum string length'
      value={maxLength}
      helperText='Leave empty for no limit'
      update={(value) => onChange({ maxLength: value })}
    />
    <Field
      label='Validation regex'
      value={regex}
      placeholder='/A-Za-z/ig'
      helperText='Javascript regex syntax, include trailing modifiers like i and g'
      update={(value) => onChange({ regex: value })}
    />
  </>
)
