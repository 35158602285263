import {
  CardContent,
  CardHeader,
  Icon,
  IconButton,
  Modal,
  Card as MuiCard,
} from '@mui/material'
import { styled } from '@mui/material/styles'

const Card = styled(MuiCard)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80vw;
  max-height: 80vh;
  background-color: #fff;
  box-shadow: 24;
`

const Product = ({ configuration: { name, description }, onClick }) => (
  <MuiCard onClick={onClick}>
    <CardHeader title={name} subheader={description} />
  </MuiCard>
)

const ProductSelect = ({
  open,
  title = 'Select product',
  availableProducts = [],
  onSelect,
  onClose,
}) => (
  <Modal
    open={open}
    onClose={onClose}
    aria-labelledby='modal-modal-title'
    aria-describedby='modal-modal-description'
  >
    <Card>
      <CardHeader
        title={title}
        subheader='Select a product to add to the customer'
        action={
          <IconButton aria-label='close' onClick={onClose}>
            <Icon name='cirkle-xmark' />
          </IconButton>
        }
      />
      <CardContent>
        {availableProducts.map((product) => (
          <Product
            key={product.id}
            {...product}
            onClick={() => onSelect(product.id)}
          />
        ))}
      </CardContent>
    </Card>
  </Modal>
)

export default ProductSelect
