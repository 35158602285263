import CollectionHeader from 'components/CollectionHeader'

const Dashboard = () => (
  <>
    <CollectionHeader
      title='Dashboard'
      description='Welcome to your dashboard. Here you can see a summary of your account.'
      show_search={false}
    />
  </>
)

export default Dashboard
