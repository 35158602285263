import React from 'react'
import { Tooltip, Typography } from '@mui/material'

import Icon from 'components/Icon'

const CopyableAttribute = ({ title, value }) => (
  <>
    <Typography variant='caption'>{title} </Typography>
    <Tooltip
      title={
        <Typography
          fontSize='.65rem'
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
          variant='subtitle1'
          pt={0}
          noWrap
        >
          <Icon size='small' name='clipboard' />
          Click to copy to clipboard
        </Typography>
      }
      placement='right'
      enterDelay={100}
      arrow
    >
      <Typography
        variant='subtitle2'
        pt={0}
        noWrap
        onClick={() => {
          navigator.clipboard.writeText(value)
        }}
        style={{
          cursor: 'pointer',
        }}
      >
        {value}
      </Typography>
    </Tooltip>
  </>
)

export default CopyableAttribute
