import { Navigate, Link, useRouteError } from 'react-router-dom'
import { Typography, Button } from '@mui/material'

import Products from 'pages/Product/Collection'
import { useCurrent } from 'state/useProductInstancesStore'
import Icon from 'components/Icon'

import product from './product'
import settings from './settings'
import components from './components'

const ErrorBoundary = (props) => {
  const product_definition = useCurrent()

  let error = useRouteError()
  console.error(error)

  if (error) {
    return (
      <>
        <Typography variant='h4'>An error occured</Typography>
        <Typography variant='body1' mt={2}>
          {error.message}
        </Typography>
      </>
    )
  }

  if (!product_definition?.id)
    return (
      <>
        <Typography variant='h4'>This product is not available</Typography>
        <Typography variant='body1' mt={2}>
          If you recently switched organisations, you will no longer be able to
          access resources belonging to the other organisation.
        </Typography>
        <Typography variant='body1' mt={1}>
          Please go back to the product list and select a product from the
          current organisation.
        </Typography>
        <Button
          variant='outlined'
          color='primary'
          sx={{ mt: 3 }}
          component={Link}
          to='/products'
        >
          <Icon name='arrow-left' style={{ marginRight: '.5rem' }} /> Back to
          product list
        </Button>
      </>
    )

  return <Navigate to='.' replace={true} relative='path' />
}

const route = {
  id: 'products',
  path: 'products',
  errorElement: <ErrorBoundary />,
  children: [
    {
      index: true,
      element: <Products />,
    },
    product,
    settings,
    components,
  ],
}

export default route
