import { create } from 'zustand'
import preload from 'utilities/preload'

const useSessionStore = create((set, get) => ({
  session: undefined,
  loading: true,
  set_session: async (session) => {
    // Prevent several rerenders when the session is the same
    if (session && get().session?.access_token === session?.access_token) return
    // Set the session
    set({ session, loading: false })
    // No need to preload unless we have a session
    if (!session) return
    // Preload all the data we need for this user
    await preload()
  },
}))

export const useSession = () => useSessionStore((state) => state.session)
export const useLoading = () => useSessionStore((state) => state.loading)
export const useSetSession = () => useSessionStore((state) => state.set_session)

export const getSession = () => useSessionStore.getState().session
