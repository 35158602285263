import { useRef, useState } from 'react'
import { styled } from '@mui/material/styles'
import { useNavigate } from 'react-router-dom'

import useSupabase from 'hooks/useSupabase'
import { useSession } from 'state/useSession'
import { useTenants } from 'state/useTenantsStore'
import Icon from 'components/Icon'

import UserButton from './UserButton'
import UserMenu from './UserMenu'
import OrgButton from './OrgButton'
import OrgMenu from './OrgMenu'
import { IconButton, Tooltip } from '@mui/material'

const Container = styled('div')`
  position: sticky;
  top: 0;
  margin: 0 auto;
  padding: 1rem 2rem;
  z-index: 1000;
  display: flex;
  justify-content: end;
  gap: 1rem;
`

const UserMenus = () => {
  const supabase = useSupabase()
  const userMenuRef = useRef()
  const orgMenuRef = useRef()
  const [userMenuOpen, setUserMenuOpen] = useState(false)
  const [orgMenuOpen, setOrgMenuOpen] = useState(false)
  const navigate = useNavigate()
  const {
    user: {
      app_metadata: { current_tenant },
    },
  } = useSession()
  const organisations = useTenants()
  const organisation = organisations.find(({ id }) => id === current_tenant)
  const nested_path = window.location.pathname.split('/').length > 3

  return (
    <Container>
      {nested_path && (
        <Tooltip title='Back to documnet' placement='right'>
          <IconButton
            sx={{ mr: 'auto' }}
            onClick={() => {
              navigate(
                window.location.pathname.split('/').slice(0, -1).join('/')
              )
            }}
          >
            <Icon name='arrow-left' />
          </IconButton>
        </Tooltip>
      )}
      <OrgButton
        organisation={organisation}
        disable={organisations.length === 1}
        ref={orgMenuRef}
        open={orgMenuOpen}
        onClick={() => {
          setOrgMenuOpen(true)
        }}
      />
      <UserButton
        ref={userMenuRef}
        open={userMenuOpen}
        onClick={() => {
          setUserMenuOpen(true)
        }}
      />
      <UserMenu
        anchorEl={userMenuRef.current}
        open={userMenuOpen}
        onClose={() => {
          setUserMenuOpen(false)
        }}
      />
      <OrgMenu
        organisations={organisations}
        anchorEl={orgMenuRef.current}
        open={orgMenuOpen}
        select={async (id) => {
          const { data, error } = await supabase.functions.invoke(
            'set-current-tenant',
            { body: { requested_tenant_id: id } }
          )
          if (!error) {
            supabase.auth.refreshSession()
          }
        }}
        onClose={() => {
          setOrgMenuOpen(false)
        }}
      />
    </Container>
  )
}

export default UserMenus
