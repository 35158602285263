import Field from 'components/Field'

const Calculation = ({
  //icon,
  name,
  variable_name,
  description = '',
  required,
  //visible,
  attributes: { default_value = '' },
  state,
  //update,
}) => (
  <Field
    required={required}
    label={name}
    helperText={description}
    disabled
    value={state[variable_name] || default_value}
  />
)

export default Calculation
