import { useState } from 'react'
import { IconButton, styled } from '@mui/material'

import Icon from 'components/Icon'
import * as formatters from 'components/PageLayouts/Dynamic/formatters'

import Draggable from '../Draggable'

const StyledDraggable = styled(Draggable)`
  flex-direction: ${({ justify }) =>
    justify === 'right' ? 'row-reverse' : 'row'};
  & .MuiIconButton-root {
    opacity: 0;
    transition: opacity 200ms ease-in-out;
    align-self: center;
    margin-inline: 0.5rem;
  }
  .mouse-over & .MuiIconButton-root {
    opacity: 1;
  }
`

const Container = styled('div')`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: ${({ justify }) => justify};
  gap: 0.5rem;
  padding: 0.5rem 1rem;
`

const PlaceholderText = styled('span')`
  color: #888;
  font-style: italic;
`

const Output = ({ value, text, attribute, prefix, suffix }) =>
  value ? (
    <span>{text}</span>
  ) : (
    <>
      <span>{prefix}</span>
      <PlaceholderText>{attribute}</PlaceholderText>
      <span>{suffix}</span>
    </>
  )

const Field = ({
  attribute,
  open_settings,
  prefix = '',
  example_data = '',
  suffix = '',
  type = 'text',
  justify = 'left',
  ...props
}) => {
  const [over_menu, set_over_menu] = useState(false)
  const text = formatters[type](prefix, example_data || attribute, suffix)

  return (
    <StyledDraggable
      name={attribute}
      parent='table'
      disabled={over_menu}
      justify={justify}
    >
      <Container justify={justify} {...props}>
        <Output
          value={example_data}
          text={text}
          attribute={attribute}
          prefix={prefix}
          suffix={suffix}
        />
      </Container>
      <IconButton
        size='small'
        onClick={(event) => {
          open_settings(event.target)
        }}
        onMouseEnter={() => set_over_menu(true)}
        onMouseLeave={() => set_over_menu(false)}
      >
        <Icon name='ellipsis-vertical' />
      </IconButton>
    </StyledDraggable>
  )
}

export default Field
