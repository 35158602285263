import { styled } from '@mui/material'

const Logotype = styled('img')`
  position: relative;
  grid-area: a;
  object-fit: contain;
  object-position: center right;
  max-width: 100%;
  max-height: 5rem;
  aspect-ratio: 3 / 1;
  &::before {
    content: '';
    position: absolute;
    inset: 0;
    background-color: var(--mui-palette-quinary-light);
    border: 2px solid var(--mui-palette-quinary-main);
  }
  &::after {
    content: attr(alt);
    position: absolute;
    inset: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--mui-palette-quinary-dark);
  }
`
export default Logotype
