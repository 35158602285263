import { forwardRef } from 'react'
import { Chip, Stack } from '@mui/material'

import Icon from 'components/Icon'

import Avatar from './Avatar'

const UserButton = forwardRef(
  ({ organisation, disable, onClick, open }, ref) => {
    return (
      <Chip
        ref={ref}
        variant='outlined'
        sx={{
          'bgcolor': open ? '#ddd' : 'transparent',
          '&:hover': {
            bgcolor: '#ddd',
          },
        }}
        label={
          <Stack direction='row' gap={1}>
            <>
              {organisation?.name}
              {disable || <Icon style={{ color: 'gray' }} name='caret-down' />}
            </>
          </Stack>
        }
        color='secondary'
        onClick={disable ? null : onClick}
        avatar={
          <Avatar
            sx={{ border: '1px solid #fff8' }}
            src={organisation?.logo_url}
            alt={`${organisation?.name}'s logotype`}
          />
        }
      />
    )
  }
)

UserButton.displayName = 'UserButton'

export default UserButton
