import { Card, CardContent, Popover } from '@mui/material'

import { Divider } from '@mui/material'

import { FlexColumn } from 'components/Flex'

import Shared from './Sections/Shared'
import Text from './Sections/Text'

const FieldSettings = ({
  popover_ref,
  close,
  update,
  type = 'text',
  width = '1fr',
  example_data = '',
  justify = 'left',
  prefix = '',
  suffix = '',
}) => (
  <Popover
    open={Boolean(popover_ref)}
    anchorEl={popover_ref}
    onClose={close}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
  >
    <Card>
      <CardContent>
        <FlexColumn>
          <Shared {...{ type, width, justify, update }} />

          <Divider />

          <Text {...{ type, prefix, suffix, example_data, update }} />
        </FlexColumn>
      </CardContent>
    </Card>
  </Popover>
)

export default FieldSettings
