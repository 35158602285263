import { create } from 'zustand'

const useLocationHistoryStore = create((set) => ({
  history: '',
  push: (path) => set({ history: path }),
}))

export const usePush = () => useLocationHistoryStore((state) => state.push)
export const useLocationHistory = () =>
  useLocationHistoryStore((state) => state.history)

export default useLocationHistoryStore
