import { create } from 'zustand'
import { mountStoreDevtool } from 'simple-zustand-devtools'

import { supabase } from 'hooks/useSupabase'
import { collection } from 'utilities/routes'

const useInvitationsStore = create((set) => {
  return {
    loading: true,
    invitations: [],
    fetchInvitations: async () => {
      const invitations = await collection({
        path: 'invitations',
        builder: (query) =>
          query.select(
            '*, account:account_id( id, name ), organisation:tenant_id( customer:customer_id( name ) )'
          ),
      })

      set({ invitations, loading: false })
    },
    addInvitation: (invitation) =>
      set(({ invitations }) => ({ invitations: [...invitations, invitation] })),
    updateInvitation: (updated) =>
      set((state) => ({
        invitations: state.invitations.map((invitation) =>
          invitation.id === updated.id
            ? { ...invitation, ...updated }
            : invitation
        ),
      })),
    deleteInvitation: ({ id }) =>
      set((state) => ({
        invitations: state.invitations.filter(
          (invitation) => invitation.id !== id
        ),
      })),
  }
})

export const useLoading = () => useInvitationsStore((state) => state.loading)
export const useInvitations = () =>
  useInvitationsStore((state) => state.invitations)
export const useInvitation = (id) =>
  useInvitationsStore((state) =>
    state.invitations.find((invitation) => invitation.id === id)
  )

export const addInvitation = useInvitationsStore.getState().addInvitation
export const updateInvitation = useInvitationsStore.getState().updateInvitation
export const deleteInvitation = useInvitationsStore.getState().deleteInvitation
export const fetchInvitations = useInvitationsStore.getState().fetchInvitations

export const subscriptionHandler = async (event, entity) => {
  console.log('invitation subscription handler', event, entity)
  switch (event) {
    case 'INSERT':
      const { data, error } = await supabase
        .from('accounts')
        .select('name')
        .eq('id', entity.account_id)
        .single()

      if (error)
        console.log('Failed to fetch user info for new Invitation', error)

      addInvitation({ ...entity, account: data })
      break
    case 'UPDATE':
      updateInvitation(entity)
      break
    case 'DELETE':
      console.log('delete invitation', entity)
      deleteInvitation(entity)
      break
    default:
      console.log('unknown event type')
  }
}

mountStoreDevtool('Invitations', useInvitationsStore)
