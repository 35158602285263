import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio as MuiRadio,
  RadioGroup,
  Typography,
} from '@mui/material'

const Radio = ({
  icon,
  name,
  variable_name,
  description = '',
  required,
  visible,
  attributes: { default_value, alternatives },
  state,
  update,
}) => (
  <FormControl sx={{ paddingLeft: '14px' }}>
    <FormLabel id={`${variable_name}-radio-buttons-group-label`}>
      <Typography variant='caption'>{name}</Typography>
    </FormLabel>
    <RadioGroup
      aria-labelledby={`${variable_name}-radio-buttons-group-label`}
      defaultValue={default_value || alternatives[0].value}
      name={`${variable_name}-radio-buttons-group`}
      onChange={({ target: { value } }) => update({ [variable_name]: value })}
    >
      {alternatives.map(({ value, label }) => (
        <FormControlLabel
          key={value}
          value={value}
          control={<MuiRadio />}
          label={<Typography variant='body1'>{label}</Typography>}
        />
      ))}
    </RadioGroup>
    <Typography variant='caption' sx={{ opacity: 0.5 }}>
      {description}
    </Typography>
  </FormControl>
)

export default Radio
