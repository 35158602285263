import { Outlet } from 'react-router-dom'

import { Table } from 'components/Table'
import { useAccounts } from 'state/useAccountsStore'
import CollectionHeader from 'components/CollectionHeader'
import search from 'utilities/search'
import useQueryParam from 'hooks/useQueryParam'

import User from './User'

const Users = () => {
  const users = useAccounts()
  const search_term = useQueryParam('search')

  const filtered_users = search(users, search_term, 'name', 'email')

  return (
    <>
      <CollectionHeader title='Users' />
      <Table columns='1fr 1fr 1fr 1fr 1fr auto'>
        {filtered_users.map((user) => (
          <User key={user.id} {...user} />
        ))}
      </Table>
      <Outlet />
    </>
  )
}

export default Users
