const useQueryParams = () =>
  window.location.search
    .slice(1)
    .split('&')
    .map((atom) => atom.split('='))
    .reduce((acc, [key, value]) => {
      acc[key] = value
      return acc
    }, {})

export default useQueryParams
