import { supabase } from 'hooks/useSupabase'
import { appendError } from 'state/useSnackbarStore'

const collection = async ({ path, builder }) => {
  let query = supabase.from(path).select('*')

  if (builder) query = builder(query)

  const { data, error } = await query

  if (error) appendError(`Loading of ${path} failed with: ${error.message}`)

  return data || []
}

export default collection
