import {
  FormControl,
  FormControlLabel,
  FormLabel,
  RadioGroup as MuiRadioGroup,
  Radio,
} from '@mui/material'

const RadioGroup = ({ label, value, options, onChange }) => (
  <FormControl>
    <FormLabel id='demo-row-radio-buttons-group-label'>{label}</FormLabel>
    <MuiRadioGroup
      row
      aria-labelledby='demo-row-radio-buttons-group-label'
      name='row-radio-buttons-group'
      value={value}
      onChange={({ target: { value } }) => onChange(value)}
    >
      {options.map((option) => (
        <FormControlLabel
          key={option.value}
          selected={value === option.value}
          value={option.value}
          control={<Radio />}
          label={option.label}
        />
      ))}
    </MuiRadioGroup>
  </FormControl>
)

export default RadioGroup
