import { Button, Divider, Typography } from '@mui/material'

import { useTenants } from 'state/useTenantsStore'

const NoOrganisations = () => {
  const organisations = useTenants()

  if (organisations.length > 0) return null

  return (
    <Typography variant='h5' component='h3' gutterBottom>
      No orgs
    </Typography>
  )
}

export default NoOrganisations
