import { styled } from '@mui/material'
import { useDraggable } from '@dnd-kit/core'

const Span = styled('span')`
  display: flex;
  cursor: grab;
  box-shadow: var(--mui-shadows-0);
  border-radius: 0.25rem;
  transition:
    box-shadow 200ms ease-in-out,
    color 200ms ease-in-out;

  &:hover {
    box-shadow: var(--mui-shadows-1);
  }

  .dragging & {
    color: color-mix(currentColor, transparent 50%);
  }
`

const Draggable = ({ name, parent, children, disabled = false, ...props }) => {
  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id: name,
    disabled,
    data: {
      from: parent,
    },
  })

  return (
    <Span
      ref={setNodeRef}
      className={transform ? 'dragging' : ''}
      {...listeners}
      {...attributes}
      {...props}
    >
      {children}
    </Span>
  )
}

export default Draggable
