import Organisations from 'pages/Organisation/Collection'
import organisation_route from './organisation'
import invitations_route from './invitations'
import users_route from './users'
import roles_route from './roles'

const route = {
  id: 'organisations',
  path: 'organisations',
  children: [
    {
      index: true,
      element: <Organisations />,
    },
    organisation_route,
    invitations_route,
    users_route,
    roles_route,
  ],
}

export default route
