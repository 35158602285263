import {
  Button,
  CardActions,
  CardContent,
  CardHeader,
  IconButton,
  Modal,
  Card as MuiCard,
  styled,
} from '@mui/material'
import { Form, useNavigate, useParams } from 'react-router-dom'

import Icon from 'components/Icon'

import MainForm from './MainForm'

const Card = styled(MuiCard)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 80vw;
  max-height: 100vh;
  background-color: #fff;
  box-shadow: 24;
`

const Main = ({ event, exists }) => {
  const navigate = useNavigate()
  const { id } = useParams()

  return (
    <Modal
      open
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <Form action='' method='POST'>
        <Card>
          <CardHeader
            title={exists ? 'Edit event' : 'Add event'}
            subheader={
              exists ? 'Edit details for event' : 'Add details for event'
            }
            action={
              <IconButton
                aria-label='close'
                onClick={() => navigate(`/customers/${id}/events`)}
              >
                <Icon name='xmark' />
              </IconButton>
            }
          />
          <CardContent>
            <MainForm event={event} />
          </CardContent>
          <CardActions sx={{ justifyContent: 'end', padding: '0 1rem 1rem' }}>
            <Button
              type='submit'
              name='section'
              value='update_event'
              variant='outlined'
              color='secondary'
              size='small'
            >
              Save event
            </Button>
          </CardActions>
        </Card>
      </Form>
    </Modal>
  )
}

export default Main
