import { Tooltip, Typography } from '@mui/material'

import Icon from 'components/Icon'

const Note = ({ note = '', warning }) => {
  if (note === '') return

  const icon = warning ? 'exclamation-triangle' : 'info-circle'
  const color = warning ? 'warningFilledBg' : 'infoFilledBg'

  return (
    <Tooltip
      placement='left'
      arrow
      title={<Typography variant='caption'>{note}</Typography>}
    >
      <Icon
        name={icon}
        sx={{ color: 'var(--mui-palette-Alert-' + color + ')' }}
      />
    </Tooltip>
  )
}

export default Note
