import { experimental_extendTheme as extendTheme } from '@mui/material/styles'

export default extendTheme({
  colorSchemes: {
    light: {
      palette: {
        primary: {
          // main: '#74749b',
          dark: '#272733',
          main: '#383849',
          light: '#5f5f6d',
        },
        secondary: {
          // main: '#9b749b',
          dark: '#646371',
          main: '#8F8EA2',
          // light: '#a5a4b4',
          light: '#c9c8d5',
        },
        tertiary: {
          // main: '#9b8874',
          dark: '#4d8e84',
          main: '#6FCBBD',
          light: '#a2ddd4',
        },
        quaternary: {
          // main: '#9b9b74',
          dark: '#aea567',
          main: '#F9ED94',
          light: '#fbf3ba',
        },
        quinary: {
          // main: '#9b9b74',
          dark: '#a3a3a3',
          main: '#E9E9E9',
          light: '#ededed',
        },
        background: {
          // default: '#F3F4F6',
          default: '#CCC',
          paper: '#FFFFFF',
        },
        action: {
          selectedOpacity: 0.2,
        },
      },
    },
    dark: {
      palette: {
        type: 'dark',
        primary: {
          // main: '#74749b',
          dark: '#272733',
          main: '#383849',
          light: '#5f5f6d',
          contrastText: '#f00',
        },
        secondary: {
          dark: '#242337',
          main: '#4c4b61',
          light: '#615f7a',
        },
        tertiary: {
          // main: '#9b8874',
          dark: '#0f3a34',
          main: '#1f4f48',
          light: '#579a90',
          contrastText: '#f00',
        },
        quaternary: {
          // main: '#9b9b74',
          dark: '#aea567',
          main: '#F9ED94',
          light: '#fbf3ba',
          contrastText: '#f00',
        },
        quinary: {
          // main: '#9b9b74',
          dark: '#a3a3a3',
          main: '#E9E9E9',
          light: '#ededed',
          contrastText: '#f00',
        },
        background: {
          // default: '#F3F4F6',
          default: '#282828',
          paper: '#282828',
          contrastText: '#eee',
        },
        action: {
          active: '#eee',
          selectedOpacity: 1,
        },
        contrastThreshold: 3,
        tonalOffset: 0.2,
      },
    },
  },

  components: {
    MuiIcon: {
      styleOverrides: {
        root: {
          fontSize: '1.125rem',
          width: 'fit-content',
        },
      },
    },
  },
})
