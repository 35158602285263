import { useState } from 'react'

import Document, { Navigation, Sections } from 'components/PageLayouts/Document'
import { useCurrent as useCurrentProductDefinition } from 'state/useProductDefinitionsStore'

import SideNavigation from './SideNavigation'
import IndexPage from './IndexPage'

const Settings = () => {
  const [index_page_expanded, set_index_page_expanded] = useState(true)
  const product_definition = useCurrentProductDefinition()

  console.log('Settings', product_definition)

  const sections = {
    index_page: {
      id: 'overview',
      name: 'Index page settings',
      icon: 'table-list',
      subtitle: 'Toggle and configure the index page for this product type',
      action: () => set_index_page_expanded(true),
    },
  }

  return (
    <Document>
      <Navigation>
        <SideNavigation sections={sections} />
      </Navigation>
      <Sections>
        <IndexPage
          expanded={index_page_expanded}
          set_expanded={set_index_page_expanded}
          {...sections.index_page}
          settings={product_definition.settings?.index_page}
        />
      </Sections>
    </Document>
  )
}

export default Settings
