import React from 'react'
import { Button, Typography, styled } from '@mui/material'
import { useNavigate } from 'react-router-dom'

import Icon from 'components/Icon'

const ButtonWithEndIconRight = styled(Button)`
  & .MuiButton-endIcon {
    margin-left: auto;
  }
`

const SideNavigationButton = ({
  url,
  label,
  icon,
  endIcon = 'arrow-right',
}) => {
  const navigate = useNavigate()

  return (
    <ButtonWithEndIconRight
      variant='outlined'
      sx={{
        justifyContent: 'flex-start',
        textTransform: 'inherit',
      }}
      onClick={() => {
        navigate(url)
      }}
      aria-label={label}
      startIcon={<Icon style={{ fontSize: '16px' }} name={icon} />}
      endIcon={
        <Icon
          style={{
            fontSize: '16px',
            marginLeft: 'auto',
          }}
          name={endIcon}
        />
      }
    >
      <Typography variant='body2'>{label}</Typography>
    </ButtonWithEndIconRight>
  )
}

export default SideNavigationButton
