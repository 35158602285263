import { Children } from 'react'
import { v4 as uuid } from 'uuid'
import { List } from '@mui/material'
import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd'

const DraggableList = ({
  id = uuid(),
  values,
  onChange,
  children,
  ...props
}) => {
  const onDragEnd = ({
    destination: { index: from },
    destination = {},
    source: { index: to },
  }) => {
    if (typeof from !== 'number') return

    values.splice(to, 0, values.splice(from, 1)[0])

    onChange(values)
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId='droppable'>
        {(provided, snapshot) => (
          <List ref={provided.innerRef} {...props}>
            {Children.map(children, (child, index) => (
              <Draggable key={child.key} draggableId={child.key} index={index}>
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                  >
                    {child}
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </List>
        )}
      </Droppable>
    </DragDropContext>
  )
}

export default DraggableList
