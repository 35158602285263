import { absolute_time, relative_time } from 'utilities/datetime'
import { Row } from 'components/Table'

import FrontRow from './FrontRow'
import BackRow from './BackRow'

const Invitation = ({
  id,
  account,
  email,
  created_at,
  status,
  validated_at,
  organisation_id,
  absolute,
}) => {
  const validated_timestamp = validated_at
    ? absolute
      ? absolute_time(validated_at)
      : relative_time(validated_at)
    : null

  return (
    <Row>
      <FrontRow
        organisation_id={organisation_id}
        inviter_id={account?.id}
        inviter_name={account?.name}
        invitee_email={email}
        timestamp={
          absolute ? absolute_time(created_at) : relative_time(created_at)
        }
      />
      <BackRow id={id} status={status} timestamp={validated_timestamp} />
    </Row>
  )
}

export default Invitation
