import { useLocationHistory } from 'state/useLocationHistoryStore'

const useShouldAnimate = (path) => {
  const history = useLocationHistory()

  if (!history || !path) return false
  if (history === path) return false

  const shouldAnimate = history?.startsWith(path) || path?.startsWith(history)

  console.log(
    `${history} -> ${path} ${shouldAnimate ? 'should' : 'should not'} animate}`
  )

  if (history === path) return false

  return shouldAnimate
}

export default useShouldAnimate
