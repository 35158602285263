import { v4 as uuid } from 'uuid'
import { useNavigate } from 'react-router-dom'

import Fab from 'components/Fab'
import Grid from 'components/Grid'
import Icon from 'components/Icon'

import Card from './Card'
import { useProducts } from 'state/useProductsStore'
import CollectionHeader from 'components/CollectionHeader'
import search from 'utilities/search'
import useQueryParam from 'hooks/useQueryParam'

const Products = () => {
  const navigate = useNavigate()
  const products = useProducts()
  const search_term = useQueryParam('search')
  const root_products = products.filter((p) => !p.parent)
  const addons = products.filter((p) => p.parent)

  return (
    <>
      <CollectionHeader title='Products and services' />
      <Grid>
        {search(root_products, search_term, 'configuration.name').map(
          (product) => {
            const productAddons = addons.filter((p) => p.parent === product.id)
            return <Card key={product.id} {...product} addons={productAddons} />
          }
        )}
      </Grid>
      <Fab aria-label='add' onClick={() => navigate(`./${uuid()}`)}>
        <Icon name='plus' />
      </Fab>
    </>
  )
}

export default Products
