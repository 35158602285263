import { redirect } from 'react-router-dom'

import { upsert, get_form_data } from 'utilities/routes'
import Roles from 'pages/Organisation/Document/Roles'
import Modal from 'pages/Organisation/Document/Roles/Modal'
import { getSession } from 'state/useSession'

function simplify_permissions(obj) {
  for (const key in obj) {
    if (typeof obj[key] === 'object') {
      // Recursively simplify nested objects
      simplify_permissions(obj[key])

      // Check for the specific keys and their values
      if (
        obj[key].read === true &&
        obj[key].create === true &&
        obj[key].delete === true &&
        obj[key].update === true
      ) {
        // Replace with 'manage': true
        delete obj[key].read
        delete obj[key].create
        delete obj[key].delete
        delete obj[key].update
        obj[key].manage = true
      }
    }
  }
  return obj
}

const action = async ({ params, request }) => {
  const [payload, _section] = await get_form_data(request)
  const { user } = getSession()

  const data = simplify_permissions({
    ...payload,
    id: params.role_id,
    account_id: user.id,
  })

  await upsert('roles', data)

  return redirect(`../`)
}

const route = {
  path: ':id/roles',
  element: <Roles />,
  children: [
    {
      path: ':role_id',
      element: <Modal />,
      action,
    },
  ],
}

export default route
