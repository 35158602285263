import { Fragment, useState } from 'react'
import {
  Box,
  CardContent,
  CardHeader,
  Collapse,
  Divider,
  IconButton,
  Stack,
} from '@mui/material'
import { styled } from '@mui/material/styles'

import Icon from 'components/Icon'
import Dialog from 'components/Dialog'

import Form from './Form'

const ProductInstanceLine = styled(Box)`
  min-width: 4px;
  align-self: stretch;
`

const ProductInstance = ({ product, values, color, update, onDelete }) => (
  <CardContent>
    <Stack direction='row' gap={2} justifyContent='end' alignItems='start'>
      <ProductInstanceLine sx={{ backgroundColor: color }} />
      <Form product={product} values={values} update={update} />
      <IconButton aria-label='close' onClick={onDelete} sx={{ mt: 1 }}>
        <Icon fontSize='small' name='trash' />
      </IconButton>
    </Stack>
  </CardContent>
)

const ProductInstances = ({ product = {}, values = [], update }) => {
  const [open, setOpen] = useState(false)
  const [deleteIndex, setDeleteIndex] = useState(-1)

  const handleUpdate = (index, change) => {
    update([
      ...values.slice(0, index),
      { ...values[index], ...change },
      ...values.slice(index + 1),
    ])
  }

  const handleDelete = (index) => {
    setDeleteIndex(index)
    setOpen(true)
  }

  const handleDialogClose = (result) => {
    setOpen(false)

    if (!result) return

    update([...values.slice(0, deleteIndex), ...values.slice(deleteIndex + 1)])
  }

  return (
    <>
      {values.map((instanceValues = {}, index) => (
        <Fragment key={index}>
          <ProductInstance
            product={product}
            values={instanceValues}
            color={index % 2 === 0 ? 'primary.main' : 'secondary.main'}
            update={(change) => handleUpdate(index, change)}
            onDelete={() => handleDelete(index)}
          />
          <Divider />
        </Fragment>
      ))}
      <Dialog open={open} onClose={handleDialogClose} title='Delete addon'>
        Are you sure you want to delete the Addon instance?
      </Dialog>
    </>
  )
}

const Product = ({
  product: {
    id,
    configuration: {
      name,
      description = '',
      // default_value = '',
      // required,
      // visible,
      limit,
      only_one,
    },
  },
  product,
  state = [],
  update,
}) => (
  <Box id={id} sx={{ backgroundColor: 'secondary.light' }}>
    <CardHeader
      title={name}
      titleTypographyProps={{ variant: 'h6' }}
      subheader={description}
      subheaderTypographyProps={{ variant: 'body2' }}
      sx={{ '& .MuiCardHeader-action': { alignSelf: 'center' } }}
      action={
        <IconButton
          size='large'
          aria-label='settings'
          disabled={state.length >= (only_one ? 1 : limit)}
          onClick={() => {
            update([...state, {}])
          }}
        >
          <Icon name='plus' />
        </IconButton>
      }
    />
    <ProductInstances product={product} values={state} update={update} />
  </Box>
)

const Products = ({ products, state, update }) =>
  products.map((product, index) => (
    <Product
      key={`${product.id}_${index}`}
      product={product}
      state={state[product.id]}
      update={(change) => update({ [product.id]: change })}
    />
  ))

const Addons = ({ product, values, update, availableProducts }) => {
  const products = availableProducts.filter((p) => p.parent === product.id)
  const [expanded, setExpanded] = useState(false)

  const handleUpdate = (change) => {
    update({ ...values, ...change })
  }

  if (products.length === 0) return

  return (
    <>
      <CardHeader
        height='40'
        title='Addons'
        subheader='Additional services that are included on top of the default product'
        titleTypographyProps={{ variant: 'h6', color: '#fff' }}
        subheaderTypographyProps={{ variant: 'body2', color: '#eee' }}
        sx={{ backgroundColor: 'secondary.main' }}
        action={
          <IconButton onClick={() => setExpanded(!expanded)}>
            <Icon
              sx={{ color: '#fff' }}
              name={expanded ? 'chevron-up' : 'chevron-down'}
            />
          </IconButton>
        }
      />
      <Collapse in={expanded} timeout='auto' unmountOnExit>
        <Products state={values} products={products} update={handleUpdate} />
      </Collapse>
    </>
  )
}

export default Addons
