import { CardContent, FormControlLabel, Stack, Switch } from '@mui/material'

import Field from 'components/Field'
import Icon from 'components/Icon'

const Common = ({
  name,
  description,
  icon,
  parent,
  only_one,
  limit,
  update,
}) => (
  <>
    <Field
      label='Name'
      value={name}
      helperText='The product name as it will appear in lists and other views in the system'
      update={(value) => update({ name: value })}
    />
    <Field
      label='Description'
      value={description}
      multiline
      minRows={2}
      helperText='A detailed description of the product'
      update={(value) => update({ description: value })}
    />
    <Stack direction='row' alignItems='center' gap={2} pr={2}>
      <Field
        label='icon'
        value={icon}
        helperText='Material design icon name, underscored. See https://mui.com/material-ui/material-icons'
        update={(value) => update({ icon: value })}
      />
      <Icon name={icon} />
    </Stack>
    {parent && (
      <Stack gap={4} direction='row' alignItems='start'>
        <FormControlLabel
          labelPlacement='top'
          label='Only one'
          style={{ whiteSpace: 'nowrap' }}
          control={
            <Switch
              checked={only_one}
              onChange={() => {
                update({ only_one: !only_one })
              }}
            />
          }
        />
        {!only_one && (
          <Field
            label='Number of instances'
            value={limit}
            helperText='Limit max number of instnaces that can be added. Leave empty for no limit'
            update={(value) => update({ limit: value })}
          />
        )}
      </Stack>
    )}
  </>
)

export default Common
