import { useRouteError } from 'react-router-dom'

import { Box, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

const Center = styled(Box)`
  display: grid;
  justify-content: center;
  align-content: center;
  height: 100vh;
`

export default function ErrorPage() {
  const error = useRouteError()

  console.log(error)

  return (
    <Center>
      <Box sx={{ maxWidth: '640px' }}>
        <img
          alt='"We messed up" union "You cant type" = 404 page'
          src='/images/404.svg'
          height='200'
          style={{ display: 'block', margin: '0 auto' }}
        />
        <Typography variant='h2' component='h1' mb={6}>
          Oops!
        </Typography>
        <Typography variant='h5' component='h2' mb={2}>
          {error?.status + ' ' + error?.statusText ||
            error?.message ||
            'Sorry, an unexpected error has occurred.'}
        </Typography>
        <Typography variant='body' component='p' mb={2}>
          They say that &quot;The road to hell is paved with good
          intentions&quot;, among them ours to not have this happen.
        </Typography>
        <Typography variant='body' component='p' mb={2}>
          But since it has could we recommend that you make sure that you:
        </Typography>
        <Typography variant='body' component='ol'>
          <li>Are logged in.</li>
          <li>Didn&apos;t miss anything if you typed or copied the URL.</li>
          <li>
            Go via the <a href='/'>frontpage</a> and use the menu to navigate to
            the page you are looking for, if possible.
          </li>
          <li>
            Anger cry into your keyboard while swearing over our incompetence.
          </li>
        </Typography>
      </Box>
    </Center>
  )
}
