import {
  CardContent,
  CardHeader,
  IconButton,
  Modal,
  Card as MuiCard,
  CardMedia as MuiCardMedia,
} from '@mui/material'
import { styled } from '@mui/material/styles'

import UploadImage from './UploadImage'
import SelectUrl from './SelectUrl'
import ImageList from './ImageList'
import Icon from 'components/Icon'

const Card = styled(MuiCard)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80vw;
  max-height: 80vh;
  background-color: #fff;
  box-shadow: 24;
`

const CardMedia = styled(MuiCardMedia)`
  max-height: 50vh;
  overflow-y: auto;
  border-top: 1px solid #aaa;
  border-bottom: 1px solid #aaa;
`

const Grid = styled('div')`
  display: grid;
  grid-template-columns: 1fr 1fr auto;
  grid-gap: 1em;
  align-items: center;
`

const ImageSelect = ({
  open,
  path,
  title = 'Add image',
  onSelect,
  onClose,
}) => (
  <Modal
    open={open}
    onClose={onClose}
    aria-labelledby='modal-modal-title'
    aria-describedby='modal-modal-description'
  >
    <Card>
      <CardHeader
        title={title}
        subheader='Select an existing image, upload a new or use an image URL.'
        action={
          <IconButton aria-label='close' onClick={onClose}>
            <Icon name='circle-xmark' />
          </IconButton>
        }
      />
      <CardMedia>
        <ImageList path={path} onSelect={onSelect} />
      </CardMedia>
      <CardContent>
        <Grid>
          <UploadImage path={path} onUpload={onSelect} />
          <SelectUrl onSubmit={onSelect} />
        </Grid>
      </CardContent>
    </Card>
  </Modal>
)

export default ImageSelect
