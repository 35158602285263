import { create } from 'zustand'
import { mountStoreDevtool } from 'simple-zustand-devtools'

import { supabase } from 'hooks/useSupabase'

import { getSession, useSession } from './useSession'
import { appendError, appendSuccess } from './useSnackbarStore'

const useAccountsStore = create((set) => {
  return {
    loading: true,
    accounts: [],
    fetchAccounts: async () => {
      const { data: accounts = [], error } = await supabase.rpc('get_accounts')

      if (error) {
        appendError(error.message)
      }

      set({ accounts, loading: false })
    },
    addAccount: (account) =>
      set(({ accounts }) => ({ accounts: [...accounts, account] })),
    updateAccount: (updated) =>
      set((state) => ({
        accounts: state.accounts.map((account) =>
          account.id === updated.id ? { ...account, ...updated } : account
        ),
      })),
    deleteAccount: ({ id }) =>
      set((state) => ({
        accounts: state.accounts.filter((account) => account.id !== id),
      })),
  }
})

export const useLoading = () => useAccountsStore((state) => state.loading)
export const useAccounts = () => useAccountsStore((state) => state.accounts)
export const useAccount = (id) =>
  useAccountsStore((state) =>
    state.accounts.find((account) => account.id === id)
  )
export const useCurrentAccount = () => {
  const {
    user: { id },
  } = useSession()

  return (
    useAccountsStore((state) =>
      state.accounts.find((account) => account.id === id)
    ) || {}
  )
}

export const addAccount = useAccountsStore.getState().addAccount
export const updateAccount = useAccountsStore.getState().updateAccount
export const deleteAccount = useAccountsStore.getState().deleteAccount
export const fetchAccounts = useAccountsStore.getState().fetchAccounts
export const getCurrentAccount = () => {
  const session = getSession()
  const id = session?.user?.id
  return (
    useAccountsStore.getState().accounts.find((account) => account.id === id) ||
    {}
  )
}
export default useAccountsStore

export const subscriptionHandler = async (event, entity) => {
  switch (event) {
    case 'INSERT':
      addAccount({ ...entity })
      break
    case 'UPDATE':
      updateAccount(entity)
      break
    case 'DELETE':
      deleteAccount(entity)
      break
    default:
  }
}

if (process.env.NODE_ENV === 'development') {
  mountStoreDevtool('Accounts', useAccountsStore)
}
