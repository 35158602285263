import { create } from 'zustand'
import { mountStoreDevtool } from 'simple-zustand-devtools'

import { supabase } from 'hooks/useSupabase'

import { useSession } from './useSession'

const useRolesStore = create((set) => {
  return {
    loading: true,
    roles: [],
    fetchRoles: async () => {
      const { data: roles } = await supabase.rpc('get_roles')

      set({ roles, loading: false })
    },
    addRole: (role) => set(({ roles }) => ({ roles: [...roles, role] })),
    updateRole: (updated) =>
      set((state) => ({
        roles: state.roles.map((role) =>
          role.id === updated.id ? { ...role, ...updated } : role
        ),
      })),
    deleteRole: ({ id }) =>
      set((state) => ({
        roles: state.roles.filter((role) => role.id !== id),
      })),
  }
})

export const useLoading = () => useRolesStore((state) => state.loading)
export const useRoles = () => useRolesStore((state) => state.roles)
export const useRole = (id) =>
  useRolesStore((state) => state.roles.find((role) => role.id === id))
export const useCurrentRole = () => {
  const {
    user: { id },
  } = useSession()

  return (
    useRolesStore((state) => state.roles.find((role) => role.id === id)) || {}
  )
}

export const addRole = useRolesStore.getState().addRole
export const updateRole = useRolesStore.getState().updateRole
export const deleteRole = useRolesStore.getState().deleteRole
export const fetchRoles = useRolesStore.getState().fetchRoles
export default useRolesStore

export const subscriptionHandler = async (event, entity) => {
  console.log('invitation subscription handler', event, entity)
  switch (event) {
    case 'INSERT':
      addRole({ ...entity })
      break
    case 'UPDATE':
      updateRole(entity)
      break
    case 'DELETE':
      console.log('delete invitation', entity)
      deleteRole(entity)
      break
    default:
      console.log('unknown event type')
  }
}

if (process.env.NODE_ENV === 'development') {
  mountStoreDevtool('Roles', useRolesStore)
}
