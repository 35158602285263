function transform_to_nested(obj) {
  let result = {}

  for (const [key, raw_value] of Object.entries(obj)) {
    let parts = key.split('.')
    let target = result
    const value = raw_value === 'on' ? true : raw_value

    for (let i = 0; i < parts.length; i++) {
      const part = parts[i]
      const match = part.match(/^(\w+)\[(\d+)\]$/)

      if (match) {
        const [_, name, index] = match

        if (!target[name]) {
          target[name] = []
        }

        if (!target[name][index]) {
          target[name][index] = {}
        }

        target = target[name][index]
      } else {
        if (!target[part]) {
          target[part] = {}
        }

        if (i === parts.length - 1) {
          target[part] = value
        } else {
          target = target[part]
        }
      }
    }
  }

  return result
}

const get_form_data = async (request) => {
  const form_data = await request.formData()
  const { section, ...data } = Object.fromEntries(form_data)
  return [transform_to_nested(data), section, data]
}

export default get_form_data
