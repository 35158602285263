const get_nested_value = (obj, field) => {
  const fields = field.split('.')
  let value = obj

  for (const f of fields) {
    if (value[f] === undefined) {
      return ''
    }
    value = value[f]
  }

  return value
}

export default get_nested_value
