import { useEffect } from 'react'
import ActionBar, { Action } from 'components/ActionBar'
import Icon from 'components/Icon'

import MainPanel from './MainPanel'
import Variables from './Variables'
import useDebugState from './state/debug'

const ProductEditor = ({ product, update }) => {
  const { getDraft, commitDraft, initialise } = useDebugState()

  useEffect(() => {
    const draft = getDraft()
    product.configuration.variables.forEach(
      (variable) =>
        (draft[variable.variable_name] = variable.default_value ?? '')
    )
    commitDraft(draft)
    initialise()
  }, [product])

  return (
    <>
      <MainPanel
        state={product}
        update={(change) =>
          update({ configuration: { ...product.configuration, ...change } })
        }
      />

      <Variables
        variables={product.configuration.variables}
        update={(variables) =>
          update({ configuration: { ...product.configuration, variables } })
        }
      />
    </>
  )
}

export default ProductEditor
