import { styled } from '@mui/material'

const Divider = styled('hr')`
  grid-column: 1 / -1;
  border: none;
  height: 1rem;
  width: 100%;
`

export default Divider
