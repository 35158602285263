import { v4 as uuid } from 'uuid'
import { useNavigate, Outlet } from 'react-router-dom'
import { useState } from 'react'
import { useInvitations } from 'state/useInvitationsStore'

import { Divider, Table } from 'components/Table'
import CollectionHeader from 'components/CollectionHeader'
import Toggle from 'components/Toggle'

import Section from './Section'
import {
  expired_invitations,
  pending_invitations,
  accepted_invitations,
  filter_invitations,
} from './functions'
import LineAdd from 'components/LineAdd'
import { useCurrentTenant } from 'state/useTenantsStore'
import useSettings from 'hooks/useSettings'
import useQueryParam from 'hooks/useQueryParam'

const Invitations = () => {
  const navigate = useNavigate()
  const invitations = useInvitations()
  const organisation = useCurrentTenant()
  const search_term = useQueryParam('search')

  const { display_time_as } = useSettings()
  const absolute = display_time_as !== 'relative'
  const [show_expired, set_show_expired] = useState(false)

  if (!invitations) return null

  const filtered_invitations = filter_invitations(invitations, search_term)

  return (
    <>
      <CollectionHeader title='Invitations'>
        <Toggle
          checked={show_expired}
          set_checked={set_show_expired}
          title='Show'
          unchecked_label='recent'
          checked_label='all'
        />
      </CollectionHeader>
      <LineAdd
        tooltip={`Invite user to ${organisation.name}`}
        onClick={() => navigate(`./${uuid()}`)}
      />
      <Table columns='auto 3rem auto auto 10rem 6rem'>
        <Section
          invitations={pending_invitations(filtered_invitations)}
          show_expired={show_expired}
          days={10}
          organisation_id={organisation.id}
          absolute={absolute}
        />
        <Divider />
        <Section
          invitations={expired_invitations(filtered_invitations)}
          show_expired={show_expired}
          days={14}
          organisation_id={organisation.id}
          absolute={absolute}
        />
        <Divider />
        <Section
          invitations={accepted_invitations(filtered_invitations)}
          show_expired={show_expired}
          days={14}
          organisation_id={organisation.id}
          absolute={absolute}
        />
      </Table>
      <Outlet />
    </>
  )
}

export default Invitations
