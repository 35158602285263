import _ from 'lodash'
import { useEffect, useRef, useState } from 'react'
import { Card, CardContent, CardHeader, IconButton } from '@mui/material'

import Icon from 'components/Icon'
import CodeEditor from 'components/CodeEditor'

import useDebugState from '../state/debug'

import Menu from './Menu'

const runCalculation = (state, { variable_name, attributes: { code } }) => {
  const compartment = new window.Compartment({
    ...state,
    log: () => {},
  })
  state[variable_name] = compartment.evaluate(code)
  return state
}

const runCalculations = (calculations, state) => {
  return calculations.reduce(runCalculation, state)
}

const Debug = ({ state, onMenuSelect }) => {
  const {
    initialised,
    state: debugState,
    getDraft,
    commitDraft,
    update,
  } = useDebugState()
  const [debugStateCode, setDebugStateCode] = useState('{}')
  const ref = useRef()
  const [open, setOpen] = useState(false)

  const { calculation = [] } = _.groupBy(state.variables, (v) => v.type)

  useEffect(() => {
    if (!initialised) return
    setDebugStateCode(JSON.stringify(debugState, null, 2))
    const draftState = getDraft(debugState)
    runCalculations(calculation, draftState)
    commitDraft(draftState)
  }, [initialised, debugState])

  return (
    <Card id={state.id} style={{ transform: 'rotateY(180deg)' }}>
      <CardHeader
        avatar={<Icon fontSize='large' name='code' />}
        title='Product debug'
        titleTypographyProps={{ variant: 'h4', component: 'h2' }}
        subheader='Test your state and calculations'
        action={
          <>
            <IconButton ref={ref} onClick={() => setOpen(true)}>
              <Icon name='bars' />
            </IconButton>
            <Menu
              anchorEl={ref.current}
              open={open}
              debug={true}
              onClose={() => setOpen(false)}
              onClick={(action) => {
                onMenuSelect(action)
                setOpen(false)
              }}
            />
          </>
        }
      />
      <CardContent>
        <CodeEditor
          code={debugStateCode}
          onChange={setDebugStateCode}
          onSave={() => {
            const newDebugState = JSON.parse(debugStateCode)
            update({ state: newDebugState })
          }}
        />
        Enter a state representation. The entered state will be used for all the
        calculations.
        <pre>{}</pre>
      </CardContent>
    </Card>
  )
}

export default Debug
