import { useState } from 'react'
import { useLocation } from 'react-router'
import { motion } from 'framer-motion'

import LineAdd from 'components/LineAdd'
import Icon from 'components/Icon'
import ActionBar, { Action } from 'components/ActionBar'
import Columns from 'components/Columns'
import Column from 'components/Column'
import { useProducts } from 'state/useProductsStore'
import { useCurrentCustomer } from 'state/useCustomersStore'

import SideNavigation from './SideNavigation'
import Products from './Products'
import ProductSelect from './ProductSelect'

const Customer = () => {
  const customer = useCurrentCustomer()
  const availableProducts = useProducts()
  const [productSelectOpen, setProductSelectOpen] = useState(false)
  const products = customer.products
  const [state, set_state] = useState(products)
  const location = useLocation()

  const handleUpdate = (change) => {
    set_state({ ...state, ...change })
  }

  return (
    <motion.div
      key={location.pathname}
      initial={{ x: '50%', opacity: 0.5 }}
      animate={{ x: 0, opacity: 1 }}
      exit={{ x: '50%', opacity: 0.5 }}
    >
      <Columns>
        <Column>
          <SideNavigation
            productConfigurations={products}
            availableProducts={availableProducts}
          />
        </Column>

        <Column>
          <Products
            products={products}
            update={(products) => handleUpdate({ products })}
            availableProducts={availableProducts}
          />

          <LineAdd
            tooltip='Add product'
            onClick={() => setProductSelectOpen(true)}
          />

          <ActionBar>
            <Action
              icon={<Icon name='brackets-curly' />}
              onClick={() => {
                // Intentionally left in
                console.log(JSON.stringify(state, null, 2))
              }}
              variant='outlined'
              sx={{
                marginRight: 'auto',
                color: 'tertiary.dark',
                borderColor: 'tertiary.dark',
              }}
            >
              Dump state
            </Action>
            <Action
              icon={<Icon name='save' />}
              onClick={() => {
                // const { data, error } = await supabase
                //   .from('countries')
                //   .upsert({ id: 1, name: 'Albania' })
                //   .select()
              }}
            >
              Save
            </Action>
          </ActionBar>
        </Column>

        <ProductSelect
          open={productSelectOpen}
          onClose={() => setProductSelectOpen(false)}
          productConfigurations={products}
          availableProducts={availableProducts}
          onSelect={(id) => {
            //appendProduct({ id })
            setProductSelectOpen(false)
            throw 'Implementation missing'
          }}
        />
      </Columns>
    </motion.div>
  )
}

export default Customer
