import { useCallback, useState } from 'react'
import { motion } from 'framer-motion'

import Attributes from './Attributes'
import Debug from './Debug'

const MainPanel = ({ state, update }) => {
  const [debug, setDebug] = useState(window.location.hash === '#debug')

  const handleSetDebug = useCallback((new_value) => {
    window.history.replaceState(
      '',
      document.title,
      window.location.pathname +
        (new_value ? '#debug' : '') +
        window.location.search
    )
    setDebug(new_value)
  }, [])

  const handleMenuSelect = useCallback(
    (action) => {
      switch (action) {
        case 'debug':
          handleSetDebug(!debug)
          break
        case 'delete':
          console.log('Delete')
          break
        default:
          console.log('Default')
      }
    },
    [debug]
  )

  return (
    <motion.div
      className='block'
      animate={{
        rotateY: debug ? 180 : 0,
        height: 'auto',
      }}
      transition={{ type: 'tween' }}
    >
      {debug ? (
        <Debug
          onMenuSelect={handleMenuSelect}
          state={{ id: state.id, ...state.configuration }}
        />
      ) : (
        <Attributes
          onMenuSelect={handleMenuSelect}
          state={{ id: state.id, ...state.configuration }}
          update={update}
        />
      )}
    </motion.div>
  )
}

export default MainPanel
